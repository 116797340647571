import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions";
import { useHistory } from 'react-router';
import Catalogo from "../Catalogo";

function Novita(props: any) {
  const history = useHistory();
  async function goToPromo() {
    await props["resetFilters"]();
    await props["setFilter"]("new", true);
  }

  useEffect(() => {
    goToPromo();
  }, []);

  return (
    <Catalogo></Catalogo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    products: state.product.searchedProducts,
    brands: state.brand.brands,
    brandsForSelect: state.domain.brand,
    nations: state.domain.nation,
    styles: state.domain.style,
    sizes: state.domain.size,
    packs: state.domain.packMap,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    },
    setSortF: (sortKey : any, order : any) => {
      dispatch(actions.setSort(sortKey, order))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Novita);
