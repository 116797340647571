import React from "react";
import LogoutAlert from "./LogoutAlert";

const Logout = () => {
    return <>
        <div className="shop scheda">
            <LogoutAlert></LogoutAlert>
        </div>
    </>
}

export default Logout;
