import React from "react";
import "./OrderOk.scss";
import Logo from "../../../../assets/images/logo.svg";
import { useHistory } from 'react-router';

export default function OrderOk() {
  const history = useHistory();
    async function goToCheckout() {
        history.push({
          pathname: "/shop",
          state: {},
        });
    }

  return (
    <div className="OrderOk">
      <div>
        <img onClick={() => goToCheckout()} className="logo" src={Logo} alt="Logo" style={{cursor: "pointer"}}/>
        <h3>Ordine effettuato con successo</h3>
        <a className="cta" onClick={() => goToCheckout()}>
          <p>Ritorna alla home</p>
        </a>
      </div>
    </div>
  );
}
