import axios from "axios";

export function initInterceptors(store: any, unsetAuthToken: any) {
  axios.interceptors.request.use((request) => {
    const state = store.getState();
    const token = state.utils.token;
    if (token) {
      request.headers.authorization = `Bearer ${token}`;
    }
    return request;
  });
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const errorNum = error?.response?.status;
      if (errorNum === 401) {
        store.dispatch(unsetAuthToken());
      }
      return Promise.reject(error);
    }
  );
}

export const createUserAccount = (data: any) => {
  return axios
    .post(`${process.env.REACT_APP_BACK_END_URL}/auth/signup`, data)
    .then((res) => res.data);
};

