import React, { Component } from "react";
import { connect } from "react-redux";
import { UserAllRest } from "../../../models/rest";
import { User } from "../../../models/user";
import * as action from "../../../store/actions";
import "./Users.scss";

class Users extends Component<{
  users: User[];
  loadAllUsers: any;
  history: any;
}> {
  componentDidMount() {
    this.props.loadAllUsers();
  }

  iconHandler = (id: any) => {
    this.props.history.push({
      pathname: "/admin/users/modify",
      state: { id: id },
    });
  };

  render() {
    return (
      <div className="ProductsAdmin">
        <button className="newButton" onClick={() => this.iconHandler("new")}>
          NUOVO
        </button>
        <table>
          <thead>
            <tr>
              {Object.keys(UserAllRest.fields).map((fieldName) => {
                return <th key={fieldName}>{fieldName}</th>;
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.users.map((product: any, ind: number) => {
              return (
                <tr key={ind}>
                  {Object.keys(UserAllRest.fields).map((fieldKey: any) => {
                    switch (UserAllRest.fields[fieldKey].dbType) {
                      case "image":
                        return (
                          <td key={fieldKey}>
                            <div
                              className="image"
                              style={{
                                backgroundImage: `url('${product[fieldKey]}')`,
                              }}
                            ></div>
                          </td>
                        );
                      case "boolean":
                        return (
                          <td key={fieldKey + product[fieldKey]}>
                            {product[fieldKey] ? "Si" : "No"}
                          </td>
                        );
                      default:
                        return (
                          <td key={fieldKey + product[fieldKey]}>
                            {product[fieldKey]}
                          </td>
                        );
                    }
                  })}
                  <td>
                    <i
                      onClick={() => this.iconHandler(product.id)}
                      className="fa fa-edit"
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    users: state.utils.users,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    loadAllUsers: () => dispatch(action.getAllUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
