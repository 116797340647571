import * as actionTypes from "../actions/actionTypes";

const initialState = {
  shopImages: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_SHOP_IMAGES:
      const shopImages: any[] = [];
      action.shopImages.forEach((image: any) => {
        shopImages.push(image);
      });
      return {
        ...state,
        shopImages: shopImages,
      };

    default:
      return { ...state };
  }
};

export default reducer;
