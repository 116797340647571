import React, { useState } from "react";
import { useHistory } from "react-router";
import * as actions from "../../../../store/actions";

import "./widgets.scss";
import { connect } from "react-redux";

type Props = {
  imageKey: any,
  image: any;
  setFilter?: any;
  resetFilters: any
  categoria: any;
  title: any;
  description: any;
};

function WidgetLargeFun({ imageKey, image, setFilter, resetFilters, categoria, title, description }: Props) {
  const history = useHistory();
  const link = ({ data, e }: any) => {
    go();
  };

  async function go() {
    resetFilters()
    if (image != undefined) setFilter('filterAs', imageKey)
    history.push({
      pathname: "/catalogo",
      state: {
          categoria: categoria != undefined ? categoria : '',
          title: title != undefined ? title : '',
          description: description != undefined ? description : '',
          image: image != undefined ? image : '',
      },
    });
  }

  return (
    <div className="widget large">
      <div
        className="content"
        style={{ backgroundImage: "url(" + ""+image + ")" }}
        onClick={link}
      ></div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    }
  };
};
export const WidgetLarge = connect(null, mapDispatchToProps)(WidgetLargeFun);

function WidgetMediumFun({ imageKey, image, setFilter, resetFilters, categoria, title, description }: Props) {
  const history = useHistory();
  const link = ({ data, e }: any) => {
    go();
  };

  async function go() {
    // await setFilter("brand", "0kwTmU9XVO5IDZHLc3yw", true);
    if (image != undefined) setFilter('filterAs', imageKey)
    history.push({
      pathname: "/catalogo",
      state: {
        categoria: categoria != undefined ? categoria : '',
        title: title != undefined ? title : '',
        description: description != undefined ? description : '',
        image: image != undefined ? image : '',
      },
    });
  }

  return (
    <div className="widget medium">
      <div
        className="content"
        style={{ backgroundImage: "url(" + image + ")" }}
        onClick={link}
      ></div>
    </div>
  );
}
export const WidgetMedium = connect(null, mapDispatchToProps)(WidgetMediumFun);


function WidgetSmallFun({ imageKey, image, setFilter, resetFilters, categoria, title, description }: Props) {
  const history = useHistory();
  const link = ({ data, e }: any) => {
    go();
  };

  async function go() {
    if (image != undefined) setFilter('filterAs', imageKey)
    // await setFilter("brand", "0kwTmU9XVO5IDZHLc3yw", true);
    history.push({
      pathname: "/catalogo",
      state: {
        categoria: categoria != undefined ? categoria : '',
        title: title != undefined ? title : '',
        description: description != undefined ? description : '',
        image: image != undefined ? image : '',
      },
    });
  }

  return (
    <div className="widget small">
      <div
        className="content"
        style={{ backgroundImage: "url(" + image + ")" }}
        onClick={link}
      ></div>
    </div>
  );
}
export const WidgetSmall = connect(null, mapDispatchToProps)(WidgetSmallFun);
