import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./Menu.scss";

class Menu extends Component<any> {
  state: { menuVoices: { [key: string]: string } } = {
    menuVoices: {
      aboutUs: "#aboutUs",
      products: "#products",
      brands: "#brands",
      shop: "/shop",
      // shop: "#actions",
      contactus: "#contactUs",
    },
  };
  render() {
    return (
      <div className="Menu">
        <div className="writeBackground">
          BEER LOVERS
          <br /> BEER LOVERS
          <br /> BEER LOVERS
        </div>
        <div
          onClick={this.props.closeClick}
          className="eurosaga icon-close"
        ></div>
        <ul>
          {Object.keys(this.state.menuVoices).map((voice) => {
            return (
              <li
                key={voice}
                onClick={() =>
                  this.props.navigate(this.state.menuVoices[voice])
                }
              >
                {/* <Link to={this.state.menuVoices[voice]}> */}
                {this.props.t("menu:" + voice)}
                {/* </Link> */}
              </li>
            );
          })}
        </ul>
        <div className="row">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/pages/category/Food---Beverage-Company/Eurosaga-srl-425043784249195/"
          >
            <div className="eurosaga icon-facebook white"></div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/eurosaga_official/?hl=bn"
          >
            <div className="eurosaga icon-instagram white"></div>
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation("menu")(Menu);
