import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import axios from "axios";

class Firebase {
  auth: firebase.auth.Auth;
  storage: any;
  token: any;
  startHttpCall: any;
  endHttpCall: any;

  constructor(config: any) {
    firebase.initializeApp(config);
    firebase.analytics();
    this.auth = firebase.auth();
    this.storage = firebase.storage();
    this.token = null;
    this.startHttpCall = config.startHttp ? config.startHttp : () => {};
    this.endHttpCall = config.endHttp ? config.endHttp : () => {};
  }

  saveUserToken = (resp: any) => {
    if (resp && resp.user) {
      resp.user.getIdToken().then((token: any) => {
        this.token = token;
      });
    }
  };

  doCreateUserWithEmailAndPassword = (email: any, password: any) => {
    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .then((resp: any) => {
        this.doSendEmailVerification();
        return resp;
      })
      .catch((err: any) => {
        throw err;
      });
  };

  doSignInWithEmailAndPassword = (email: any, password: any) => {
    this.startHttpCall();
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then((resp: any) => {
        this.saveUserToken(resp);
        this.endHttpCall();
        return resp;
      })
      .catch((err: any) => {
        this.endHttpCall();
        throw err;
      });
  };

  doSignInWithGoogle = () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        throw err;
      });
  };

  doSigninWithFacebook = () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(facebookProvider)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        throw err;
      });
  };

  doSignOut = () => {
    this.startHttpCall();
    return this.auth
      .signOut()
      .then((resp: any) => {
        this.endHttpCall();
        return resp;
      })
      .catch((err: any) => {
        this.endHttpCall();
        throw err;
      });
  };

  doPasswordReset = (email: any) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password: any) =>
    this.auth.currentUser?.updatePassword(password);

  doSendEmailVerification = () =>
    this.auth.currentUser?.sendEmailVerification();

  getRefFromUrl = (url: any) => this.storage.ref(url);

  helloWorld = () => {
    return axios
      .get(`${process.env.REACT_APP_BACK_END_URL}/auth/try`, {
        headers: { authorization: `Bearer ${this.token}` },
      })
      .then((res: any) => res.data);
  };
}

export default Firebase;
