import React from "react";
import "./Spinner.scss";

export default function Spinner() {
  return (
    <div className="Spinner loading">
      <div className="ldsEllipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
