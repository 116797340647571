import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import dadi_image from "../../../assets/images/cellulare.png";
import { scrollTo } from "../../../utils/functions";
import "./Actions.scss";

class Actions extends Component<any> {
  goShop = () => {
    this.props.history.push("/shop");
  };
  goMail = () => {
    scrollTo("#contactUs");
  };
  render() {
    return (
      <div className="Actions" id="#actions">
        <div
          className="leftColumn"
          style={{ backgroundImage: `url('${dadi_image}')` }}
        ></div>
        <div className="rightColumn">
          <h2>{this.props.t("actions:title")}</h2>
          <p>{this.props.t("actions:par")}</p>
          <div className="row">
            <button className="primary" onClick={this.goShop}>
              {this.props.t("actions:button1")}
            </button>
            <button className="secondary" onClick={this.goMail}>
              {this.props.t("actions:button2")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withTranslation("actions"), withRouter)(Actions);
