import { FormModel, RestModel } from "../models/rest";

export function getInitState(
  fieldsConfig: RestModel,
  prevValues?: any
): { form: any } {
  const form: FormModel = {};
  const fields = fieldsConfig.fields;
  Object.keys(fields).forEach((fieldKey) => {
    let ret: any;
    if (!fields[fieldKey].hide) {
      switch (fields[fieldKey].inputType) {
        case "text":
        case "number":
        case "note":
        case "note":
          ret = {
            type: fields[fieldKey].inputType,
            value:
              prevValues && prevValues[fieldKey] ? prevValues[fieldKey] : "",
            label: fieldsConfig.skipLabel
              ? null
              : fields[fieldKey].label || fieldKey,
            placeholder:
              fields[fieldKey].placeholder || "Inserisci " + fieldKey,
          };
          break;
        case "none":
          ret =
            prevValues && prevValues[fieldKey]
              ? {
                  type: fields[fieldKey].inputType,
                  value:
                    prevValues && prevValues[fieldKey]
                      ? prevValues[fieldKey]
                      : "",
                  label: fields[fieldKey].label || fieldKey,
                  placeholder:
                    fields[fieldKey].placeholder || "Inserisci " + fieldKey,
                }
              : null;
          break;
        case "file":
          ret = {
            type: fields[fieldKey].inputType,
            value: "",
            previousValue:
              prevValues && prevValues[fieldKey] ? prevValues[fieldKey] : "",
            label: fields[fieldKey].label || fieldKey,
            placeholder:
              fields[fieldKey].placeholder || "Inserisci " + fieldKey,
          };
          break;
        case "select":
          ret = {
            type: fields[fieldKey].inputType,
            value:
              prevValues && prevValues[fieldKey] ? prevValues[fieldKey] : "",
            label: fields[fieldKey].label || fieldKey,
            placeholder:
              fields[fieldKey].placeholder || "Inserisci " + fieldKey,
          };
          break;
        case "checkbox":
          ret = {
            type: fields[fieldKey].inputType,
            value:
              prevValues && prevValues[fieldKey] ? prevValues[fieldKey] : [],
            label: fields[fieldKey].label || fieldKey,
          };
          break;
        case "radio":
          ret = {
            type: fields[fieldKey].inputType,
            value: prevValues && prevValues[fieldKey] ? "true" : "false",
            label: fields[fieldKey].label || fieldKey,
            choices: [
              { value: "true", label: "Si" },
              { value: "false", label: "No" },
            ],
          };
          break;
        case "array":
          ret = {
            type: fields[fieldKey].inputType,
            childs: fields[fieldKey].childs,
            label: fields[fieldKey].label || fieldKey,
            value:
              prevValues && prevValues[fieldKey]
                ? prevValues[fieldKey].map((el: any) => {
                    const newEl: any = {};
                    Object.keys(el).forEach((key) => {
                      if (el[key] === true) {
                        newEl[key] = "true";
                      } else if (el[key] === false) {
                        newEl[key] = "false";
                      } else {
                        newEl[key] = el[key];
                      }
                    });
                    return newEl;
                  })
                : [{}],
            choices: [
              { value: "true", label: "Si" },
              { value: "false", label: "No" },
            ],
            // devo usare il value in qualche modo?! Forse ciclando dentro ai figli
          };
          break;
      }
    }
    if (!!ret) {
      form[fieldKey] = ret;
    }
  });
  return { form };
}

export function formatForm(form: FormModel): FormData {
  const body = new FormData();
  const newForm: any = {};
  Object.keys(form).forEach((fieldKey) => {
    const field = form[fieldKey];
    switch (field.type) {
      case "file":
        body.append("image", field.value);
        if (!newForm.id) {
          newForm.id = fieldKey;
        }
        break;
      case "radio":
        newForm[fieldKey] = field.value === "true";
        break;
      case "array":
        if (field.value && field.value.length) {
          (field.value as any).forEach((el: any) => {
            Object.keys(el).forEach((key) => {
              if (el[key] === "true") {
                el[key] = true;
              } else if (el[key] === "false") {
                el[key] = false;
              }
            });
          });
        }
        newForm[fieldKey] = field.value;
        break;
      default:
        newForm[fieldKey] = field.value;
    }
  });
  body.append("formData", JSON.stringify(newForm));
  return body;
}

function handleInputTextChange(
  component: { state: any; setState: any },
  event: any,
  fieldKey: string
) {
  const newForm: any = { ...component.state.form };
  const newField = { ...newForm[fieldKey] };
  newField.value = event.target.value;
  newForm[fieldKey] = newField;
  component.setState({ form: newForm });
}

export function handleFileChange(
  component: { state: any; setState: any },
  event: any,
  fieldKey: string
) {
  const newForm: any = { ...component.state.form };
  const newField = { ...newForm[fieldKey] };
  newField.value = event.target.files[0];
  newField.fileName = event.target.files[0].name;
  newField.type = !newField.type ? "file" : newField.type;
  newForm[fieldKey] = newField;
  component.setState({ form: newForm });
}

function handleCheckboxClick(
  component: { state: any; setState: any },
  event: any,
  fieldKey: string
) {
  const newForm: any = { ...component.state.form };
  const newField = { ...newForm[fieldKey] };
  var index = newField.value.indexOf(event.target.value);
  if (index !== -1) {
    newField.value.splice(index, 1);
  } else {
    newField.value.push(event.target.value);
  }
  newForm[fieldKey] = newField;
  component.setState({ form: newForm });
}

function handleArray(
  component: { state: any; setState: any },
  event: any,
  fieldKey: string,
  index: number,
  childKey: string
) {
  const newForm: any = { ...component.state.form };
  const newField = { ...newForm[fieldKey] };
  newField.value[index][childKey] = event.target.value;
  newForm[fieldKey] = newField;
  component.setState({ form: newForm });
}

export function inputHandler(component: any, type: string): any {
  switch (type) {
    case "text":
    case "email":
    case "number":
    case "note":
    case "radio":
    case "select":
      return (event: any, fieldKey: any) =>
        handleInputTextChange(component, event, fieldKey);
    case "file":
      return (event: any, fieldKey: any) =>
        handleFileChange(component, event, fieldKey);
    case "checkbox":
      return (event: any, fieldKey: any) =>
        handleCheckboxClick(component, event, fieldKey);
    case "array":
      return (event: any, fieldKey: any, index: number, childKey: any) =>
        handleArray(component, event, fieldKey, index, childKey);
    default:
      return () => {};
  }
}
