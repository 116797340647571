import React from "react";
import Container from "../../widgets/Basics/Container";
import "./catalogo.scss";

import ChevronUp from "../../../../assets/images/chevron-down.png";
import ChevronDown from "../../../../assets/images/chevron-up.png";

const Filter = ({ name, i, children }: any) => {
  return (
    <div>
      <a id={"hide" + i} href={"#hide" + i} className="hide">
        <p>{name}</p>
        <img src={ChevronUp} />
      </a>
      <a id={"show" + i} href={"#show1" + i} className="show">
        <p>{name}</p>
        <img src={ChevronDown} />
      </a>
      <div className="details">{children}</div>
    </div>
  );
};

export default Filter;
