import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import { useHistory } from "react-router";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import 'pure-react-carousel/dist/react-carousel.es.css';
import Hide from "../../widgets/Basics/Hide";
import Container from "../../widgets/Basics/Container";
import User from '../../../../assets/icons/user.svg';
import BrandLogo from '../../../../assets/images/partners/brewdog.png'

const carouselResponsiveBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Header = ({images, withArrows, resetFilters, setFilter} : any) => {
  const history = useHistory();

  async function go(item : any) {
    resetFilters()
    setFilter('filterAs', item.id)
    history.push({
      pathname: "/catalogo",
      state: {}
    });
  }

  async function goToBoxes() {
    history.push({
      pathname: "/about-box",
      state: {}
    });
  }

  async function goToBoxesSconti() {
    history.push({
      pathname: "/about-box/#sconti",
      state: {}
    });
  }


  return (
    <div>
        <Carousel
            responsive={carouselResponsiveBreakpoints}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2500}
            keyBoardControl={true}
            transitionDuration={800}
            arrows={withArrows}
            >
                {images != null && images.map((item : any, i : any) => {
                  if (i % 2 == 0 && item != undefined) {
                    if (i == 0) {
                      if (item != undefined)
                        return <img onClick={() => goToBoxes()} style={{width: '100%', cursor: 'pointer'}} src={item.image} />
                    } else if (i == 2) {
                      if (item != undefined)
                        return <a href="/about-box#sconti"><img onClick={() => goToBoxesSconti()} style={{width: '100%', cursor: 'pointer'}} src={item.image} /></a>
                    } else {
                      if (item != undefined)
                        return <img onClick={() => go(images[i+1])} style={{width: '100%', cursor: 'pointer'}} src={item.image} />
                    }
                  }
                })}
        </Carousel>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    }
  };
};
export default connect(null, mapDispatchToProps)(Header);
