import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import NavBar from "../../widgets/NavBar/NavBar";
import Feed from "./Feed";
import Header from "./Header";
import "./catalogo.scss";
import Container from "../../widgets/Basics/Container";
import Footer from "../../widgets/Footer/Footer";

function Catalogo() {
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const location = useLocation();
  const history = useHistory(); 

  useEffect(() => {
    try {
      const { categoria, title, description, image } = location.state as any;
      setCategory(categoria != undefined ? categoria : "");
      setTitle(title != null ? title : "");
      setDescription(description != null ? description : "");
      setImage(image != null ? image : "");
    } catch (e) {
      /*history.push({
        pathname: "/shop",
      });*/
    }
  }, []);

  return (
    <div>
      <NavBar></NavBar>
      {location != null &&
        location.state != null &&
        location.state != undefined && (
          <Header
            image={image}
            headline={title}
            subheadline={description}
          ></Header>
        )}
      <Feed category={category}></Feed>
      <Footer></Footer>
    </div>
  );
}

export default Catalogo;
