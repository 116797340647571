import React, { useState, useEffect } from "react";
import PlusIcon from '../../../../assets/images/count-plus-icon.png'
import MinusIcon from '../../../../assets/images/count-minus-icon.png'
import './prods.scss'

function Counter({ forCart, inizialValue, onChange, max } : any) {
    const [count, setCount] = useState(inizialValue != null ? inizialValue : 1);

    useEffect(() => {
        setCount(inizialValue)
    }, [inizialValue])

    function changeCount(x : number) {
        if(x > 0 && x <= max) {
            onChange(x)
            setCount(x)
        }
    }

    return forCart ? ( 
        <div className="shop shop-v-middle cart">
            <div className="shop wrapper-count-icon" style={{opacity: count == 1 ? 0.5 : 1}}>
                <img className="shop counter-icon" src={MinusIcon} alt="Minus Icon" onClick={() => changeCount(count - 1)}/>
            </div>
            <p className="shop counter-value">{count}</p>
            <div className="shop wrapper-count-icon">
                <img className="shop counter-icon" src={PlusIcon} alt="Plus Icon" onClick={() => changeCount(count + 1)}/>
            </div>
        </div> 
    ) : (
        <div className="shop shop-v-middle" style={{justifyContent: "space-between", alignItems: "center", width: "100%"}}>
            <div className="shop wrapper-count-icon" style={{opacity: count == 1 ? 0.5 : 1}}>
                <img className="shop counter-icon" src={MinusIcon} alt="Minus Icon" onClick={() => changeCount(count - 1)}/>
            </div>
            <p className="shop counter-value">{count}</p>
            <div className="shop wrapper-count-icon" style={{opacity: max == undefined || max == 0 ? 0.5 : 1}}>
                <img className="shop counter-icon" src={PlusIcon} alt="Plus Icon" onClick={() => changeCount(count + 1)}/>
            </div>
        </div> 
    )
}

export default Counter;
