import * as actionTypes from "../actions/actionTypes";

interface Brand {
  image?: string;
  insertDate?: Date;
  name?: string;
  nation?: string;
}

const initialState = {
  brands: [],
};

const reducer = (
  state = initialState,
  action: { type: string; brands: any[] }
) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_BRANDS:
      const brands: Brand[] = [];
      action.brands
        .sort((b1, b2) => (b1.name > b2.name ? 1 : -1))
        .forEach((brand: Brand) => {
          brands.push(brand);
        });
      return {
        ...state,
        brands: brands,
      };
    default:
      return { ...state };
  }
};

export default reducer;
