import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

function loadToLocalStorage() {
  try {
    let ret = undefined;
    const serializedState = localStorage.getItem("state");
    if (serializedState) {
      let prevState = JSON.parse(serializedState);
      if (prevState && prevState.utils) {
        prevState = {
          ...prevState,
          utils: { ...prevState.utils, restToWait: 0 },
        };
      }
      ret = prevState;
    }

    return ret;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function initStore(reducers: any) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    combineReducers(reducers),
    loadToLocalStorage(),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}
