import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Close from "../../../../assets/images/close_grey.png";
import * as actions from "../../../../store/actions";
import Item from "./Cart/Item";
import "./navbar.scss";

const Cart = ({
  toggleCart,
  cart,
  prods,
  brands,
  sizes,
  packs,
  isLogged,
  me,
  sendOrder,
  quantity,
}: any) => {
  const [total, setFTotal] = useState(-1);
  const [counter, setCount] = useState(quantity);
  var tempCounter = 0;
  const [toIsland, setToIsland] = useState();
  const [stripe, setStripe] = useState(undefined);
  var tempGlobalTotal = 0;
  const history = useHistory();
  async function goToCheckout() {
    history.push({
      pathname: "/checkout/informazioni",
      state: {},
    });
  }

  function setTotal(
    oldPrice: any,
    newPrice: any,
    oldQuantity: any,
    newQuantity: any
  ) {
    console.log("setTotal");
    var tempTotal = total != -1 ? total : tempGlobalTotal;
    tempTotal -= oldPrice;
    tempTotal += newPrice;
    setFTotal(tempTotal);
    setCount(quantity);
  }

  function resetTotal(price: any) {
    setFTotal(price);
  }

  function close() {
    toggleCart();
  }

  const radioHandler = (event: any) => {
    if (event?.target?.value) {
      setToIsland(event.target.value as any);
    }
  };

  const clickHandler = () => {
    if (
      (total > 0 || (tempGlobalTotal > 0 && total < 0)) &&
      quantity >= 6 &&
      quantity % 6 == 0
    ) {
      goToCheckout();
    }
  };

  const onOrderSuccess = (res: { data: { sessionId: string } }) => {
    const sessionId = res?.data?.sessionId;
    if (sessionId && stripe) {
      (stripe as any)
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then((result: any) => {
          history.push({
            pathname: "/order-ko",
          });
          //   return result.error.message;
        });
    }
  };

  async function goToLogin() {
    history.push({
      pathname: "/login",
      state: {},
    });
  }

  function float2int(value: number) {
    return value | 0;
  }

  function calcQuantity() {
    var tot = (float2int(quantity / 6) - 1) * 5;
    if (tot < 0) tot = 0;
    if (tot > 20) tot = 20;
    return tot;
  }

  return (
    <div>
      <div>
        <div className="cart-header-section">
          <div className="shop-row-space-between">
            <div>
              <h3>Carrello</h3>
              <h5>
                {quantity < 6
                  ? "Crea il tuo box"
                  : quantity >= 6 && quantity < 12
                  ? "Chill Box"
                  : quantity >= 12 && quantity < 18
                  ? "Share Box"
                  : quantity >= 18 && quantity < 24
                  ? "Party Box"
                  : "Extreme Box"}
              </h5>
            </div>
            <img
              onClick={() => close()}
              className="icon"
              src={Close}
              alt="Burger Icon"
            />
          </div>
          <div className="divider" />
        </div>
      </div>
      <div className={"list"}>
        {cart != undefined &&
          Object.keys(cart).map((prodId: any, index: any) => {
            var prod: any;
            var brand;
            var size;
            var pack;
            var price;
            var typeObject;
            if (prods != undefined) {
              prods.map((item: any) => {
                if (item.id == prodId) {
                  prod = item;
                }
              });
              if (prod != undefined) {
                brand = brands[prod.brand];
                var cartItem = cart[prodId];
                var tempString = Object.keys(cartItem);
                var sizeId = tempString[0];
                var typIds = cartItem[tempString[0]];
                var arrayTypIds = Object.keys(typIds);
                var quantity = typIds[arrayTypIds[0]];
                prod.types.map((type: any, i: any) => {
                  if (type.size == sizeId) {
                    typeObject = type;
                    size = sizes[prod.types[i].size];
                    pack = packs[prod.types[i].pack];
                    price = type.price;
                  }
                });

                if (
                  typeObject != undefined &&
                  price != undefined &&
                  size != undefined &&
                  pack != undefined
                ) {
                  tempGlobalTotal += price * quantity;
                  if (quantity > 0) {
                    tempCounter += quantity;
                    return (
                      <Item
                        id={prod.id}
                        prod={prod}
                        type={typeObject}
                        image={prod.image}
                        title={"" + prod.name}
                        brand={"" + brand}
                        format={"" + size}
                        pz={"" + pack}
                        price={price != undefined ? price : 0.0}
                        quantity={quantity}
                        onChangeCount={() => setTotal}
                        onResetCount={() => resetTotal}
                        key={"cartEl" + index}
                      ></Item>
                    );
                  } else return <></>;
                }
                return <></>;
              }
            }
            return <></>;
          })}
      </div>

      <div className="cart-footer-section">
        <div className="shop-row-space-between">
          <div>
            {calcQuantity() > 0 && (
              <p className="title" style={{ fontWeight: 400 }}>
                Sconto
              </p>
            )}
            <h3 className="title" style={{ display: "inline-block" }}>
              Totale (Iva inclusa)
            </h3>
          </div>
          <div>
            {calcQuantity() > 0 && (
              <p className="price" style={{ fontWeight: 400 }}>
                €{" "}
                {total != -1
                  ? calcQuantity().toFixed(2)
                  : calcQuantity().toFixed(2)}
              </p>
            )}
            <h3 className="price">
              €{" "}
              {total != -1
                ? (total - calcQuantity()).toFixed(2)
                : (tempGlobalTotal - calcQuantity()).toFixed(2)}
            </h3>
          </div>
        </div>
        <a
          className={
            (total > 0 || (tempGlobalTotal > 0 && total < 0)) &&
            me != undefined &&
            quantity >= 6 &&
            quantity % 6 == 0 &&
            isLogged
              ? "button active"
              : "button"
          }
          onClick={isLogged ? clickHandler : goToLogin}
          style={{
            cursor: "pointer",
          }}
        >
          <p>
            {!isLogged
              ? "Accedi per continuare"
              : quantity < 6
              ? "Aggiungi almeno 6 articoli"
              : quantity % 6 != 0
              ? "Finisci il tuo box"
              : "Vai al checkout"}
          </p>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    brands: state.domain.brandMap,
    sizes: state.domain.sizeMap,
    packs: state.domain.packMap,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    cart: state.shop.chart,
    prods: state.product.products,
    me: state.utils.me,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    sendOrder: (chart: any, spedition: any, user: any, onSuccess: any) =>
      dispatch(actions.sendOrder(chart, spedition, user, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
