import axios from "axios";
import { Coupon } from "../../models/product";
import * as actionTypes from "./actionTypes";
import { endHttpCall, startHttpCall } from "./utils";

export const saveAllCoupons = (coupons: Coupon[]) => {
  return {
    type: actionTypes.SAVE_ALL_COUPON,
    coupons: coupons,
  };
};

export const getAllCoupons = () => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/coupon/all`;
    axios
      .get(url)
      .then((resp) => {
        dispatch(saveAllCoupons(resp.data));
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const newCoupon = (newObj: Coupon, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/coupon/new`;
    axios
      .put(url, newObj)
      .then((_) => {
        dispatch(getAllCoupons());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const modifyCoupon = (inObj: Coupon, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/coupon/modify`;
    axios
      .post(url, inObj)
      .then((_) => {
        dispatch(getAllCoupons());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const deleteCoupon = (id: string, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/coupon/${id}`;
    axios
      .delete(url)
      .then((_) => {
        dispatch(getAllCoupons());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};
