import * as actionTypes from "./actionTypes";
import axios from "axios";
import { startHttpCall, endHttpCall } from "./utils";

export const getAllShopImages = () => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/shopImages/all`;
    axios
      .get(url)
      .then((resp) => {
        dispatch(saveAllShopImages(resp.data));
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const saveAllShopImages = (shopImages: any) => {
  return {
    type: actionTypes.SAVE_ALL_SHOP_IMAGES,
    shopImages: shopImages,
  };
};

export const modifyShopImage = (image: any, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/shopImages/modify`;
    axios
      .post(url, image)
      .then((_) => {
        dispatch(getAllShopImages());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};
