import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { CouponRest } from "../../../models/rest";
import * as actions from "../../../store/actions";
import { formatForm, getInitState, inputHandler } from "../../../utils/form";

class ModifyCoupon extends Component<any> {
  state = { form: {} };

  componentDidMount() {
    const id = this.props.location.state.id;
    const obj = this.props.coupons.find((el: any) => el.id === id);
    this.setState(getInitState(CouponRest, obj));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    // creo il viaggio
    const objToSave = formatForm({
      // id: {  value: this.state.travelId },
      ...this.state.form,
    });
    this.props.modify(objToSave, this.props.history.push("/admin/coupon"));
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
          domains={this.props.domain}
        >
          <div className="text-center mb-3">
            <button className="btn btn-primary" type="submit">
              MODIFICA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    coupons: state.coupons.coupons,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modify: (obj: any, onSuccess: any) => {
      dispatch(actions.modifyCoupon(obj, onSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyCoupon);
