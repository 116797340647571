export const SAVE_ALL_PRODUCTS = "SAVE_ALL_PRODUCTS";
export const SAVE_DOMAIN = "SAVE_DOMAIN";
export const SAVE_ALL_SHOP_IMAGES = "SAVE_ALL_SHOP_IMAGES";
export const SAVE_ALL_BRANDS = "SAVE_ALL_BRANDS";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const UNSET_AUTH_TOKEN = "UNSET_AUTH_TOKEN";
export const START_HTTP_CALL = "START_HTTP_CALL";
export const END_HTTP_CALL = "END_HTTP_CALL";
export const CHANGE_CHART_PRODUCT_AMOUNT = "CHANGE_CHART_PRODUCT_AMOUNT";
export const SET_SPEDITION_DATA = "SET_SPEDITION_DATA";
export const SAVE_ALL_ORDERS = "SAVE_ALL_ORDERS";
export const CHART_RESET = "CHART_RESET";
export const SET_FILTER = "SET_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";
export const SEARCH_CALCULATE = "SEARCH_CALCULATE";
export const SAVE_USERS = "SAVE_USERS";
export const SET_SORT = "SET_SORT";
export const SAVE_ME = "SAVE_ME";
export const SAVE_ALL_COUPON = "SAVE_ALL_COUPON";
// UNUSED. TO REMOVE?
export const SET_SCREEN_WIDTH = "SET_SCREEN_WIDTH";
