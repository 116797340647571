import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";
import { connect } from "react-redux";
import { ProdCard } from "./../Catalogo/widgets/ProdCard";
import "./scheda.scss";

const BestOf = ({ products, brands }: any) => {
  var index = 0;


  function shuffle(array: any) {
    return array.sort(() => Math.random() - 0.5);
  }

  return (
    <div>
      <div className="bestOf">
        <h2>Suggeriti per te</h2>
        <div className="itemsWrapper" style={{ paddingTop: "24px" }}>
          {products != null &&
            products.map((item: any, i: any) => {
              const image = item.image;
              if (image != undefined) {
                var brand = "";
                var brandLogo = "";
                var brandNation = "";
                brands != undefined &&
                  brands.map((element: any) => {
                    if (element.id == item.brand) {
                      brand = element.name;
                      brandLogo = element.image;
                      brandNation = element.nation;
                    }
                  });
                const title = item.name;
                const format = "";
                const pz = 0;
                const types = item["types"];
                const price = types[0].price;
                const oldPrice = types[0].oldPrice;
                const discountPercentage = types[0].discountPercentage;
                const quantity = 0;
                const stock = types[0].availablePieces;
                index++;
                if (index < 11)
                return (
                  <ProdCard
                    image={image}
                    brandLogo={brandLogo}
                    title={title}
                    brand={brand}
                    format={format}
                    pz={pz}
                    price={price}
                    oldPrice={oldPrice}
                    discountPercentage={discountPercentage}
                    quantity={quantity}
                    stock={stock}
                    isFavorite={false}
                    key={"prodCard"+title+i}
                  ></ProdCard>
                );

                else return <></>;
              } else {
                return <></>;
              }
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
    return {
        brands: state.brand.brands,
    }
}


export default connect(mapStateToProps)(BestOf);
