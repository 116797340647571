import React, { useState } from "react"
import { connect } from 'react-redux'
import * as actions from "../../../../store/actions";
import { InputText } from "../../widgets/Input/Text";
import "./checkout.scss";
import ItemCart from "./ItemCart";

const Cart = ({ cart, prods, brands, sizes, packs, costSpedition, additionalCosts, eventCoupon, me, isLogged }: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  var total = 0;
  var totalQuantity = 0;
  var coupon: string;

  const handleCoupon = () => {
    eventCoupon(coupon);
  }

  const handleOnChange = (e: any) => {
    coupon = e.target.value;
  }

  function float2int (value : number) {
    return value | 0;
  }

  function calcQuantity() {
    var tot = (float2int(totalQuantity / 6) - 1) * 5;
    if (tot < 0) tot = 0;
    if (tot > 20) tot = 20;
    return tot;
  }

  return (
    <div>
      <div className="cart">
        {
          cart != undefined && Object.keys(cart).map((prodId: any, index: any) => {
            var prod: any;
            var brand;
            var size;
            var pack;
            var price;
            if (prods != undefined) {
              prods.map((item: any) => {
                if (item.id == prodId) {
                  prod = item
                }
              })
              if (prod != undefined) {
                brand = brands[prod.brand]
                size = sizes[prod.types[0].size]
                pack = packs[prod.types[0].pack]
                price = prod.types[0].price

                var cartItem = cart[prodId]
                var tempString = Object.keys(cartItem)
                var cartItem2 = cartItem[tempString[0]]
                var tempString2 = Object.keys(cartItem2)
                var quantity = cartItem2[tempString2[0]]
                total += (price * quantity)
                if (quantity > 0) {
                  totalQuantity += quantity;
                  return (
                    <ItemCart
                      image={prod.image}
                      title={"" + prod.name}
                      brand={"" + brand}
                      format={"" + size}
                      pz={"" + pack}
                      price={price != undefined ? price * quantity : 0.0}
                      quantity={quantity}
                      key={"cartItem"+index}
                    ></ItemCart>
                  )
                }
              }
            }
            return (<></>)
          })
        }
      </div>
      <div className="divider"></div>
      <div className="flex vertical" style={{display: "none"}}>
        <InputText placeholder={"Inserisci codice promo"} callback={handleOnChange} missed={false} missedText={"Codice non trovato"} secureText={false} onKeyDown={() => {}}></InputText>
        <a className="cta code" onClick={() => handleCoupon} style={{ opacity: isSubmitting ? '0.6' : '1' }}>
          <p>{isSubmitting ? "Caricamento" : "Salva"}</p>
        </a>
      </div>
      <div className="divider" style={{display: "none"}}></div>
      <div className="flex" style={{ display: "flex" }}>
        <p>Subtotale</p>
        <p className="data">€ {total.toFixed(2)}</p>
      </div>
      <div className="flex" style={{ display: "flex" }}>
        <p>Spedizione e altro</p>
        <p className="data">{costSpedition == undefined ? "Calcolate al prossimo step" : isLogged ? "€ " + costSpedition.toFixed(2) : "€ 0.00"}</p>
      </div>
      <div style={{display: 'none'}}>{ me != undefined && !me.isPrivate && ( <div className="flex" style={{ display: "flex" }}>
        <p>Servizi aggiuntivi</p>
        <p className="data">€{additionalCosts == undefined ? (0.0).toFixed(2) : additionalCosts.toFixed(2)}</p>
      </div> )}</div>
      <div className="divider"></div>
      <div className="flex" style={{ display: "flex" }}>
        <p>Subtotale</p>
        <p className="data">€{(total + costSpedition).toFixed(2)}</p>
      </div>
      <div className="flex" style={{ display: "flex" }}>
        <p>Sconto</p>
        <p className="data">€{(calcQuantity()).toFixed(2)}</p>
      </div>
      <div className="flex" style={{ display: "flex" }}>
        <p>Totale (Iva Inclusa)</p>
        <p className="data">€{costSpedition == undefined ? (total - calcQuantity()).toFixed(2) : additionalCosts != undefined ? (total + costSpedition + additionalCosts - calcQuantity()).toFixed(2) : (total + costSpedition - calcQuantity()).toFixed(2)}</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.domain.brandMap,
    sizes: state.domain.sizeMap,
    packs: state.domain.packMap,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    cart: state.shop.chart,
    prods: state.product.products,
    me: state.utils.me,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart)