import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Logo from "../../../../../assets/images/logo.svg";
import * as actions from "../../../../../store/actions";
import "../checkout.scss";
import { Spedition } from "./../../../../../models/product";
import { User } from "./../../../../../models/user";
import { InputText } from "./../../../widgets/Input/Text";
import "./subpages.scss";

const Pagamento = (props: any) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [user, setUser] = useState<User>({});
  const [flagDefaultIndirizzoDiFatturazione, setFlagIndirizzo] = useState(true);
  const [stripe, setStripe] = useState(undefined);
  const [flagIfDisagiato, setFlagIfDisagiato] = useState(false);

  var billingName: any = undefined,
    billingSurname: any = undefined,
    billingStreet: any = undefined,
    billingStreetNumber: any = undefined,
    billingCap: any = undefined,
    billingCity: any = undefined,
    billingProvince: any = undefined;
  var speditionObj: Spedition = {};

  useEffect(() => {
    setStripe(
      (window as any).Stripe(
        // "pk_test_51HnWesGVQ80q9EIpW1xqAiEoVLzM6S3NExC6msKJpAv2HO6Nq1uuSOYJsYXBcLXEgPjJEqGZgYZQrcZkJlPWZeNG00LBQolXm9"
        "pk_live_51HnWesGVQ80q9EIpwkliKFJ1SAeJc3QuMliKGxIWQF0KxYw8NO5LsOOrPnJmWhM1hWvZi5ph2I1OTbz7VlcUb8Lk00cl5IlIkl"
      )
    );
    var tempUser: User = props["me"];
    if (props["costs"] == 5) speditionObj.groundFloor = true;
    if (props["costs"] == 25) speditionObj.spondaIdraulica = true;
    if (props["coupon"] != undefined) speditionObj.promoCode = props["coupon"];
    setUser(tempUser);
  }, []);

  const onOrderSuccess = (res: { data: { sessionId: string } }) => {
    console.log("onOrderSuccess");
    const sessionId = res?.data?.sessionId;
    if (sessionId && stripe) {
      (stripe as any)
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then((result: any) => {
          history.push({
            pathname: "/order-ko",
          });
          console.log(result.error.message);
        });
    }
  };

  async function save() {
    setSubmittedFlag(false);
    setSubmitting(true);
    const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
    await delay(2000);
    try {
      if (!flagDefaultIndirizzoDiFatturazione) {
        user.billingName = billingName;
        user.billingSurname = billingSurname;
        user.billingStreet = billingStreet;
        user.billingStreetNumber = billingStreetNumber;
        user.billingCap = billingCap;
        user.billingCity = billingCity;
        user.billingProvince = billingProvince;
        //qui inviare l'oggetto user
      } else {
        user.billingName = user.speditionName;
        user.billingSurname = user.speditionSurname;
        user.billingStreet = user.address;
        user.billingStreetNumber = user.addressNumber;
        user.billingCap = user.cap;
        user.billingCity = user.city;
        user.billingProvince = user.province;
      }
      await props["sendOrder"](
        props["cart"],
        {
          disadvantagedLocation: setIfDisagiato(user),
          toIslands:
            (user.region == "Calabria" ||
              user.region == "Sicilia" ||
              user.region == "Sardegna") &&
            !setIfDisagiato(user),
        },
        user,
        onOrderSuccess
      );
      await props["modifyMe"](user);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setSubmitting(false);
    }
  }

  function setIfDisagiato(user: any) {
    if (user.cap == "80071") return true;
    if (user.cap == "7046") return true;
    if (user.cap == "80070") return true;
    if (user.cap == "9011") return true;

    if (user.cap == "57031") return true;
    if (user.cap == "80073") return true;
    if (user.cap == "57034") return true;
    if (user.cap == "9014") return true;

    if (user.cap == "80074") return true;
    if (user.cap == "91023") return true;
    if (user.cap == "80070") return true;
    if (user.cap == "80075") return true;

    if (user.cap == "58012") return true;
    if (user.cap == "58012") return true;
    if (user.cap == "58012") return true;
    if (user.cap == "80077") return true;

    if (user.cap == "7024") return true;
    if (user.cap == "80076") return true;
    if (user.cap == "57031") return true;
    if (user.cap == "92010") return true;

    if (user.cap == "30126") return true;
    if (user.cap == "92010") return true;
    if (user.cap == "98055") return true;
    if (user.cap == "57030") return true;

    if (user.cap == "57033") return true;
    if (user.cap == "91010") return true;
    if (user.cap == "57034") return true;
    if (user.cap == "30100") return true;

    if (user.cap == "4027") return true;
    if (user.cap == "91017") return true;
    if (user.cap == "4027") return true;
    if (user.cap == "57037") return true;

    if (user.cap == "57030") return true;
    if (user.cap == "80079") return true;
    if (user.cap == "57038") return true;
    if (user.cap == "57039") return true;

    if (user.cap == "57031") return true;
    if (user.cap == "30124") return true;
    if (user.cap == "9017") return true;
    if (user.cap == "80070") return true;

    if (user.cap == "71100") return true;
    if (user.cap == "90010") return true;
    if (user.cap == "4020") return true;
    if (user.cap == "30142") return true;

    if (user.cap == "30123") return true;
    if (user.cap == "30141") return true;
    if (user.cap == "30142") return true;
    if (user.cap == "30141") return true;

    if (user.cap == "30133") return true;
    if (user.cap == "30141") return true;
    if (user.cap == "30124") return true;
    if (user.cap == "30121") return true;

    if (user.cap == "30132") return true;
    if (user.cap == "30135") return true;
    if (user.cap == "30142") return true;
    if (user.cap == "30126") return true;

    if (user.cap == "30126") return true;
    if (user.cap == "30142") return true;
    if (user.cap == "30126") return true;
    if (user.cap == "30132") return true;

    if (user.cap == "30126") return true;
    if (user.cap == "30141") return true;
    if (user.cap == "30126") return true;
    if (user.cap == "30122") return true;

    if (user.cap == "30173") return true;
  }

  const handleName = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingName = e.target.value;
    else billingName = e.target.value;
    setUser(tempUser);
  };

  const handleSurname = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingSurname = e.target.value;
    else billingSurname = e.target.value;
    setUser(tempUser);
  };

  const handleAddress = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingStreet = e.target.value;
    else billingStreet = e.target.value;
    setUser(tempUser);
  };

  const handleAddNumber = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingStreetNumber = e.target.value;
    else billingStreetNumber = e.target.value;
    setUser(tempUser);
  };

  const handleCAP = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingCap = e.target.value;
    else billingCap = e.target.value;
    setUser(tempUser);
  };

  const handleCity = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingCity = e.target.value;
    else billingCity = e.target.value;
    setUser(tempUser);
  };

  const handleProvince = (e: any) => {
    var tempUser = user;
    if (flagDefaultIndirizzoDiFatturazione)
      tempUser.billingProvince = e.target.value;
    else billingProvince = e.target.value;
    setUser(tempUser);
  };

  const history = useHistory();
  async function goToCheckout() {
    history.push({
      pathname: "/catalogo",
      state: {},
    });
  }

  function goToSpedizione() {
    history.push({
      pathname: "/checkout/spedizione",
      state: {},
    });
  }

  function goToInformazioni() {
    history.push({
      pathname: "/checkout/informazioni",
      state: {},
    });
  }

  return (
    <div className="informazione pagamento">
      <img
        onClick={() => goToCheckout()}
        className="logo"
        src={Logo}
        alt="Logo"
        style={{ cursor: "pointer" }}
      />
      <ul className="indexPage">
        <li>
          <p style={{ cursor: "pointer" }} onClick={() => goToCheckout()}>
            Home &gt;{" "}
          </p>
        </li>
        <li>
          <p style={{ cursor: "pointer" }} onClick={() => goToInformazioni()}>
            Spedizione &gt;
          </p>
        </li>
        <li>
          <p className="active">Fatturazione</p>
        </li>
      </ul>
      <div className="info-box">
        <div className="row">
          <h3>E-mail</h3>
          <p>{user.email}</p>
        </div>
        <div className="divider"></div>
        <div className="row">
          <h3>Indirizzo</h3>
          <p>
            {user.address} {user.addressNumber}, {user.cap}, {user.city}
          </p>
          <span
            onClick={() => goToInformazioni()}
            style={{ cursor: "pointer" }}
          >
            Cambia
          </span>
        </div>
      </div>
      <div className="section">
        <h3 className="section-title">Indirizzo di fatturazione</h3>
        <div className="info-box">
          <div className="indirizzo-fatturazione-check">
            <input
              type="radio"
              id="huey"
              name="costs"
              value="costs0"
              checked={flagDefaultIndirizzoDiFatturazione}
              onChange={() => setFlagIndirizzo(true)}
            />
            <label>
              <b>Usa indirizzo di spedizione</b>
            </label>
          </div>
          <div className={"divider"}></div>
          <div className="indirizzo-fatturazione-check">
            <input
              type="radio"
              id="huey"
              name="costs"
              value="costs0"
              onChange={() => setFlagIndirizzo(false)}
            />
            <label>
              <b>Usa un indirizzo di fatturazione diverso</b>
            </label>
          </div>
          <div
            style={{
              display: flagDefaultIndirizzoDiFatturazione ? "none" : "block",
            }}
          >
            <div className={"flex"}>
              <div className="option">
                <p>Alla cortese attenzione di</p>
                <InputText
                  placeholder={"Nome"}
                  callback={handleName}
                  missed={false}
                  missedText={"Campo obbligatorio"}
                  secureText={false}
                  onKeyDown={() => {}}
                ></InputText>
              </div>
              <div className="option">
                <p>&nbsp;</p>
                <InputText
                  placeholder={"Cognome"}
                  callback={handleSurname}
                  missed={false}
                  missedText={"Campo obbligatorio"}
                  secureText={false}
                  onKeyDown={() => {}}
                ></InputText>
              </div>
            </div>
            <div className="option" style={{ width: "100%", padding: 0 }}>
              <p>Via</p>
              <InputText
                placeholder={"Via"}
                callback={handleAddress}
                missed={false}
                missedText={"Campo obbligatorio"}
                secureText={false}
                onKeyDown={() => {}}
              ></InputText>
            </div>
            <div className={"flex"}>
              <div className="option">
                <p>Numero</p>
                <InputText
                  placeholder={"Numero"}
                  callback={handleAddNumber}
                  missed={false}
                  missedText={"Campo obbligatorio"}
                  secureText={false}
                  onKeyDown={() => {}}
                ></InputText>
              </div>
              <div className="option">
                <p>CAP</p>
                <InputText
                  placeholder={"CAP"}
                  callback={handleCAP}
                  missed={false}
                  missedText={"Campo obbligatorio"}
                  secureText={false}
                  onKeyDown={() => {}}
                ></InputText>
              </div>
            </div>
            <div className={"flex"}>
              <div className="option">
                <p>Città</p>
                <InputText
                  placeholder={"Città"}
                  callback={handleCity}
                  missed={false}
                  missedText={"Campo obbligatorio"}
                  secureText={false}
                  onKeyDown={() => {}}
                ></InputText>
              </div>
              <div className="option">
                <p>Provincia</p>
                <InputText
                  placeholder={"Provincia"}
                  callback={handleProvince}
                  missed={false}
                  missedText={"Campo obbligatorio"}
                  secureText={false}
                  onKeyDown={() => {}}
                ></InputText>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex vertical">
        <p
          style={{ cursor: "pointer" }}
          onClick={() => goToInformazioni()}
          className="backTo"
        >
          &lt; Torna indietro
        </p>
        <a
          className="cta"
          onClick={() => (isSubmitting ? () => {} : save())}
          style={{ opacity: isSubmitting ? "0.6" : "1" }}
        >
          <p>{isSubmitting ? "Caricamento" : "Vai al pagamento"}</p>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    me: state.utils.me,
    cart: state.shop.chart,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyMe: (user: User) => {
      dispatch(actions.modifyMe(user));
    },
    sendOrder: (chart: any, spedition: any, user: any, onSuccess: any) =>
      dispatch(actions.sendOrder(chart, spedition, user, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagamento);
