import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import beer_image from "../../../assets/images/beer.png";
import cinder_image from "../../../assets/images/cider.png";
import seltzer_image from "../../../assets/images/seltz.png";
import { carouselResponsiveBreakpoints } from "../../../utils/consts";
import "./Products.scss";

function CarouselCard(props: any) {
  return (
    <div className="Carouselcard">
      <div className="writeBackground">{props.backgroundText}</div>
      <div
        className="image"
        style={{ backgroundImage: `url('${props.image}')` }}
      ></div>
    </div>
  );
}

export default class Products extends Component {
  state = {
    selected: "beer",
    sections: {
      beer: {
        backgroundText: "BEER",
        image: beer_image,
        backgroundColor: "yellow",
      },
      cider: {
        backgroundText: "CIDER",
        image: cinder_image,
        backgroundColor: "red",
      },
      seltzer: {
        backgroundText: "SELTZ",
        image: seltzer_image,
        backgroundColor: "white",
      },
    },
  };

  render() {
    const currentSlideMap = {
      0: "cider",
      1: "seltzer",
      2: "beer",
    };
    return (
      <div
        className={
          "Products " +
          (this.state.sections as any)[this.state.selected]?.backgroundColor
        }
        id="#products"
      >
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={carouselResponsiveBreakpoints}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={800}
          arrows={false}
          beforeChange={(currentSlide) => {
            this.setState({
              selected: (currentSlideMap as any)[currentSlide % 3],
            });
          }}
        >
          {Object.keys(this.state.sections).map((elemKey) => {
            const elem = (this.state.sections as any)[elemKey];
            return (
              <React.Fragment key={elemKey}>
                <CarouselCard {...elem} />
              </React.Fragment>
            );
          })}
        </Carousel>
      </div>
    );
  }
}
