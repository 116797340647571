import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { ShopImagesRest } from "../../../models/rest";
import * as actions from "../../../store/actions";
import { formatForm, getInitState, inputHandler } from "../../../utils/form";

class ModifyShopImages extends Component<any> {
  state = { form: {} };

  componentDidMount() {
    const shopImageId = this.props.location.state.id;
    let shopImage = this.props.shopImages.find(
      (el: any) => el.id === shopImageId
    );
    // if (shopImage) {
    //   ShopImagesRest.fields.style.hide = !shopImage.id.includes("beer");
    // }
    this.setState(getInitState(ShopImagesRest, shopImage));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    const objToSave = formatForm({
      ...this.state.form,
    });
    this.props.modifyShopImage(
      objToSave,
      this.props.history.push("/admin/images")
    );
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
          domains={this.props.domain}
        >
          <div className="text-center mb-3">
            <button className="btn btn-primary" type="submit">
              MODIFICA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    shopImages: state.shopImages.shopImages,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyShopImage: (brand: any, onSuccess: any) => {
      dispatch(actions.modifyShopImage(brand, onSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyShopImages);
