import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Burger from "../../../../assets/icons/burger.svg";
import Cart from "../../../../assets/icons/cart_black.svg";
import Search from "../../../../assets/icons/search.svg";
import User from "../../../../assets/icons/user.svg";
import Logo from "../../../../assets/images/logo.svg";
import OrdersIcon from "../../../../assets/images/orders-icon.png";
import WishlistIcon from "../../../../assets/images/wishlist-icon.png";
import AccountIcon from "../../../../assets/images/profile-icon.png";
import LogoutIcon from "../../../../assets/images/logout-icon.png";
import ChevronUp from "../../../../assets/images/chevron-down.png";
import ChevronDown from "../../../../assets/images/chevron-up.png";
import * as actions from "../../../../store/actions";
import Container from "../Basics/Container";
import Hide from "../Basics/Hide";
import Item from "./Cart/Item";
import CartComponent from './Cart';
import "./navbar.scss";
import GlobalSearch from "./GlobalSearch";
import FacebookIcon from '../../../../assets/icons/facebook_black.svg';
import InstagramIcon from '../../../../assets/icons/instagram_black.svg';

function CNavBar(props: any) {
  const [flagMobileMenu, setFlagMobileMenu] = useState(false);
  const [flagMobileCart, setFlagMobileCart] = useState(false);
  const [flagMobileProfileDropDown, setFlagMobileProfileDropDown] = useState(
    false
  );
  const [flagDesktopBirrerieDropDown, setflagDesktopBirrerieDropDown] = useState(false);
  const [flagDesktopStylesDropDown, setflagDesktopStylesDropDown] = useState(
    false
  );
  const [flagDesktopAltroDropDown, setflagDesktopAltroDropDown] = useState(
    false
  );
  const [flagGlobalSearch, setFlagGlobalSearch] = useState(false)
  var totalCart = 0;
  var cartElementQuantity = 0;

  const openProfileMenu = () => {
    setFlagMobileProfileDropDown(!flagMobileProfileDropDown);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  };

  const openBirrerie = () => {
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(!flagDesktopBirrerieDropDown);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  };

  const openStili = () => {
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(!flagDesktopStylesDropDown);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  };

  const openAltro = () => {
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(!flagDesktopAltroDropDown);
    setFlagGlobalSearch(false);
  };

  function toggleCart() {
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagMobileCart(!flagMobileCart);
    setFlagGlobalSearch(false);
  }

  function toogleGlobalSearch() {
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagMobileCart(false);
    setFlagGlobalSearch(!flagGlobalSearch)
  }

  async function handleGlobalSearch(text : string, flag : boolean) {
    await props["setFilter"]("genericSearch", text);
    if(flag) {
      setFlagGlobalSearch(!flagGlobalSearch)
      history.push({
        pathname: "/catalogo",
        state: {},
      });
    }
  }

  const history = useHistory();
  const toHome = ({ data, e }: any) => {
    //e.target.reset();
    history.push({
      pathname: "/shop",
      state: {},
    });
  };

  async function goToBirreria(id: any) {
    await props["resetFilters"]();
    await props["setFilter"]("brand", id, true);
    setflagDesktopBirrerieDropDown(false)
    history.push({
      pathname: "/catalogo",
      state: {
      },
    });
    setFlagMobileMenu(false)
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  }

  async function goToStyle(id: any) {
    await props["resetFilters"]();
    await props["setFilter"]("style", id, true);
    history.push({
      pathname: "/catalogo",
      state: {
      },
    });
    setFlagMobileMenu(false)
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  }

  async function goToTutteLeBirre() {
    await props["resetFilters"]();
    await props["setFilter"]("brand", null, true);
    history.push({
      pathname: "/catalogo",
      state: {
        categoria: 'Tutte le birre',
      },
    });
    setFlagMobileMenu(false)
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  }

  async function goToNovità() {
    await props["resetFilters"]();
    //await props["setFilter"]("new", true);
    history.push({
      pathname: "/new",
      state: {
        categoria: 'Novità',
      },
    });
    setFlagMobileMenu(false)
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  }

  async function goToPromo() {
    await props["resetFilters"]();
    //await props["setFilter"]("promo", true);
    history.push({
      pathname: "/promo",
      state: {
        categoria: 'Promo',
      },
    });
    setFlagMobileMenu(false)
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  }

  async function goTopRated() {
    await props["resetFilters"]();
    await props["setFilter"]("topRated", true);
    history.push({
      pathname: "/catalogo",
      state: {
        categoria: 'Top Rated',
      },
    });
    setFlagMobileMenu(false)
    setFlagMobileProfileDropDown(false);
    setflagDesktopBirrerieDropDown(false);
    setflagDesktopStylesDropDown(false);
    setflagDesktopAltroDropDown(false);
    setFlagGlobalSearch(false);
  }

  async function goToLogin() {
    props["resetFilters"]();
    history.push({
      pathname: "/login",
      state: {},
    });
  }

  async function goToRegister() {
    props["resetFilters"]();
    history.push({
      pathname: "/registration",
      state: {},
    });
  }

    async function goToProfile() {
      await props["resetFilters"]();
      history.push({
          pathname: "/profile",
          state: {},
      });
    }

    async function goToOrdini() {
      await props["resetFilters"]();
      history.push({
          pathname: "/profile/ordini",
          state: {},
      });
    }

    async function goToFavoriti() {
      await props["resetFilters"]();
      history.push({
          pathname: "/profile/favoriti",
          state: {},
      });
    }

    async function logOut() {
      await props["resetFilters"]();
      history.push({
          pathname: "/logout",
          state: {},
      });
    }

    async function goToAboutUs() {
      await props["resetFilters"]();
      history.push({
        pathname: "/",
        state: {},
      });
    }

  return (
    <>
      <div className="under-bar"></div>
      <nav className="shop shop-navbar">
        <div className="body">
          <Container>
            <div className="shop-flex">
              <div className="shop-v-middle">
                <Hide forMobile={true}>
                  <img
                    onClick={() => setFlagMobileMenu(!flagMobileMenu)}
                    className="icon"
                    src={Burger}
                    alt="Burger Icon"
                  />
                </Hide>
                <img onClick={toHome} className="logo" src={Logo} alt="Logo" />
              </div>
              <Hide forMobile={false}>
                <ul>
                  <li onClick={() => goToTutteLeBirre()}>Tutte le birre</li>
                  <li onClick={() => goToNovità()}>Novità</li>
                  <li className="dropdown">
                    <span onClick={() => openBirrerie()}>Birrerie</span>
                    <div
                      onClick={() => openBirrerie()}
                      className={
                        flagDesktopBirrerieDropDown
                          ? "shop-drop-down-menu"
                          : "shop-drop-down-menu hide-drop-down-menu"
                      }
                    >
                      <Container>
                        <ul className="list-drop-down-menu">
                          <h3 className="top20">Birrerie</h3>
                          <br />
                          {props != null &&
                            props["brands"] != undefined &&
                            props["brands"].map(
                              (element: any, index: number) => {
                                if (index < 17) {
                                  var brand = element.name;
                                  return (
                                    <li key={"brandsVoice"+brand+index}>
                                      <p
                                        onClick={() => goToBirreria(element.id)}
                                      >
                                        {brand}
                                      </p>
                                    </li>
                                  );
                                } else if (index == 18) {
                                  return (
                                    <li key={"brandsAllVoices"}>
                                      <p>
                                        <b>
                                          <a href="/all-brands">A-Z Birrerie</a>
                                        </b>
                                      </p>
                                    </li>
                                  );
                                }
                              }
                            )}
                        </ul>
                      </Container>
                    </div>
                  </li>
                  <li className="dropdown">
                    <span onClick={() => openStili()}>Stili</span>
                    <div
                      onClick={() => openStili()}
                      className={
                        flagDesktopStylesDropDown
                          ? "shop-drop-down-menu"
                          : "shop-drop-down-menu hide-drop-down-menu"
                      }
                    >
                      <Container>
                        <ul className="list-drop-down-menu">
                          <h3 className="top20">Stili</h3>
                          <br />
                          {props != null &&
                            props["styles"] != undefined &&
                            props["styles"].map((element: any) => {
                              var style = element.label;
                              return (
                                <li key={"styleVoice"+style}>
                                  <p onClick={() => goToStyle(element.value)}>{style}</p>
                                </li>
                              );
                            })}
                        </ul>
                      </Container>
                    </div>
                  </li>
                  <li onClick={() => goToPromo()}>Promo</li>
                  <li onClick={() => goTopRated()}>Top rated</li>
                  <li className="dropdown" style={{display: 'none'}}>
                    <span onClick={() => openAltro()}>Altro</span>
                    <div
                      onClick={() => openAltro()}
                      className={
                        flagDesktopAltroDropDown
                          ? "shop-drop-down-menu"
                          : "shop-drop-down-menu hide-drop-down-menu"
                      }
                    >
                      <Container>
                        <ul className="list-drop-down-menu">
                          <li>Sidri</li>
                          <li>Seltzer</li>
                        </ul>
                      </Container>
                    </div>
                  </li>
                  <li onClick={() => goToAboutUs()}>About Us</li>
                </ul>
              </Hide>
              <div>
                <img className="icon" src={Search} alt="Search Icon" onClick={() => toogleGlobalSearch()}/>
                <img
                  className="icon"
                  src={User}
                  alt="User Icon"
                  onClick={() => props["isLogged"] ? setFlagMobileProfileDropDown(!flagMobileProfileDropDown) : goToLogin()}
                />
                <div className={"icon lastOnRight"}>
                  { props["cart"] != undefined && Object.keys(props["cart"]).length != 0 && Object.keys(props["cart"]).map((item, i) => {
                    
                    var cartItem = props["cart"][item]
                    var tempString = Object.keys(cartItem)
                    var typIds = cartItem[tempString[0]]
                    var arrayTypIds = Object.keys(typIds)
                    var quantity = typIds[arrayTypIds[0]]
                    if (quantity > 0) totalCart+=quantity;
                    return ( <div className="cartCount" style={{display: totalCart > 0 ? 'flex' : 'none'}} key={"cartProd"+i}></div> )
                  }) }
                  <img
                    className="icon lastOnRight"
                    src={Cart}
                    alt="Cart Icon"
                    onClick={() => toggleCart()}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Hide forMobile={true}>
          <div
            className={
              flagMobileMenu
                ? "shop-mobile-menu-shadow"
                : "shop-mobile-menu-shadow hide-mobile-menu"
            }
            onClick={() => setFlagMobileMenu(!flagMobileMenu)}
          ></div>
          <div
            className={
              flagMobileMenu
                ? "shop-mobile-menu"
                : "shop-mobile-menu hide-mobile-menu"
            }
          >
            <div className="shop-v-space-between" style={{height: "96vh"}}>
              <div>
                <div className="user-section">
                  <div className="shop-v-middle">
                  <img onClick={toHome} className="logo" src={Logo} alt="Logo" />
                  </div>
                </div>
                <ul>
                  <li><p onClick={() => goToTutteLeBirre()}>Tutte le birre</p></li>
                  <li onClick={() => goToNovità()}><p>Novità</p></li>
                  <li>
                    <div className={"dropdownmobile"} onClick={() => setflagDesktopBirrerieDropDown(!flagDesktopBirrerieDropDown)}>
                      <p className={"dropdown-text"}>Birrerie</p>
                      <img className={"dropdown-icon"} src={flagDesktopBirrerieDropDown ? ChevronDown : ChevronUp} />
                    </div>
                    <div className={flagDesktopBirrerieDropDown ? "dropdownmobile-menu opened" : "dropdownmobile-menu"}>
                      <ul>
                        {
                        props != null &&
                        props["brands"] != undefined &&
                        props["brands"].map(
                          (element: any, index: number) => {
                            if (index < 7) {
                              var brand = element.name;
                              return (
                                <li key={"brandsVoiceMobile"+brand}>
                                  <p
                                    onClick={() => goToBirreria(element.id)}
                                  >
                                    {brand}
                                  </p>
                                </li>
                              );
                            } else if (index == 18) {
                              return (
                                <li key={"allVoiceMobile"}>
                                  <p>
                                    <b>
                                      <a href="/all-brands">A-Z Birrerie</a>
                                    </b>
                                  </p>
                                </li>
                              );
                            }
                          }
                        )
                      }
                      </ul>
                    </div>
                  </li>
                  <li>
                  <div className={"dropdownmobile"} onClick={() => setflagDesktopStylesDropDown(!flagDesktopStylesDropDown)}>
                      <p className={"dropdown-text"}>Stili</p>
                      <img className={"dropdown-icon"} src={flagDesktopBirrerieDropDown ? ChevronDown : ChevronUp}/>
                    </div>
                    <div className={flagDesktopStylesDropDown ? "dropdownmobile-menu opened" : "dropdownmobile-menu"}>
                      <ul>
                        {props != null &&
                          props["styles"] != undefined &&
                          props["styles"].map((element: any) => {
                            var style = element.label;
                            return (
                              <li key={"styleVoiceMobile"+style}>
                                <p onClick={() => goToStyle(element.value)}>{style}</p>
                              </li>
                            );
                        })}
                      </ul>
                    </div>
                  </li>
                  <li onClick={() => goToPromo()}><p>Promo</p></li>
                  <li onClick={() => goTopRated()}><p>Top rated</p></li>
                  <li style={{display: 'none'}}>
                    <div className={"dropdownmobile"} onClick={() => setflagDesktopAltroDropDown(!flagDesktopAltroDropDown)}>
                      <p className={"dropdown-text"}>Altro</p>
                      <img className={"dropdown-icon"} src={flagDesktopBirrerieDropDown ? ChevronDown : ChevronUp}/>
                    </div>
                    <div className={flagDesktopAltroDropDown ? "dropdownmobile-menu opened" : "dropdownmobile-menu"}>
                      <ul>
                        <li><p>Sidri</p></li>
                        <li><p>Seltzer</p></li>
                      </ul>
                    </div>
                  </li>
                  <li onClick={() => goToAboutUs()}><p>About us</p></li>
                </ul>
              </div>
              <div className="social-section">
                <div className="shop-v-middle">
                  <div><a href="https://www.facebook.com/eurosagasrl"><img src={FacebookIcon} style={{width: "32px", height: "32px", marginRight: '16px'}}/></a></div>
                  <div><a href="https://www.instagram.com/eurosaga_official/?hl=en"><img src={InstagramIcon} style={{width: "32px", height: "32px"}}/></a></div>
                </div>
              </div>
            </div>
          </div>
        </Hide>
        <div
          className={
            flagMobileCart
              ? "shop-cart-menu-shadow"
              : "shop-cart-menu-shadow hide-cart-menu"
          }
          onClick={() => setFlagMobileCart(false)}
        ></div>
        <div
          className={
            flagMobileCart ? "shop-cart-menu" : "shop-cart-menu hide-cart-menu"
          }
        >
          <div className="list-cart-menu">
                  { props["cart"] != undefined && Object.keys(props["cart"]).length != 0 && Object.keys(props["cart"]).map((item) => {
                    
                    var cartItem = props["cart"][item]
                    var tempString = Object.keys(cartItem)
                    var typIds = cartItem[tempString[0]]
                    var arrayTypIds = Object.keys(typIds)
                    var quantity = typIds[arrayTypIds[0]]
                    cartElementQuantity++;
                    if (cartElementQuantity == 1)
                    return ( <CartComponent key={"cart"} toggleCart={() => toggleCart()} quantity={totalCart}></CartComponent> )
                    else return <></>;
                  }) }
                  { props["cart"] != undefined && Object.keys(props["cart"]).length == 0 && (<CartComponent key={"cart"} toggleCart={() => toggleCart()} quantity={0}></CartComponent>)}
            
          </div>
        </div>
        <Container>
            <div className={flagMobileProfileDropDown ? "shop-profile-menu" : "shop-profile-menu hide-profile-menu"}>
                { props["isLogged"] != undefined && props["isLogged"] ? (
                    <ul className="list-profile-menu">
                      <li>
                        <img className="icon" src={OrdersIcon} />
                        <p style={{cursor: "pointer"}} onClick={() => goToOrdini()}>I miei ordini</p>
                      </li>
                      <li>
                        <img className="icon" src={WishlistIcon} />
                        <p style={{cursor: "pointer"}} onClick={() => goToFavoriti()}>Preferiti</p>
                      </li>
                      <li>
                        <img className="icon" src={AccountIcon} />
                        <p style={{cursor: "pointer"}} onClick={() => goToProfile()}>Account</p>
                      </li>
                      <li>
                        <img className="icon" src={LogoutIcon} />
                        <p style={{cursor: "pointer"}} onClick={() => logOut()}>Esci</p>
                      </li>
                    </ul>
                ) : (
                    <ul className="list-profile-menu">
                        <li>
                            <p style={{cursor: "pointer"}} onClick={() => goToLogin()}>Accedi</p>
                        </li>
                        <li>
                            <p style={{cursor: "pointer"}} onClick={() => goToRegister()}>Registrati</p>
                        </li>
                    </ul>
                ) }
            </div>
        </Container>
        <div style={{display: flagGlobalSearch ? "block" : "none"}}>
          <GlobalSearch searchEvent={handleGlobalSearch} closeEvent={() => setFlagGlobalSearch(false)}></GlobalSearch>
        </div>
      </nav>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    cart: state.shop.chart,
    prods: state.domain.products,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    },
    setSortFilter: (sortKey : any, order : any) => {
      dispatch(actions.setSort(sortKey, order))
    }
  };
};

const NNavBar = connect(mapStateToProps, mapDispatchToProps)(CNavBar);

export default function NavBar() {
  return <NNavBar></NNavBar>;
}
