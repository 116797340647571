import React from 'react'
import './profile.scss'

const Sidebar = ({index} : any) => {
    return (
        <div className="sidebar">
            <ul>
                <li>
                    { index == "dashboard" ? <p><b>Dashboard</b></p> : <p><a href="/profile/dashboard">Dashboard</a></p> }
                    <div className="divider"></div>
                </li>
                <li>
                    { index == "fatturazione" ? <p><b>Indirizzo di fatturazione</b></p> : <p><a href="/profile/fatturazione">Indirizzo di fatturazione</a></p> }
                    <div className="divider"></div>
                </li>
                <li>
                    { index == "spedizione" ? <p><b>Indirizzo di spedizione</b></p> : <p><a href="/profile/spedizione">Indirizzo di spedizione</a></p> }
                    <div className="divider"></div>
                </li>
                <li>
                    { index == "ordini" ? <p><b>I miei ordini (soon)</b></p> : <p><a href="/profile/ordini">I miei ordini (soon)</a></p> }
                    <div className="divider"></div>
                </li>
                <li>
                    { index == "wishlist" ? <p><b>Wishlist (soon)</b></p> : <p><a href="/profile/wishlist">Wishlist (soon)</a></p> }
                    <div className="divider"></div>
                </li>
                <li>
                    { index == "logout" ? <p><b>Logout</b></p> : <p><a href="/profile/logout">Logout</a></p> }
                </li>
            </ul>
        </div>
    )
}

export default Sidebar