import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "../../widgets/NavBar/NavBar";
import './home.scss';
import '../../widgets/Widgets/widgets.scss'

import { WidgetLarge, WidgetMedium, WidgetSmall } from '../../widgets/Widgets/widgets'
import Container from "../../widgets/Basics/Container";
import BestOf from './BestOf'
import Footer from "../../widgets/Footer/Footer";
import Brands from "./Brands";
import { useHistory } from 'react-router';
import Hide from "../../widgets/Basics/Hide";
import BoxesImage from "../../../../assets/images/boxes/boxes_mobile.jpg";
import { Brand } from './../../../../models/product';
import Header from "./Header";
import Icon1 from "../../../../assets/images/banners/icon1.png";
import Icon2 from "../../../../assets/images/banners/icon2.png";
import Icon3 from "../../../../assets/images/banners/icon3.png";
import Icon4 from "../../../../assets/images/banners/icon4.png";

function Home({brands, images} : any) {
  const history = useHistory();

  async function goToBoxes() {
    history.push({
      pathname: "/about-box",
      state: {}
    });
  }

  return (
    <div className="shop">
        <NavBar></NavBar>
        <Hide forMobile={false}>
          <div className="banner" onClick={() => goToBoxes()}>
            <div className="flex">
              <img src={Icon1} className="icon" />
              <p className="label">Spedizione gratis*</p>
            </div>
            <div className="flex">
              <img src={Icon2} className="icon" />
              <p className="label">Consegna in 24/48h</p>
            </div>
            <div className="flex">
              <img src={Icon3} className="icon" />
              <p className="label">Pagamenti sicuri</p>
            </div>
            <div className="flex">
              <img src={Icon4} className="icon" />
              <p className="label">Più acquisti più risparmi</p>
            </div>
          </div>
        </Hide>
        <Hide forMobile={true}>
          <div className="banner" onClick={() => goToBoxes()}>
            <div className="flex">
              <img src={Icon1} className="icon" />
              <p className="label">Spedizione gratis* in 24/48h | Scopri</p>
            </div>
          </div>
        </Hide>
        <Hide forMobile={false}>
          <Header withArrows={false} images={[images[16], images[16], images[18], images[18],]}></Header>
        </Hide>
        <Hide forMobile={true}>
          <Header withArrows={false} images={[images[17], images[16], images[19], images[18],]}></Header>
        </Hide>
        <Container>
          <div className="row">
            <div className="col">
              <WidgetLarge imageKey={images[5].id} image={images[5].image} categoria={images[5].title} title={images[5].title} description={images[5].desc}></WidgetLarge>
            </div>
            <div className="col wM">
              <WidgetMedium imageKey={images[12].id}  image={images[12].image} categoria={images[12].title} title={images[12].title} description={images[12].desc}></WidgetMedium>
              <WidgetMedium imageKey={images[13].id}  image={images[13].image} categoria={images[13].title} title={images[13].title} description={images[13].desc}></WidgetMedium>
            </div>
          </div>
        </Container>
        <BestOf images={images}></BestOf>
        <Container>
          <div className="row">
            <div className="col">
              <WidgetMedium imageKey={images[14].id}  image={images[14].image} categoria={images[14].title} title={images[14].title} description={images[14].desc}></WidgetMedium>
            </div>
            <div className="col wM">
              <WidgetMedium imageKey={images[15].id}  image={images[15].image} categoria={images[15].title} title={images[15].title} description={images[15].desc}></WidgetMedium>
            </div>
          </div>
        </Container>
        <Brands brands={brands}></Brands>
        <Footer></Footer>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands.filter((el: Brand) => !el.hide)
    .sort((a: Brand, b: Brand) => (b.priority || 0) - (a.priority || 0)),
    images: state.shopImages.shopImages,
  }
}

export default connect(mapStateToProps)(Home);
