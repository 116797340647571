import React from "react"
import Container from "../Basics/Container";

import SearchIcon from "../../../../assets/icons/search.svg";
import CloseIcon from "../../../../assets/images/close_grey.png";

const GlobalSearch = ({searchEvent, closeEvent} : any) => {
    var text = "";

    const handleChangeText = (e : any) => {
        text = e.target.value;
        searchEvent(text, false)
    }    

    const handleKeyDown = (e : any) => {
        if (e.key === 'Enter') {
          searchEvent(text, true)
        }
    }

    return (
      <div className="globalSearch">
        <div className="shadow" onClick={closeEvent}></div>
        <div className="searchBody">
          <Container>
            <div className="flex-space-btw">
                <img src={SearchIcon} onClick={() => searchEvent(text, true)}/>
                <input ref={input => input && input.focus()} placeholder={"Trova prodotti"} type="text" onChange={handleChangeText} onKeyDown={handleKeyDown} />
            </div>
          </Container>
        </div>
      </div>
    )
}

export default GlobalSearch