import React, { Component } from "react";
import AboutUs from "./AboutUs/AboutUs";
import Actions from "./Actions/Actions";
import Home from "./Home/Home";
import Products from "./Products/Products";
import Partners from "./Partners/Partners";
import { withTranslation } from "react-i18next";
import Mail from "./Mail/Mail";
import backgroundImage from "../../assets/images/landing_background.png";

class Landing extends Component<any> {
  state = {};
  render() {
    return (
      <div
        style={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundAttachment: "fixed",
          backgroundRepeat: "round",
          backgroundSize: "contain",
        }}
      >
        <Home />
        <AboutUs />
        <Products />
        <Partners />
        <Actions />
        <Mail />
      </div>
    );
  }
}

export default withTranslation("common")(Landing);
