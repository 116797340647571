import React, { useState } from 'react'

const Ordini = (props : any) => {
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submittedFlag, setSubmittedFlag] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    async function send() {
        try {
          setSubmitting(true)
          if (email == '' || password == '') {
            setSubmitting(false)
            setSubmittedFlag(true)
          } else {
            // call function here props.signIn()
            var response = props["firebase"].doSignInWithEmailAndPassword('', '')
            setSubmitting(false)
          }
        } catch (error) {
          setError(error)
        }
    }

    const handleEmail = (e : any) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e : any) => {
        setPassword(e.target.value)
    }

    return (
        <div className="page">
            <h2>I miei ordini</h2>
            <p>Coming soon!</p>
        </div>
    )
}

export default Ordini