import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions";
import { Range, getTrackBackground } from "react-range";

const STEP = 1;
const MIN = 1;
const MAX = 60;

class DoubleRange extends React.Component<{
    setFilter: any;
    forMobile: boolean;
}> {
  state = {
    values: [1,60]
  };

  handleOnChange(values : any) {
    this.setState({ values });
    this.props.setFilter('price', [this.state.values[0].toFixed(2), this.state.values[1].toFixed(2)])
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <output style={{ width: "100%", marginBottom: "16px", marginLeft: this.props.forMobile ? "-16px" : 0}} id="output">
            <div 
                style={{
                  width: this.props.forMobile ? "calc(100% + 24px)" : "100%",
                  marginLeft: this.props.forMobile ? "-8px" : 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }} 
            >
                <h3 style={{fontSize: this.props.forMobile ? "18px" : "24px",}}>Min</h3>
                <h3 style={{fontSize: this.props.forMobile ? "18px" : "24px",}}>Max</h3>
            </div>
            <div 
                style={{
                  width: this.props.forMobile ? "calc(100% + 24px)" : "100%",
                  marginLeft: this.props.forMobile ? "-8px" : 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }} 
            >
                <p style={{fontSize: this.props.forMobile ? "16px" : "20px",}}>€{this.state.values[0].toFixed(2)}</p>
                <p style={{fontSize: this.props.forMobile ? "16px" : "20px",}}>€{this.state.values[1].toFixed(2)}</p>
            </div>
        </output>
        <Range
          values={this.state.values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => this.handleOnChange(values)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: ["#ccc", "#000", "#ccc"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: this.props.forMobile ? "32px" : "16px",
                width: this.props.forMobile ? "32px" : "16px",
                borderRadius: this.props.forMobile ? "16px" : "16px",
                backgroundColor: "#000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA"
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#000" : "#000"
                }}
              />
            </div>
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
      setFilter: (key: string, value: string) => {
        dispatch(actions.setFilter(key, value, true));
      },
    };
  };
  
export default connect(null, mapDispatchToProps)(DoubleRange);
