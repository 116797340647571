import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "recompose";
import AuthModal, { AuthModalType } from "./components/AuthModal/AuthModal";
import Layout from "./components/Layout/Layout";
import Spinner from "./components/Spinner/Spinner";
import { withFirebase } from "./hoc/firebase/context";
import { DomainRest, Domains } from "./models/rest";
import Admin from "./pages/Admin";
import Landing from "./pages/Landing";
import Menu from "./pages/Menu";
import AllBrands from "./pages/Shop/subpages/All_Brands/AllBrands";
import Catalogo from "./pages/Shop/subpages/Catalogo/Catalogo";
import Checkout from "./pages/Shop/subpages/Checkout/Checkout";
import ConfirmRegistration from "./pages/Shop/subpages/ConfirmRegistration/ConfirmRegistration";
import Shop from "./pages/Shop/subpages/Home/Home";
import Login from "./pages/Shop/subpages/Login/Login";
import Logout from "./pages/Shop/subpages/Logout/Logout";
import OrderKo from "./pages/Shop/subpages/OrderKo/OrderKo";
import OrderOk from "./pages/Shop/subpages/OrderOk/OrderOk";
import PreLogin from "./pages/Shop/subpages/PreLogin/PreLogin";
import Profile from "./pages/Shop/subpages/Profile/Profile";
import Registration from "./pages/Shop/subpages/Registration/Registration";
import SchedaProdotto from "./pages/Shop/subpages/Scheda_Prodotto/Scheda";
import BoxPage from "./pages/Shop/subpages/BoxPage/BoxPage";
import RecuperaPassoword from './pages/Shop/subpages/RecuperaPassword/RecuperaPassword';
import * as action from "./store/actions";
import { scrollTo } from "./utils/functions";
import LoginWidget from "./pages/Shop/subpages/Checkout/subpages/LoginWidget";
import Promo from "./pages/Shop/subpages/Catalogo/permalinks/Promo";
import Novita from './pages/Shop/subpages/Catalogo/permalinks/Novita';

class App extends React.Component<{
  firebase: any;
  setAuthToken: any;
  unsetAuthToken: any;
  location: any;
  getDomain: any;
  loadAllProducts: any;
  loadAllBrands: any;
  loadAllShopImages: any;
  getAllOrders: any;
  history: any;
  isLogged: boolean;
  isEditor: boolean;
  viewSpinner: true;
  getMe: any;
}> {
  state = {
    navbarVisible: true,
    isAuthModalOpen: false,
    authMode: null,
    isMobile: undefined,
  };

  lastScrollTop = 0;

  openAuthModalHandler = (mode: AuthModalType) => {
    this.setState({ isAuthModalOpen: true, authMode: mode });
  };

  closeAuthModalHandler = () => {
    this.setState({ isAuthModalOpen: false });
  };

  logoutHandler = () => {
    this.props.firebase.doSignOut();
  };

  catchAuth = () => {
    this.props.firebase.auth.onAuthStateChanged((authUser: any) => {
      if (authUser) {
        authUser
          .getIdTokenResult()
          .then((user: { claims: { editor: boolean }; token: string }) => {
            this.props.setAuthToken(user?.token, user?.claims?.editor || false);
            this.props.getAllOrders();
            this.props.getMe();
          });
      } else {
        this.props.unsetAuthToken();
      }
    });
  };

  render() {
    return (
      <Layout
        logoClick={this.logoClickHandler}
        menuClick={this.menuClickHandler}
        isNavbavVisible={
          this.state.navbarVisible && this.props.location.pathname !== "/menu"
        }
        isFooterVisible={this.props.location.pathname !== "/menu"}
        openAuthModal={this.openAuthModalHandler}
        logoutHandler={this.logoutHandler}
        isLogged={this.props.isLogged}
        isMobile={this.state.isMobile}
      >
        {this.props.viewSpinner && this.props.location.pathname !== "/" ? (
          <Spinner />
        ) : null}
        <AuthModal
          isOpen={this.state.isAuthModalOpen}
          closeModal={this.closeAuthModalHandler}
          mode={this.state.authMode}
        />
        <Switch>
          <Route path="/shop" component={Shop} />
          <Route path="/catalogo" component={Catalogo} />
          <Route path="/prod" component={SchedaProdotto} />
          <Route path="/all-brands" component={AllBrands} />
          <Route path="/prelogin" component={PreLogin} />
          <Route path="/login" component={Login} />
          <Route path="/registration" component={Registration} />
          <Route path="/logout" component={Logout} />
          <Route path="/confirm-registration" component={ConfirmRegistration} />
          <Route path="/order-ok" component={OrderOk} />
          <Route path="/order-ko" component={OrderKo} />
          <Route path="/profile" component={Profile} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/about-box" component={BoxPage} />
          <Route path="/recupera-password" component={RecuperaPassoword} />
          <Route path="/checkout-login" component={LoginWidget} />
          <Route path="/promo" component={Promo} />
          <Route path="/new" component={Novita} />
          <Route path="/novità" component={Novita} />
          <Route
            path="/menu"
            component={() => (
              <Menu
                closeClick={this.menuClickHandler}
                navigate={this.navigate}
              />
            )}
          />
          {this.props.isEditor ? (
            <Route path="/admin" component={Admin} />
          ) : null}
          <Route path="/" component={Landing} />
        </Switch>
      </Layout>
    );
  }

  resetStorageAfterUpdate() {
    if (!localStorage.getItem("alreadyReset")) {
      setTimeout(() => {
        localStorage.removeItem("state");
        localStorage.setItem("alreadyReset", "true");
      }, 3000);
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    Object.keys(DomainRest).forEach((domain) => {
      this.props.getDomain(domain);
    });
    this.props.loadAllProducts();
    this.props.loadAllBrands();
    this.props.loadAllShopImages();
    this.catchAuth();
    this.resetStorageAfterUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleResize = () => {
    const windowSize = window.innerWidth;
    this.setState({ windowSize, isMobile: windowSize < 767 });
  };

  handleScroll = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > this.lastScrollTop) {
      if (this.state.navbarVisible) {
        this.setState({ navbarVisible: false });
      }
    } else {
      if (!this.state.navbarVisible) {
        this.setState({ navbarVisible: true });
      }
    }
    this.lastScrollTop = st <= 0 ? 0 : st;
  };

  logoClickHandler = () => {
    this.navigate("/");
  };

  menuClickHandler = () => {
    if (this.props.location.pathname === "/menu") {
      this.props.history.push("/");
    } else {
      this.props.history.push("/menu");
    }
  };

  navigate = (page: string) => {
    if (page.includes("#")) {
      this.props.history.push("/");
      setTimeout(() => {
        scrollTo(page);
      });
    } else {
      this.props.history.push(page);
    }
  };
}

const mapStateToProps = (state: any) => {
  return {
    isLogged: state.utils.isLogged,
    isEditor: state.utils.isEditor,
    viewSpinner: state.utils.restToWait > 0,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getDomain: (domName: Domains) => dispatch(action.getDomain(domName)),
    loadAllProducts: () => dispatch(action.getAllProducts()),
    loadAllBrands: () => dispatch(action.getAllBrands()),
    setAuthToken: (token: any, isEditor: boolean) =>
      dispatch(action.setAuthToken(token, isEditor)),
    unsetAuthToken: () => dispatch(action.unsetAuthToken()),
    getAllOrders: () => dispatch(action.getAllOrders()),
    getMe: () => dispatch(action.getMe()),
    loadAllShopImages: () => dispatch(action.getAllShopImages()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withRouter
)(App as any);
