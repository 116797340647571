import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { withRouter } from "react-router-dom";
import footerLogo from "../../assets/images/footer_logo.png";
import logo from "../../assets/images/logo.svg";
import { scrollTo } from "../../utils/functions";
import "./Layout.scss";

function FooterSection(props: any) {
  const [visible, setVisible] = useState(false);
  return (
    <div className="section" onClick={() => setVisible(!visible)}>
      <div className="row">
        <h6>{props.title}</h6>
        <div className="eurosaga icon-plus"></div>
      </div>
      {visible ? (
        <div className="expansionSection">{props.children}</div>
      ) : null}
    </div>
  );
}

function LandingMenu(props: {
  isNavbavVisible: boolean;
  isFooterVisible: boolean;
  logoClick: any;
  menuClick: any;
  children: any;
  openAuthModal: any;
  logoutHandler: any;
  isLogged: boolean;
  history: any;
  isMobile: boolean;
}) {
  return (
    <React.Fragment>
      {props.isNavbavVisible ? (
        <nav className="menu">
          <a onClick={props.logoClick} className="logo" href="/">
            <img src={logo} alt="" />
          </a>
          <div className="rightDiv">
            <div onClick={props.menuClick} className="eurosaga icon-menu"></div>
          </div>
        </nav>
      ) : null}
      <main className={props.isNavbavVisible ? "" : "no-menu"}>
        {props.children}
      </main>
      {props.isFooterVisible ? (
        props.isMobile ? (
          <footer className="landingFooterMobile">
            <FooterSection title="ABOUT US">
              <div className="row-3-col">
                <br />
                <h6>
                  Eurosaga s.r.l
                  <br />
                  Via Bergamo n.12
                  <br />
                  San Giuliano Milanese (Mi)
                </h6>
                <h6>
                  (+39) 02 98281321
                  <br />
                  lun-ven 9.00/17.00
                </h6>
                <h6>P.iva: 10047450159 </h6>
              </div>
            </FooterSection>
            <hr />
            <FooterSection title="I NOSTRI UFFICI">
              <div className="row-3-col offices">
                <div>
                  <h5>Import</h5>
                  <h6>info@eurosaga.it</h6>
                </div>
                <div>
                  <h5>Marketing & eventi</h5>
                  <h6>marketing@eurosaga.it</h6>
                </div>
                <div>
                  <h5>Amministrazione</h5>
                  <h6>contabilita@eurosaga.it</h6>
                </div>
              </div>
            </FooterSection>
            <hr />
            <FooterSection title="NAVIGA">
              <h5 onClick={() => scrollTo("#aboutUs")}>About us</h5>
              <h5 onClick={() => scrollTo("#products")}>Prodotti</h5>
              <h5 onClick={() => props.history.push("/shop")}>Shop</h5>
              <h5 onClick={() => scrollTo("#contactUs")}>Contatti</h5>
            </FooterSection>
            <hr />
            <div className="iconsRow">
              <img src={footerLogo} alt="" className="footerLogo" />
              <div className="icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/pages/category/Food---Beverage-Company/Eurosaga-srl-425043784249195/"
                >
                  <div className="eurosaga icon-facebook-black"></div>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/eurosaga_official/?hl=bn"
                >
                  <div className="eurosaga icon-instagram-black"></div>
                </a>
              </div>
            </div>

            <p>Copyright© 2020 Designed by Yuri Ceriani</p>
          </footer>
        ) : (
          <footer className="landingFooter">
            <div className="col leftCol">
              {/* <h4>:eurosaga:</h4> */}
              {/* <div
              className="footerLogo"
              style={{ backgroundImage: `url('${footerLogo}')` }}
              
            ></div> */}
              <img src={footerLogo} alt="" className="footerLogo" />
              <h6>
                Eurosaga s.r.l
                <br />
                Via Bergamo n.12
                <br />
                San Giuliano Milanese (Mi)
              </h6>
              <h6>
                (+39) 02 98281321
                <br />
                lun-ven 9.00/17.00
              </h6>
              <h6>P.iva: 10047450159 </h6>
              <div className="row">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/pages/category/Food---Beverage-Company/Eurosaga-srl-425043784249195/"
                >
                  <div className="eurosaga icon-facebook grey"></div>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/eurosaga_official/?hl=bn"
                >
                  <div className="eurosaga icon-instagram grey"></div>
                </a>
              </div>
            </div>
            <div className="col centerCol">
              <h4>I nostri uffici</h4>
              <h5>Import</h5>
              <h6>info@eurosaga.it</h6>
              <h5>Marketing & eventi</h5>
              <h6>marketing@eurosaga.it</h6>
              <h5>Amministrazione</h5>
              <h6>contabilita@eurosaga.it</h6>
            </div>
            <div className="col rightCol">
              <h4>Naviga</h4>
              <h5 onClick={() => scrollTo("#aboutUs")}>About us</h5>
              <h5 onClick={() => scrollTo("#products")}>Prodotti</h5>
              <h5 onClick={() => props.history.push("/shop")}>Shop</h5>
              <h5 onClick={() => scrollTo("#contactUs")}>Contatti</h5>
            </div>
            <hr />
            <p>© Copyright 2020 Designed by Yuri Ceriani</p>
            {/* <button onClick={() => props.openAuthModal("registration")}>
            REGISTRATION
          </button>
          <button onClick={() => props.openAuthModal("login")}>LOGIN</button>
          <button onClick={props.logoutHandler}>LOGOUT</button>
          {props.isLogged ? (
            <p>Sei loggato con successo!</p>
          ) : (
            <p>Loggati prima di eseguire altre operazioni</p>
          )} */}
          </footer>
        )
      ) : null}
    </React.Fragment>
  );
}

function ShopMenu(props: any) {
  return (
    <React.Fragment>
      <nav></nav>
      <main className="Shop">{props.children}</main>
      <footer></footer>
    </React.Fragment>
  );
}

function Layout(props: any) {
  const [thereIsCookie, setThereIsCookie] = useState(0);
  function checkCookie() {
    setTimeout(() => {
      setThereIsCookie(
        Number(document.getElementsByClassName("cookieConsent").length > 0)
      );
    });
  }
  const isShop =
    props?.location?.pathname?.includes("/shop") ||
    props?.location?.pathname?.includes("/catalogo") ||
    props?.location?.pathname?.includes("/checkout") ||
    props?.location?.pathname?.includes("/profile") ||
    props?.location?.pathname?.includes("/registration") ||
    props?.location?.pathname?.includes("/prod") ||
    props?.location?.pathname?.includes("/logout") ||
    props?.location?.pathname?.includes("/login") ||
    props?.location?.pathname?.includes("/register") ||
    props?.location?.pathname?.includes("/order-ok") ||
    props?.location?.pathname?.includes("/order-ko") ||
    props?.location?.pathname?.includes("/new") ||
    props?.location?.pathname?.includes("/novità") ||
    props?.location?.pathname?.includes("/promo") ||
    props?.location?.pathname?.includes("/about-box");

  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="ACCETTO"
        buttonStyle={{ backgroundColor: "#f5976c", color: "white" }}
        enableDeclineButton
        declineButtonText="RIFIUTO"
        setDeclineCookie={false}
        onAccept={checkCookie}
        onDecline={checkCookie}
      >
        Questo sito utilizza cookie.
        {/* <span
          style={{ cursor: "pointer", fontFamily: "Venus Bold" }}
          onClick={props.goCookie}
        >
          {" "}
          Dettagli
        </span> */}
      </CookieConsent>
      {isShop ? <ShopMenu {...props} /> : <LandingMenu {...props} />}
    </React.Fragment>
  );
}

export default withRouter(Layout);
