import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { CouponRest } from "../../../models/rest";
import * as actions from "../../../store/actions";
import { formatForm, getInitState, inputHandler } from "../../../utils/form";

class NewCoupon extends Component<{
  create: any;
  history: any;
}> {
  state = { form: {} };

  componentDidMount() {
    this.setState(getInitState(CouponRest));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    const objToSave = formatForm(this.state.form);
    this.props.create(objToSave, this.props.history.push("/admin/coupon"));
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
        >
          <div className="text-center mb-3">
            <button className="btn btn-primary" type="submit">
              CREA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    create: (product: any, onSuccess: any) => {
      dispatch(actions.newCoupon(product, onSuccess));
    },
  };
};

export default connect(null, mapDispatchToProps)(NewCoupon);
