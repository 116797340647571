import React, {useState, useEffect} from 'react'
import Catalogo from '../Catalogo/Catalogo'
import Informazione from './subpages/Informazione'
import Pagamento from './subpages/Pagamento'
import Spedizione from './subpages/Spedizione'
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import Login from './subpages/LoginWidget';

const Body = ({index, eventSpedition, eventCosts, costs, spedition, coupon} : any) => {
    const [indexPage, setIndex] = useState("informazioni")

    useEffect(() => {
        setIndex(index)
    }, [index])

    if (indexPage == "informazioni")
        return ( <div className="body"><Informazione costs={costs} spedition={spedition} eventSpedition={eventSpedition} eventCosts={eventCosts}></Informazione></div> )
    if (indexPage == "pagamento")
        return ( <div className="body"><Pagamento costs={costs} spedition={spedition} coupon={coupon}></Pagamento></div> )

    return ( <div className="body"></div> )
}

const mapStateToProps = (state: any) => {
    return {
      isLogged: state.utils.isLogged,
    }
  }

export default Body