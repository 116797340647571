import React, { useState } from 'react'
import { withFirebase } from '../../../../../hoc/firebase/context';
import { useHistory } from 'react-router';

const Logout = (props : any) => {
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submittedFlag, setSubmittedFlag] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    async function signOut() {
        try {
          setSubmitting(true)
          await props["firebase"].doSignOut()
          toHome()
          setSubmitting(false)
        } catch (error) {
          setError(error)
        }
    }

    const history = useHistory();
    const toHome = () => {
      history.push({
        pathname: "/shop",
        state: {},
      });
    };

    return (
        <div className="page">
            <h2>Ci mancherai</h2>
            <p>Già non vediamo l'ora di rivederti</p>

            <a className="cta" onClick={() => signOut()} style={{opacity: isSubmitting ? '0.6' : '1'}}>
                <p>{isSubmitting ? "Caricamento" : "Esci"}</p>
            </a>
        </div>
    )
}

export default withFirebase(Logout);