import brand from "./store/reducers/brand";
import coupon from "./store/reducers/coupon";
import domain from "./store/reducers/domain";
import product from "./store/reducers/product";
import shop from "./store/reducers/shop";
import shopImages from "./store/reducers/shopImages";
import utils from "./store/reducers/utils";

export const firebaseConfig = {
  apiKey: "AIzaSyA1-QiAi-yGmHKKg1NiJHzOxaEUwwhYYk8",
  authDomain: "eurosaga-agents.firebaseapp.com",
  databaseURL: "https://eurosaga-agents.firebaseio.com",
  projectId: "eurosaga-agents",
  storageBucket: "eurosaga-agents.appspot.com",
  messagingSenderId: "481934805458",
  appId: "1:481934805458:web:95279fc480d842bf70e00b",
  measurementId: "G-NQFD4JWNMM",
};

export const reducers = {
  product: product,
  domain: domain,
  shopImages: shopImages,
  brand: brand,
  utils: utils,
  shop: shop,
  coupons: coupon,
};
