import React, { Component, useEffect, useState } from "react";
import * as actions from "../../../../store/actions";
import { useHistory } from "react-router";
import Container from "../Basics/Container";
import Hide from "../Basics/Hide";
import './footer.scss';
import Close from '../../../../assets/icons/close.svg';
import Cards from '../../../../assets/images/cards.png';
import FacebookIcon from '../../../../assets/icons/facebook_black.svg';
import InstagramIcon from '../../../../assets/icons/instagram_black.svg';
import ChevronUp from "../../../../assets/images/chevron-down.png";
import ChevronDown from "../../../../assets/images/chevron-up.png";
import { connect } from 'react-redux';

function Footer(props : any) {
    const history = useHistory();

    async function goToTutteLeBirre() {
        await props["resetFilters"]();
        await props["setFilter"]("brand", null, true);
        history.push({
          pathname: "/catalogo",
          state: {
          },
        });
      }
    
      async function goToNovità() {
        await props["resetFilters"]();
        await props["setFilter"]("new", true);
        history.push({
          pathname: "/catalogo",
          state: {
          },
        });
      }
    
      async function goToPromo() {
        await props["resetFilters"]();
        await props["setFilter"]("promo", true);
        history.push({
          pathname: "/catalogo",
          state: {
          },
        });
      }
    
      async function goTopRated() {
        await props["resetFilters"]();
        await props["setFilter"]("topRated", true);
        history.push({
          pathname: "/catalogo",
          state: {
          },
        });
      }

      async function goToBirrerie() {
        await props["resetFilters"]();
        await props["setFilter"]("topRated", true);
        history.push({
          pathname: "/all-brands",
          state: {
          },
        });
      }

    return (
        <>
            <Hide forMobile={false}>
                <div className="footer">
                    <Container>
                        <div className="col">
                            <h3>About Us</h3>
                            <p>Eurosaga s.r.l<br/>
                            Via Bergamo n.12<br/>
                            San Giuliano Milanese<br/>
                            </p>
                            <p>(+39) 02 98281321</p>
                            <p>lun-ven 9.00/17.00</p>
                            <p>P.iva: 10047450159</p>

                            
                        </div>
                        <div className="col">
                            <h3>Aiuto & Info</h3>
                            <a href="/##contactUs" style={{textDecoration: 'none'}}>FAQ</a>
                            <a href="/about-box" style={{textDecoration: 'none'}}>Spedizioni</a>
                            <a href="/about-box#sconti" style={{textDecoration: 'none'}}>Sconti</a>
                            <a href="https://www.iubenda.com/privacy-policy/84960474" style={{textDecoration: 'none'}}>Privacy Policy</a>
                            <a href="https://www.iubenda.com/termini-e-condizioni/84960474" style={{textDecoration: 'none'}}>Termini e Condizioni</a>
                        </div>
                        <div className="col">
                            <h3>Menù Principale</h3>
                            <a onClick={() => goToTutteLeBirre()}>Tutte le birre</a>
                            <a onClick={() => goToNovità()}>Novità</a>
                            <a onClick={() => goToBirrerie()}>Birrerie</a>
                            <a onClick={() => goToPromo()}>Promo</a>
                            <a onClick={() => goTopRated()}>Top rated</a>
                        </div>
                        <div className="col">
                            <h3>Seguici su</h3>
                            <div style={{display: "flex"}}>
                                <div><a href="https://www.facebook.com/eurosagasrl"><img src={FacebookIcon} style={{width: "32px", height: "32px", marginRight: '16px', filter: 'invert(100%)'}}/></a></div>
                                <div><a href="https://www.instagram.com/eurosaga_official/?hl=en"><img src={InstagramIcon} style={{width: "32px", height: "32px", filter: 'invert(100%)'}}/></a></div>
                            </div>
                            <p><b>Pagamenti sicuri</b></p>
                            <img className={"logoCards"} src={Cards} />
                        </div>
                    </Container>
                    <Container>
                        <div className="underFooter">
                            <p>Copyright© 2020 Designed by Yuri Ceriani</p>
                        </div>
                    </Container>
                </div>
            </Hide>
            <Hide forMobile={true}>
                <div className="footer">
                    <Container>
                        <div className="collapse">
                            <a id="hide100" href="#hide100" className="hide">
                                <p>About Us</p>
                                <img style={{filter: 'invert(100%)'}} src={ChevronUp} />
                            </a>    
                            <a id="show100" href="#show100" className="show">
                                <p>About Us</p>
                                <img style={{filter: 'invert(100%)'}} src={ChevronDown} />
                            </a>
                            <div className="details">
                                <p>Eurosaga s.r.l<br/>
                                Via Bergamo n.12<br/>
                                San Giuliano Milanese<br/>
                                </p>
                                <p>(+39) 02 98281321</p>
                                <p>lun-ven 9.00/17.00</p>
                                <p>P.iva: 10047450159</p>
                            </div>
                        </div>
                        <div className="collapse">
                            <a id="hide101" href="#hide101" className="hide">
                                <p>Aiuto & Info</p>
                                <img style={{filter: 'invert(100%)'}} src={ChevronUp} />
                            </a>    
                            <a id="show101" href="#show101" className="show">
                                <p>Aiuto & Info</p>
                                <img style={{filter: 'invert(100%)'}} src={ChevronDown} />
                            </a>
                            <div className="details">
                                <a href="/##contactUs">FAQ</a>
                                <a href="/about-box" style={{textDecoration: 'none'}}>Spedizioni</a>
                                <a href="/about-box#sconti" style={{textDecoration: 'none'}}>Sconti</a>
                                <a href="https://www.iubenda.com/privacy-policy/84960474" style={{textDecoration: 'none'}}>Privacy Policy</a>
                                <a href="https://www.iubenda.com/termini-e-condizioni/84960474" style={{textDecoration: 'none'}}>Termini e Condizioni</a>
                            </div>
                        </div>
                        <div className="collapse">
                            <a id="hide102" href="#hide102" className="hide">
                                <p>Menù Principale</p>
                                <img style={{filter: 'invert(100%)'}} src={ChevronUp} />
                            </a>    
                            <a id="show102" href="#show102" className="show">
                                <p>Menù Principale</p>
                                <img style={{filter: 'invert(100%)'}} src={ChevronDown} />
                            </a>
                            <div className="details">
                                <a onClick={() => goToTutteLeBirre()}>Tutte le birre</a>
                                <a onClick={() => goToNovità()}>Novità</a>
                                <a onClick={() => goToBirrerie()}>Birrerie</a>
                                <a onClick={() => goToPromo()}>Promo</a>
                                <a onClick={() => goTopRated()}>Top rated</a>
                            </div>
                        </div>
                        <h3>Seguici su</h3>
                        <div style={{display: "flex"}}>
                            <div><a href="https://www.facebook.com/eurosagasrl"><img src={FacebookIcon} style={{width: "32px", height: "32px", marginRight: '16px', filter: 'invert(100%)'}}/></a></div>
                            <div><a href="https://www.instagram.com/eurosaga_official/?hl=en"><img src={InstagramIcon} style={{width: "32px", height: "32px", filter: 'invert(100%)'}}/></a></div>
                        </div>
                        <p><b>Pagamenti sicuri</b></p>
                        <img className={"logoCards"} src={Cards} />
                    </Container>
                    <Container>
                        <div className="underFooter">
                            <p>Copyright© 2020 Designed by Yuri Ceriani</p>
                        </div>
                    </Container>
                </div>
            </Hide>
        </>
    ) 
}

const mapStateToProps = (state: any) => {
    return {
      brands: state.brand.brands,
      styles: state.domain.style,
      isLogged: state.utils.isLogged,
      cart: state.shop.chart,
      prods: state.domain.products,
    }
}

  const mapDispatchToProps = (dispatch: any) => {
    return {
      setFilter: (key: string, value: string) => {
        dispatch(actions.setFilter(key, value, true));
      },
      resetFilters: () => {
        dispatch(actions.resetFilters());
      },
      setSortFilter: (sortKey : any, order : any) => {
        dispatch(actions.setSort(sortKey, order))
      }
    };
};

export default connect(mapStateToProps, null)(Footer);;
