import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { withFirebase } from "../../../../hoc/firebase/context";
import * as actions from "../../../../store/actions";
import { InputText } from "../../widgets/Input/Text";
import { User } from "./../../../../models/user";
import "./registration.scss";

const Body = (props: any) => {
  const [type, setType] = useState(0);
  const [error, setError] = useState(null);
  const [flagNewsletter, setflagNewsletter] = useState(false);
  const [flagStayOnline, setFlagStayOnline] = useState(false);
  const [flagPrivacy, setFlagPrivacy] = useState(false);
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  var user: User = {};
  var confirmPassword: string = "";
  var privacyTerms: boolean = false;

  useEffect(() => {
    user.isPrivate = true;
    user.newsletter = false;
  }, []);

  async function send() {
    try {
      setSubmittedFlag(false);
      setSubmitting(true);
      console.log(user.name);
      if (user.isPrivate) {
        if (
          user.name != "" &&
          user.name != undefined &&
          user.surname != "" &&
          user.surname != undefined &&
          user.email != "" &&
          user.email != undefined &&
          user.password != "" &&
          user.password != undefined &&
          privacyTerms
        ) {
          if (confirmPassword == user.password && confirmPassword.length >= 6) {
            await props["createUserAccount"](user);
            props["firebase"].doSignInWithEmailAndPassword(
              user.email,
              user.password
            );
            toHome();
          } else {
            setSubmitting(false);
            setSubmittedFlag(true);
          }
        } else {
          setSubmitting(false);
          setSubmittedFlag(true);
        }
      } else {
        setSubmitting(false);
        setSubmittedFlag(true);
      }
    } catch (error) {
      setError(error);
    }
  }

  const history = useHistory();
  const toHome = () => {
    history.push({
      pathname: "/shop",
      state: {},
    });
  };

  const handleNome = (e: any) => {
    user.name = e.target.value;
    user.billingName = e.target.value;
  };

  const handleCognome = (e: any) => {
    user.surname = e.target.value;
    user.billingSurname = e.target.value;
  };

  const handleSocieta = (e: any) => {
    user.company = e.target.value;
  };

  const handleTipologia = (e: any) => {
    user.type = e.target.value;
  };

  const handleCodiceFiscale = (e: any) => {
    user.fiscalCode = e.target.value;
  };

  const handlePartitaIVA = (e: any) => {
    user.iva = e.target.value;
  };

  const handleIndirizzo = (e: any) => {
    user.address = e.target.value;
    user.billingStreet = e.target.value;
  };

  const handleNumero = (e: any) => {
    user.addressNumber = e.target.value;
    user.billingStreetNumber = e.target.value;
  };

  const handleCitta = (e: any) => {
    user.city = e.target.value;
    user.billingCity = e.target.value;
  };

  const handleProvincia = (e: any) => {
    user.province = e.target.value;
    user.billingProvince = e.target.value;
  };

  const handleEmail = (e: any) => {
    user.email = e.target.value;
  };

  const handlePassword = (e: any) => {
    user.password = e.target.value;
  };

  const handleConfermaPassword = (e: any) => {
    confirmPassword = e.target.value;
  };

  const handleNewsLetter = () => {
    user.newsletter = !user.newsletter;
  };

  const handlePrivacyTerms = () => {
    privacyTerms = !privacyTerms;
  };

  function setTypeFlag(x: number) {
    if (x == 0) user.isPrivate = true;
    else user.isPrivate = false;
    setType(x);
    setSubmittedFlag(false);
  }

  return (
    <div className="shop registrationPage">
      <div className="box">
        <div className="toggle" style={{ display: "none" }}>
          <div
            className={type == 0 ? "background left" : "background right"}
          ></div>
          <div
            onClick={() => setTypeFlag(0)}
            className={type == 0 ? "tab active" : "tab"}
          >
            <h3>UTENTE PRIVATO</h3>
          </div>
          <div
            onClick={() => setTypeFlag(1)}
            className={type == 1 ? "tab active" : "tab"}
          >
            <h3>PROFESSIONISTI</h3>
          </div>
        </div>
        <div className="box-body">
          <div style={{ display: type == 0 ? "block" : "none" }}>
            <h2>Registrati</h2>
            <label>
              Nome e Cognome<b style={{ color: "#EB2D38" }}>*</b>
            </label>
            <InputText
              placeholder={"Nome"}
              callback={handleNome}
              missed={
                submittedFlag && (user.name == undefined || user.name == "")
              }
              missedText="Questo campo è obbligatorio"
              secureText={false}
              onKeyDown
            ></InputText>
            <InputText
              placeholder={"Cognome"}
              callback={handleCognome}
              missed={
                submittedFlag &&
                (user.surname == undefined || user.surname == "")
              }
              missedText="Questo campo è obbligatorio"
              secureText={false}
              onKeyDown
            ></InputText>
            <label>
              Informazioni account<b style={{ color: "#EB2D38" }}>*</b>
            </label>
            <InputText
              placeholder={"E-mail"}
              callback={handleEmail}
              missed={
                submittedFlag && (user.email == undefined || user.email == "")
              }
              missedText="Questo campo è obbligatorio"
              secureText={false}
              onKeyDown
            ></InputText>
            <InputText
              placeholder={"Password"}
              callback={handlePassword}
              missed={
                submittedFlag &&
                (user.password == undefined || user.password == "")
              }
              missedText="Questo campo è obbligatorio"
              secureText={true}
              onKeyDown
            ></InputText>
            <InputText
              placeholder={"Conferma Password"}
              callback={handleConfermaPassword}
              missed={submittedFlag && confirmPassword == ""}
              missedText="Questo campo è obbligatorio"
              secureText={true}
              onKeyDown
            ></InputText>
            <div className="checks">
              <div>
                <input
                  type="checkbox"
                  name="newsletter"
                  value=""
                  onChange={handleNewsLetter}
                />
                <label> Iscriviti alla news letter</label>
              </div>
              <div style={{ display: "none" }}>
                <input type="checkbox" name="remainLoggedIn" value="" />
                <label> Rimani collegato</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="privacyTerms"
                  value=""
                  onChange={handlePrivacyTerms}
                />
                <label>
                  {" "}
                  Effettuando l’accesso accetti e dichiari di avere letto le
                  condizioni di vendita di Eurosaga e di aver preso visione dela
                  politica sulla privacy e l’informativa sui cookie.
                </label>
              </div>
            </div>
            <p style={{ color: "red" }}>
              {submittedFlag && !privacyTerms
                ? "Attenzione, per continuare è richiesto di accettare le condizioni"
                : ""}
            </p>
            <a
              className="cta"
              onClick={() => (isSubmitting ? () => {} : send())}
              style={{ opacity: isSubmitting ? "0.6" : "1" }}
            >
              <p>{isSubmitting ? "Caricamento" : "Crea un account"}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createUserAccount: (user: User) => {
      dispatch(actions.createUserAccount(user));
    },
  };
};

export default withFirebase(connect(null, mapDispatchToProps)(Body));
