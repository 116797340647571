import React, { Component } from "react";
import "./basics.scss";

class Container extends Component {
  render() {
    return <div className="shop shop-container">{this.props.children}</div>;
  }
}

export default Container;
