import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "../../widgets/Basics/Container";

const PreLogin = ({ brands } : any) => {
    return <>
    </>
}

const mapStateToProps = (state: any) => {
    return {
      products: state.product.searchedProducts,
      brands: state.brand.brands,
      brandsForSelect: state.domain.brand,
      nations: state.domain.nation,
      styles: state.domain.style,
      sizes: state.domain.size,
    };
};
  
  export default connect(mapStateToProps)(PreLogin);
