import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "../../widgets/Basics/Container";
import Logo from '../../../../assets/images/logo.svg';
import Check from '../../../../assets/images/check-green.png'
import './confirmRegistration.scss'
import NavBar from "../../widgets/NavBar/NavBar";

const ConfirmRegistration = () => {
    return <div className="shop confirmRegistration">
        <img className="logo" src={Logo} alt="Logo" />
        <div className="box">
            <div className="icon"><img src={Check} alt="Logo" /></div>
            <h3>Ti diamo il benvenuto</h3>
            <p>Grazie per aver effettuato l’iscrizione, entro 24h lavorative elaboreremo i tuoi dati e il tuo account verrà attivato.</p>
        </div>
    </div>
}
  
export default ConfirmRegistration;
