import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import common_it from "./translations/it/pages.json";
import common_en from "./translations/en/pages.json";

const resources = {
  en: {
    ...common_en,
  },
  it: {
    ...common_it,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: "it",
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
