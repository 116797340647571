import React from "react"

const ItemCart = ({image, title, brand, format, pz, price, quantity} : any) => {
    return (
        <div className="itemCart">
            <div className="flex" style={{display: "flex"}}>
                <div className="info">
                    <div className="wrapperImageProd">
                        <img className="imageProd" src={image} />
                        <label className="notification">{quantity}</label>
                    </div>
                    <div>
                        <h3>{title}</h3>
                        <h2>{brand}</h2>
                    </div>
                </div>
                <p className="price">€ {price.toFixed(2)}</p>
            </div>
        </div>
    )
}

export default ItemCart;