import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import { useHistory } from "react-router";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import 'pure-react-carousel/dist/react-carousel.es.css';
import Hide from "../../widgets/Basics/Hide";
import Container from "../../widgets/Basics/Container";
import User from '../../../../assets/icons/user.svg';
import BrandLogo from '../../../../assets/images/partners/brewdog.png'

const carouselResponsiveBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

const WidgetBrands = ({brands, resetFilters, setFilter} : any) => {
  const history = useHistory();

  async function go(brand : any) {
    resetFilters()
    setFilter('brand', ""+brand, true)
    history.push({
      pathname: "/catalogo",
      state: {}
    });
  }


  return (
    <div>
      <Hide forMobile={true}>
        <Container>
          <div className="bestOf forDesktop" style={{boxSizing: 'border-box', paddingRight: '16px'}}>
            <h2 className="forDesktop" style={{marginTop: '72px', marginBottom: '54px'}}>Trending Brands</h2>
            <Carousel
            responsive={carouselResponsiveBreakpoints}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={true}
          transitionDuration={800}
          arrows={false}
        >
          {brands != null && brands.map((item : any, i : any) => {
                    return (
                      <div key={"logoTrendingBrands"+i} onClick={() => go(""+item.id)} className="bestPost forDesktop" style={{boxShadow: 'none', padding: '16px', marginTop: '8px'}}>
                          <div className={i % 2 == 0 ? "image" : "image"} style={{backgroundColor: 'white', backgroundImage: "url(" + item.image + ")", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', borderRadius: '16px', boxShadow: 'none', paddingTop: '75px'}}></div>
                        </div>
                        
                    )
                  })}
        </Carousel>
            
          </div>
        </Container>
      </Hide>
      <Hide forMobile={false}>
        <Container>
          <div className="bestOf forDesktop">
            <h2 className="forDesktop" style={{marginTop: '72px', marginBottom: '54px'}}>Trending Brands</h2>
            <Carousel
            responsive={carouselResponsiveBreakpoints}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={true}
          transitionDuration={800}
          arrows={false}
        >
          {brands != null && brands.map((item : any, i : any) => {
                    return (
                      <div key={"logoTrendingBrands"+i} onClick={() => go(""+item.id)} className="bestPost forDesktop" style={{boxShadow: 'none', padding: '16px', marginTop: '8px', cursor: "pointer"}}>
                          <div className={i % 2 == 0 ? "image" : "image"} style={{backgroundColor: 'white', backgroundImage: "url(" + item.image + ")", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', borderRadius: '16px', boxShadow: 'none', paddingTop: '75px'}}></div>
                        </div>
                        
                    )
                  })}
        </Carousel>
            
          </div>
        </Container>
      </Hide>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    }
  };
};
export default connect(null, mapDispatchToProps)(WidgetBrands);
