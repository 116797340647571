import React, { useState } from "react";
import "./input.scss";

const InputSelectForCheckout = ({
  placeHolder,
  children,
  callback,
  defaultValue,
}) => {
  var flag = false;
  var value = "";

  function handleUpdate(e) {
    value = e.target.value;
    callback(e);
  }

  return (
    <div className="input-select-wrapper forCheckout">
      <select
        className="input-select forCheckout"
        type="select"
        onChange={callback}
        value={value != "" ? value : defaultValue}
      >
        {children}
      </select>
    </div>
  );
};

export default InputSelectForCheckout;
