export type InputType =
  | "none"
  | "text"
  | "email"
  | "number"
  | "note"
  | "radio" // usare solo per i booleani (senno il format form scazza)
  | "checkbox"
  | "select"
  | "file"
  | "password"
  | "array";

export type DbType =
  | "id"
  | "string"
  | "number"
  | "reference"
  | "image"
  | "referenceArray"
  | "boolean"
  | "array"
  | "stringArray";

export type SearchType =
  | "string"
  | "number"
  | "boolean"
  | "numberRange"
  | "stringArray";

export interface RestModel {
  skipLabel?: boolean;
  fields: {
    [key: string]: {
      dbType: DbType;
      inputType: InputType;
      searchType?: SearchType;
      placeholder?: string;
      label?: string;
      inputChoices?: { label: string; value: string }[];
      hide?: boolean;
      childs?: {
        [key: string]: {
          dbType: DbType;
          inputType: InputType;
          searchType?: SearchType;
          placeholder?: string;
          label?: string;
          inputChoices?: { label: string; value: string }[];
        };
      };
    };
  };
}

export interface FormModel {
  [key: string]: {
    type: InputType;
    value: string;
    label: string;
    placeholder: string;
    choices: { label: string; value: string }[];
    childs?: { [key: string]: any };
  };
}

export const ProductRest: RestModel = {
  fields: {
    id: { dbType: "id", inputType: "none" },
    name: { dbType: "string", inputType: "text", searchType: "string" },
    brand: { dbType: "reference", inputType: "select", searchType: "string" },
    category: {
      dbType: "reference",
      inputType: "select",
      searchType: "string",
    },
    image: { dbType: "image", inputType: "file" },
    alcol: {
      dbType: "number",
      inputType: "number",
      label: "Alcol (%)",
      searchType: "number",
    },
    style: { dbType: "reference", inputType: "select", searchType: "string" },
    desc: { dbType: "string", inputType: "note", searchType: "string" },
    ingredients: {
      dbType: "string",
      inputType: "text",
      searchType: "string",
      label: "ingredienti",
    },
    store: {
      dbType: "reference",
      inputType: "select",
      label: "Stato delle scorte",
      searchType: "string",
    },
    artisanal: { dbType: "boolean", inputType: "radio", searchType: "boolean" },
    new: {
      dbType: "boolean",
      inputType: "radio",
      label: "Nuovo prodotto",
      searchType: "boolean",
    },
    topRated: {
      dbType: "boolean",
      inputType: "radio",
      label: "Top rated",
      searchType: "boolean",
    },
    promo: {
      dbType: "boolean",
      inputType: "radio",
      label: "Promo",
      searchType: "boolean",
    },
    expiry: {
      dbType: "number",
      inputType: "number",
      label: "Mesi alla scadenza",
    },
    filterAs: {
      dbType: "stringArray",
      inputType: "checkbox",
      label: "Filtra come",
      searchType: "stringArray",
    },
    // filterIsIpa: {
    //   dbType: "boolean",
    //   inputType: "radio",
    //   label: "Birra IPA?",
    //   searchType: "boolean",
    // },
    // filterIsTrappist: {
    //   dbType: "boolean",
    //   inputType: "radio",
    //   label: "Birra TRAPPISTA?",
    //   searchType: "boolean",
    // },
    // filterIsStout: {
    //   dbType: "boolean",
    //   inputType: "radio",
    //   label: "Birra STOUT?",
    //   searchType: "boolean",
    // },
    // filterIsLambic: {
    //   dbType: "boolean",
    //   inputType: "radio",
    //   label: "Birra LAMBIC?",
    //   searchType: "boolean",
    // },
    // filterIsAle: {
    //   dbType: "boolean",
    //   inputType: "radio",
    //   label: "Birra ALE?",
    //   searchType: "boolean",
    // },
    types: {
      dbType: "array",
      inputType: "array",
      label: "Tipologia di contenitori e prezzi",
      childs: {
        price: {
          dbType: "number",
          inputType: "number",
          label: "Prezzo",
          searchType: "numberRange",
        },
        oldPrice: {
          dbType: "number",
          inputType: "number",
          label: "Vecchio Prezzo",
        },
        discountPercentage: {
          dbType: "number",
          inputType: "number",
          label: "Percentuale di sconto",
          searchType: "number",
        },
        container: {
          dbType: "reference",
          inputType: "select",
          label: "Contenitore",
          searchType: "string",
        },
        size: {
          dbType: "reference",
          inputType: "select",
          label: "Dimensione",
          searchType: "number",
        },
        pack: {
          dbType: "reference",
          inputType: "select",
          label: "Package",
          searchType: "number",
        },
        availablePieces: {
          dbType: "number",
          inputType: "number",
          label: "Pezzi ancora disponibili",
        },
        code: { dbType: "string", inputType: "text", label: "Codice catalogo" },
        onlyPiva: {
          dbType: "boolean",
          inputType: "radio",
          label: "Solo per partita iva",
        },
      },
    },
  },
};

export const BrandRest: RestModel = {
  fields: {
    id: { dbType: "id", inputType: "none" },
    name: {
      dbType: "string",
      inputType: "text",
      label: "Nome",
    },
    nation: { dbType: "reference", inputType: "select", label: "Nazione" },
    image: { dbType: "image", inputType: "file" },
    priority: { dbType: "number", inputType: "number" },
    hide: { dbType: "boolean", inputType: "radio" },
    onlyPiva: { dbType: "boolean", inputType: "radio" },
  },
};

export const ShopImagesName = [
  "beer1",
  "beer2",
  "beer3",
  "beer4",
  "beer5",
  "image1",
  "image2",
  "image3",
  "image4",
  "image5",
  "image6",
  "image7",
  "image8",
  "image9",
  "image10",
  "image11",
  "image12",
  "image13",
  "image14",
  "image15",
];

export const ShopImagesRest: RestModel = {
  fields: {
    id: { dbType: "id", inputType: "none" },
    image: { dbType: "image", inputType: "file" },
    title: { dbType: "string", inputType: "text" },
    desc: { dbType: "string", inputType: "note" },
    // style: {
    //   dbType: "string",
    //   inputType: "text",
    //   label: "Campo su cui filtrare (ES. filterIsAle)",
    // },
  },
};

export const MailRest: RestModel = {
  skipLabel: true,
  fields: {
    name: {
      dbType: "string",
      inputType: "text",
      placeholder: "Nome",
      label: "",
    },
    email: { dbType: "string", inputType: "text", placeholder: "E-mail" },
    object: { dbType: "string", inputType: "text", placeholder: "Oggetto" },
    message: { dbType: "string", inputType: "note", placeholder: "Messaggio " },
  },
};

export const UserAllRest: RestModel = {
  fields: {
    name: {
      dbType: "string",
      inputType: "text",
    },
    email: {
      dbType: "string",
      inputType: "text",
    },
    password: {
      dbType: "string",
      inputType: "text",
    },
    isPrivate: {
      dbType: "boolean",
      inputType: "radio",
    },
    surname: {
      dbType: "string",
      inputType: "text",
    },
    company: {
      dbType: "string",
      inputType: "text",
    },
    type: {
      dbType: "string",
      inputType: "text",
    },
    fiscalCode: {
      dbType: "string",
      inputType: "text",
    },
    iva: {
      dbType: "string",
      inputType: "text",
    },
    address: {
      dbType: "string",
      inputType: "text",
    },
    city: {
      dbType: "string",
      inputType: "text",
    },
    province: {
      dbType: "string",
      inputType: "text",
    },
    newsletter: {
      dbType: "boolean",
      inputType: "radio",
    },
    verified: {
      dbType: "boolean",
      inputType: "radio",
    },
  },
};

export const UserVerify: RestModel = {
  fields: {
    id: {
      dbType: "id",
      inputType: "none",
    },
    verified: {
      dbType: "boolean",
      inputType: "radio",
      label: "Partita iva verificata",
    },
  },
};

export const CouponRest: RestModel = {
  fields: {
    id: { dbType: "id", inputType: "none" },
    code: {
      dbType: "string",
      inputType: "text",
      label: "Codice coupon",
    },
    percentage: {
      dbType: "number",
      inputType: "number",
      label: "Percentuale di sconto",
    },
  },
};

export type Domains =
  | "category"
  | "size"
  | "style"
  | "pack"
  | "store"
  | "nation"
  | "container";

export const DomainRest: {
  [key in Domains]: {
    [key: string]: { type: string };
  };
} = {
  category: {
    id: { type: "id" },
    value: { type: "string" },
  },
  size: {
    id: { type: "id" },
    value: { type: "string" },
  },
  style: {
    id: { type: "id" },
    value: { type: "string" },
  },
  pack: {
    id: { type: "id" },
    value: { type: "string" },
  },
  store: {
    id: { type: "id" },
    value: { type: "string" },
  },
  nation: {
    id: { type: "id" },
    value: { type: "string" },
  },
  container: {
    id: { type: "id" },
    value: { type: "string" },
  },
};
