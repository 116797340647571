export const cssVariables = {
  white: "#f2f2f2",
  black: "#3f3f3f",
  red: "#eb4b4b",
  grey3: "#828282",
};

export const carouselResponsiveBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const mobileScreenSizeLimit = "768";

export const breakpoints = {
  xs: 480,
  sm: 767,
  md: 1023,
  lg: 1279,
  xl: 1919,
};
