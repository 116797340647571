import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { CouponRest } from "../../../models/rest";
import * as action from "../../../store/actions";
import "./Coupon.scss";

class Coupon extends Component<{
  history: any;
  delete: any;
  loadAll: any;
  coupons: any[];
  domain: any[];
}> {
  componentDidMount() {
    this.props.loadAll();
  }
  iconHandler = (page: "modify" | "delete" | "new", id?: any) => {
    if (page === "modify" || page === "new") {
      this.props.history.push({
        pathname: "/admin/coupon/" + page,
        state: { id: id },
      });
    } else {
      this.props.delete(id, this.props.loadAll());
    }
  };

  render() {
    return (
      <div className="Coupon">
        <button className="newButton" onClick={() => this.iconHandler("new")}>
          NUOVO
        </button>
        <table>
          <thead>
            <tr>
              {Object.keys(CouponRest.fields).map((fieldName) => {
                return (
                  <th key={fieldName}>
                    {CouponRest.fields[fieldName].label || fieldName}
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.coupons.map((product: any, ind: number) => {
              return (
                <tr key={ind}>
                  {Object.keys(CouponRest.fields).map((fieldKey: any) => {
                    switch (CouponRest.fields[fieldKey].dbType) {
                      case "boolean":
                        return (
                          <td key={fieldKey + product[fieldKey]}>
                            {product[fieldKey] ? "Si" : "No"}
                          </td>
                        );
                      default:
                        return (
                          <td key={fieldKey + product[fieldKey]}>
                            {product[fieldKey]}
                          </td>
                        );
                    }
                  })}
                  <td>
                    <i
                      onClick={() => this.iconHandler("modify", product.id)}
                      className="fa fa-edit"
                    ></i>
                    <i
                      onClick={() => this.iconHandler("delete", product.id)}
                      className="fa fa-trash"
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    coupons: state.coupons.coupons,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadAll: () => dispatch(action.getAllCoupons()),
    delete: (id: any, onSuccess: any) =>
      dispatch(action.deleteCoupon(id, onSuccess)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Coupon as any
);
