import React from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import "./boxCatalogo.scss";
import NavBar from "../../widgets/NavBar/NavBar";
import Container from "../../widgets/Basics/Container";
import Footer from "../../widgets/Footer/Footer";
import BoxChill from "../../../../assets/images/boxes/BoxChill.jpg";
import BoxShare from "../../../../assets/images/boxes/BoxShare.jpg";
import BoxParty from "../../../../assets/images/boxes/BoxParty.jpg";
import BoxExtreme from "../../../../assets/images/boxes/BoxExtreme.jpg";

function BoxPage() {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="shop">
      <NavBar></NavBar>
      <div className={"boxPage"}>
        <Container>
          <div className={"body"}>
            <h1 className={"title"}>Crea il tuo box</h1>
            <p>
              Componi il tuo box come più ti piace, ricordati che il{" "}
              <b>minimo di ordine è fissato a 6 pezzi</b>, per ordinare più
              articoli ti basterà comporre un box più grande ricordandoti che
              devi <b>completare box da 6 o suoi multipli.</b>
            </p>
            <div className="flex">
              <div className="box">
                <img className={"image"} src={BoxChill} />
                <h2>CHILL BOX</h2>
                <h3>Box 6 pezzi</h3>
                <p>
                  Componi il tuo box come più ti piace, seleziona 6 referenze a
                  tua scelta, sia lattine che bottiglie, unico limite? Riempire
                  il box con tutti i pezzi indicati.
                </p>
              </div>
              <div className="box">
                <img className={"image"} src={BoxShare} />
                <h2>SHARE BOX</h2>
                <h3>Box 12 pezzi</h3>
                <p>
                  Componi il tuo box come più ti piace, seleziona 12 referenze a
                  tua scelta, sia lattine che bottiglie, unico limite? Riempire
                  il box con tutti i pezzi indicati.
                </p>
              </div>
              <div className="box">
                <img className={"image"} src={BoxParty} />
                <h2>PARTY BOX</h2>
                <h3>Box 18 pezzi</h3>
                <p>
                  Componi il tuo box come più ti piace, seleziona 18 referenze a
                  tua scelta, sia lattine che bottiglie, unico limite? Riempire
                  il box con tutti i pezzi indicati.
                </p>
              </div>
              <div className="box">
                <img className={"image"} src={BoxExtreme} />
                <h2>EXTREME BOX</h2>
                <h3>Box 24 o più pezzi</h3>
                <p>
                  Componi il tuo box come più ti piace, seleziona 24 referenze o
                  più a tua scelta, sia lattine che bottiglie, unico limite?
                  Riempire il box con tutti i pezzi indicati.
                </p>
              </div>
            </div>
            <h2 className={"title"}>Spedizioni</h2>
            <p id={"sconti"}>
              <b>Le spedizioni sono gratuita in tutta Italia</b> (Calabria,
              Isole, isole minori e località disagiate escluse,{" "}
              <a
                style={{ color: "black" }}
                href="https://drive.google.com/file/d/1rwBqwIdE0XIdotcK-d7Nm8vOxlUAUOCA/view?usp=sharing"
              >
                vedi tabella
              </a>
              ) <b>a partire dall’acquisto di un box da 6 pezzi</b> (minimo di
              ordine). <br />• <b>€5,90</b> Calabria, Isole
              <br />• <b>€18,50</b> Località disagiate, Isole minori, Venezia,
              Livigno, Campione d’Italia <br />
              <br />- <b>Tempi di consegna</b> 24/48h lavorative
              dall’elaborazione dell’ordine (gli ordini ricevuti{" "}
              <b>entro e non oltre le ore 12:00</b> verranno presi in consegna
              dal nostro corriere il giorno stesso){" "}
              <b>NB: i tempi potrebbero variare vista l’emergenza COVID19</b>{" "}
              <br />- <b>Preavviso consegna</b> il cliente riceverà un reminder
              tramite mail/sms dal ns. corriere di riferimento con le
              informazioni e la data di cosegna dell’ordine.
              <br />- <b>Giacenza</b> il corriere tenterà la consegna 2 volte
              dopodichè la merce finirà in giacenza per 10gg, gli eventuali
              costi di giacenza (€6,00) verranno addebitati al cliente. Se dopo
              i 10gg di giacenza il cliente non dovesse aver ritirato il proprio
              ordine, la merce tornerà nel nostro magazzino e l’ordine{" "}
              <b>non verrà rimborsato</b>.<br />
            </p>
            <h2 className={"title minus"} style={{ paddingTop: "24px" }}>
              Sconti
            </h2>
            <p>
              Abbiamo previsto <b>2 tipologie di sconti</b>, sui box e sui
              singoli prodotti.
              <br />
              <br />
              <b>FILL THE BOX!</b>
              <br />
              La prima tipologia di sconti è fissa si applica all’acquisto dei
              box. Più alto è il numero dei pezzi che acquisti e più alto sarà
              lo sconto, la scelta sta a te. Gli sconti vengono applicati
              automaticamente prima del pagamento al raggiungimento della soglia
              di prodotti aggiunti nel carrello, difficile? Trovi un piccolo
              recap qui in basso.
              <br />
              <br /> - SHARE BOX (12 pezzi): sconto <b>€5,00</b> <br />- PARTY
              BOX (18 pezzi): sconto <b>€10,00</b>
              <br />- EXTREME BOX (24 pezzi o più): <b>€15,00</b> (24 pezzi)
              <b> €20,00</b> (30 pezzi in su)
              <br />
              <br />
              <b>FLASH SALE</b>
              <br />
              La seconda tipologia di sconti è variabile e viene applicata
              direttamente ai singoli prodotti durante l’anno a discrezione di
              Eurosaga. Tieni d’occhio la sezione promo e iscriviti alla
              newsletter per rimanere aggiornato.
            </p>
            <h2 className={"title minus"} style={{ paddingTop: "24px" }}>
              FAQ
            </h2>
            <p>
              Per mandarci un saluto, per ricevere informazioni o altro non
              esitare a scrivere una mail tramite il nostro{" "}
              <a style={{ color: "black" }} href="/##contactUs">
                <b>form dedicato</b>
              </a>
              , saremo lieti di risponderti il prima possibile.
            </p>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default BoxPage;
