import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Route, Switch } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../hoc/firebase/context";
import "./Admin.scss";
import Brands from "./Brands";
import ModifyBrands from "./Brands/ModifyBrands";
import NewBrands from "./Brands/NewBrands";
import Coupon from "./Coupon/Coupon";
import ModifyCoupon from "./Coupon/ModifyCoupon";
import NewCoupon from "./Coupon/NewCoupon";
import Dev from "./Dev/Dev";
import ModifyShopImages from "./Images/ModifyShopImages";
import ShopImages from "./Images/ShopImages";
import ModifyProducts from "./Products/ModifyProduct";
import NewProducts from "./Products/NewProduct";
import Products from "./Products/Products";
import Users from "./Users";
import ModifyUsers from "./Users/ModifyUsers";

class Admin extends Component<any> {
  state = {
    menuVoices: [
      { name: "prodotti", link: "/admin/products" },
      { name: "brands", link: "/admin/brands" },
      { name: "immagini catalogo", link: "/admin/images" },
      { name: "utenti", link: "/admin/users" },
      // { name: "coupon", link: "/admin/coupon" },
      { name: "DEV ONLY", link: "/admin/dev" },
    ],
  };
  componentDidMount() {}
  render() {
    return (
      <div className="Admin">
        <nav className={"AdminMenu"}>
          <ul className="">
            {this.state.menuVoices.map((el) => {
              let button = null;
              button = (
                <li key={el.link} className={""}>
                  <NavLink className="nav-link" exact to={el.link}>
                    {el.name}
                  </NavLink>
                </li>
              );
              return button;
            })}
          </ul>
        </nav>
        <div className="AdminPageContent">
          <Switch>
            <Route exact path="/admin/dev" component={Dev} />
            <Route exact path="/admin/users/modify" component={ModifyUsers} />
            <Route exact path="/admin/users" component={Users} />
            <Route exact path="/admin/brands/modify" component={ModifyBrands} />
            <Route exact path="/admin/brands/new" component={NewBrands} />
            <Route exact path="/admin/brands" component={Brands} />
            <Route exact path="/admin/coupon/modify" component={ModifyCoupon} />
            <Route exact path="/admin/coupon/new" component={NewCoupon} />
            <Route exact path="/admin/coupon" component={Coupon} />
            <Route
              exact
              path="/admin/products/modify"
              component={ModifyProducts}
            />
            <Route exact path="/admin/products/new" component={NewProducts} />
            <Route
              exact
              path="/admin/images/modify"
              component={ModifyShopImages}
            />
            <Route exact path="/admin/images" component={ShopImages} />
            <Route
              exact
              path={["/admin/products", "/admin/"]}
              component={Products}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Admin);
