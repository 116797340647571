import React, { Component } from "react";
import "./basics.scss";

const Hide = ({ forMobile, children }) => (
  <div
    className={
      forMobile ? "shop shop-hide target-m" : "shop shop-hide target-d"
    }
  >
    {children}
  </div>
);

export default Hide;
