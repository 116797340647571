import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../../hoc/firebase/context";
import { ShopImagesRest } from "../../../models/rest";
import * as action from "../../../store/actions";
import "./Images.scss";

class ShopImages extends Component<any> {
  iconHandler = (page: "modify", id?: any) => {
    if (page === "modify") {
      this.props.history.push({
        pathname: "/admin/images/" + page,
        state: { id: id },
      });
    }
  };

  render() {
    return (
      <div className="ShopImagesAdmin">
        <table>
          <thead>
            <tr>
              {Object.keys(ShopImagesRest.fields).map((fieldName) => {
                return <th key={fieldName}>{fieldName}</th>;
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.shopImages.map((row: any, ind: number) => {
              return (
                <tr key={ind}>
                  {Object.keys(ShopImagesRest.fields).map((fieldKey: any) => {
                    switch (ShopImagesRest.fields[fieldKey].inputType) {
                      case "file":
                        return (
                          <td key={fieldKey}>
                            <div
                              className="image"
                              style={{
                                backgroundImage: `url('${row[fieldKey]}')`,
                              }}
                            ></div>
                          </td>
                        );
                      case "select":
                        return (
                          <td key={fieldKey + row[fieldKey]}>
                            {this.props.domain[fieldKey]
                              ? this.props.domain[fieldKey].find(
                                  (el: any) => el.value === row[fieldKey]
                                )?.label
                              : null}
                          </td>
                        );
                      case "text":
                      case "note":
                      case "none":
                        return (
                          <td key={fieldKey + row[fieldKey]}>
                            {row[fieldKey]}
                          </td>
                        );
                    }
                  })}
                  <td className="buttons-cell">
                    <button onClick={() => this.iconHandler("modify", row.id)}>
                      MODIFICA
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    shopImages: state.shopImages.shopImages,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyShopImage: (image: any) =>
      dispatch(action.modifyShopImage(image, () => {})),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(ShopImages);
