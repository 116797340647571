import React, { Component } from "react";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { inputHandler, formatForm, getInitState } from "../../../utils/form";
import { BrandRest } from "../../../models/rest";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

class ModifyBrand extends Component<any> {
  state = { form: {} };

  componentDidMount() {
    const brandId = this.props.location.state.id;
    const brand = this.props.brands.find((el: any) => el.id === brandId);
    this.setState(getInitState(BrandRest, brand));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    // creo il viaggio
    const objToSave = formatForm({
      // id: {  value: this.state.travelId },
      ...this.state.form,
    });
    this.props.modifyBrand(objToSave, this.props.history.push("/admin/brands"));
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
          domains={this.props.domain}
        >
          <div className="text-center mb-3">
            <button className="btn btn-primary" type="submit">
              MODIFICA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyBrand: (brand: any, onSuccess: any) => {
      dispatch(actions.modifyBrand(brand, onSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyBrand);
