import React, {useState} from 'react'
import Footer from '../../widgets/Footer/Footer'
import NavBar from '../../widgets/NavBar/NavBar'
import Body from './Body'

const Registration = () => {
    return (
        <div className="shop">
            <NavBar></NavBar>
            <Body></Body>
            <Footer></Footer>
        </div>
    )
}

export default Registration