import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import Hide from "../../widgets/Basics/Hide";
import Container from "../../widgets/Basics/Container";
import User from '../../../../assets/icons/user.svg';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
import { useHistory } from 'react-router';

const carouselResponsiveBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1,
  },
};

const BestOf = ({images, setFilter} : any) => {
  const history = useHistory();

  function handleClick(key: any) {
    console.log(key);
    setFilter('filterAs', key);
    goToCatalogo();
  }

  async function goToCatalogo() {
    history.push({
      pathname: "/catalogo",
      state: {}
    });
  }

  return (
    <div>
      <Hide forMobile={true}>
        <div className="bestOf">
          <h2>Scegli il tuo stile</h2>
          <div className="itemsWrapper">
            {images != undefined && images.map((item : any, i : any) => {
              if (i < 5)
              return (
                <div key={"bestOfForMobile"+item.title} className="bestPost" onClick={() => handleClick(item.id)}>
                  <div className={i % 2 == 0 ? "image left" : "image right"} style={{backgroundImage: "url(" + item.image + ")"}}></div>
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                </div>
              )
            })}
          </div>
        </div>
      </Hide>
      <Hide forMobile={false}>
        <Container>
          <div className="bestOf forDesktop">
            <h2 className="forDesktop">Scegli il tuo stile</h2>
            <Carousel
            responsive={carouselResponsiveBreakpoints}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={true}
          transitionDuration={800}
          arrows={false}
        >
          {images != undefined && images.map((item : any, i : any) => {
                    if (i < 5)
                    return (
                        <div key={"bestOfForMobile"+item.title} className="bestPost forDesktop" onClick={() => handleClick(item.id)} style={{cursor: "pointer"}}>
                          <div className={i % 2 == 0 ? "image left" : "image right"} style={{backgroundImage: "url(" + item.image + ")"}}></div>
                          <h3>{item.title}</h3>
                          <p style={{lineHeight: 1.4, marginTop: '8px'}}>{item.desc}</p>
                        </div>
                    )
                  })}
        </Carousel>
          </div>
        </Container>
      </Hide>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    }
  };
};

export default connect(null, mapDispatchToProps)(BestOf);
