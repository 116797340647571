import axios from "axios";
import React, { Component } from "react";
import Modal from "react-modal";
import GenericInput from "../../components/GenericInput/GenericInput";
import { withFirebase } from "../../hoc/firebase/context";

export type AuthModalType = "login" | "registration" | "passwordReset";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const labelsMap: {
  [key in AuthModalType]: { title: string; button: string };
} = {
  login: { title: "LOGIN", button: "LOGIN" },
  passwordReset: { title: "PASSWORD RESET", button: "RESET" },
  registration: { title: "REGISTRAZIONE", button: "REGISTRATI" },
};

const initForm = {
  mail: {
    type: "text",
    label: "EMAIL *",
    value: "",
    required: true,
    autoComplete: "username",
  },
  password: {
    type: "password",
    label: "PASSWORD *",
    value: "",
    required: true,
  },
  passwordConfirm: {
    type: "password",
    label: "PASSWORD CONFIRM*",
    value: "",
    required: true,
  },
};

Modal.setAppElement("#root");

class AuthModal extends Component<{
  isOpen: boolean;
  firebase: any;
  history: any;
  closeModal: any;
  mode: AuthModalType;
}> {
  state = {
    form: initForm,
    error: "",
    mode: "login",
  };

  componentDidUpdate(prevProps: any) {
    if (!prevProps || !this.state.mode || prevProps.mode !== this.props.mode) {
      this.setState({ mode: this.props.mode });
    }
  }

  passwordReset = (event: any) => {
    event.preventDefault();
    this.setState({ mode: "passwordReset" });
  };

  handleInputTextChange = (event: any, fieldKey: any) => {
    const newForm: any = { ...this.state.form };
    const newField = { ...newForm[fieldKey] };
    newField.value = event.target.value;
    newForm[fieldKey] = newField;
    this.setState({ form: newForm });
  };

  resetFormData = () => {
    this.setState({
      form: initForm,
    });
  };

  submitHandler = (event: any) => {
    event.preventDefault();
    let promise;
    switch (this.state.mode) {
      case "login":
        promise = this.props.firebase.doSignInWithEmailAndPassword(
          this.state.form.mail.value,
          this.state.form.password.value
        );
        break;
      case "registration":
        // promise = this.props.firebase.doCreateUserWithEmailAndPassword(
        //   this.state.form.mail.value,
        //   this.state.form.password.value
        // );
        let url = `${process.env.REACT_APP_BACK_END_URL}/user/new`;
        promise = axios.put(url, {
          email: this.state.form.mail.value,
          password: this.state.form.password.value,
          name: "nome",
          surname: "cognome",
          newsletter: true,
        });
        break;
      case "passwordReset":
        promise = this.props.firebase.doPasswordReset(
          this.state.form.mail.value
        );
        break;
    }

    promise
      ?.then((resp: any): any => {
        this.resetFormData();
        this.props.closeModal();
      })
      .catch((err: any) => {
        try {
          const error =
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : err?.message;
          this.setState({ error });
        } catch (e) {}
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Auth modal"
      >
        <h3>{labelsMap[this.state.mode as AuthModalType].title}</h3>
        {this.state.mode ? (
          <form onSubmit={this.submitHandler}>
            {Object.keys(this.state.form).map((el) => {
              switch (el) {
                case "passwordConfirm":
                  return this.state.mode === "registration" ? (
                    <GenericInput
                      key={el}
                      name={el}
                      {...(this.state.form as any)[el]}
                      handler={this.handleInputTextChange}
                    />
                  ) : null;
                case "password":
                  return ["registration", "login"].includes(this.state.mode) ? (
                    <GenericInput
                      key={el}
                      name={el}
                      {...(this.state.form as any)[el]}
                      handler={this.handleInputTextChange}
                    />
                  ) : null;
                default:
                  return (
                    <GenericInput
                      key={el}
                      name={el}
                      {...(this.state.form as any)[el]}
                      handler={this.handleInputTextChange}
                    />
                  );
              }
            })}
            <p style={{ color: "red" }}>{this.state.error}</p>
            <button className="btn btn-primary">
              {this.state.mode === "login" ? "LOGIN" : "REGISTRATION"}
            </button>
            {["registration", "login"].includes(this.state.mode) ? (
              <p>
                <a onClick={this.passwordReset} href="/">
                  Password dimenticata?
                </a>
              </p>
            ) : null}
          </form>
        ) : null}
      </Modal>
    );
  }
}

export default withFirebase(AuthModal);
