import axios from "axios";
import { User } from "../../models/user";
import { Chart, Format, Product, Spedition } from "./../../models/product";
import * as actionTypes from "./actionTypes";
import { endHttpCall, startHttpCall } from "./utils";

export const saveAllOrders = (orders: any) => {
  return {
    type: actionTypes.SAVE_ALL_ORDERS,
    orders: orders,
  };
};

export const getAllOrders = () => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/shop/all`;
    axios
      .get(url)
      .then((resp) => {
        dispatch(saveAllOrders(resp.data));
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const increaseProductAmount = (product: Product, format: Format) => {
  return (dispatch: any) => {
    dispatch(changeChartProductAmount(product, format, 1));
  };
};

export const decreaseProductAmount = (product: Product, format: Format) => {
  return (dispatch: any) => {
    dispatch(changeChartProductAmount(product, format, -1));
  };
};

// inerisce, incrementa e decrementa i prodotti nel carrello
export const changeChartProductAmount = (
  product: Product,
  format: Format,
  amount: number
) => {
  return {
    type: actionTypes.CHANGE_CHART_PRODUCT_AMOUNT,
    format,
    product,
    amount,
  };
};

export const chartReset = () => {
  return {
    type: actionTypes.CHART_RESET,
  };
};

export const sendOrder = (
  chart: Chart,
  spedition: Spedition,
  user: User,
  onSuccess?: any
) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/shop/new`;
    axios
      .put(url, { chart, spedition, user })
      .then((ret) => {
        if (onSuccess) {
          onSuccess(ret);
        }
        dispatch(chartReset());
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const setSpeditionData = (spedition: Spedition) => {
  return {
    type: actionTypes.SET_SPEDITION_DATA,
    spedition: spedition,
  };
};
