import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../store/actions";
import "./Dev.scss";
class Dev extends Component<any> {
  state: any = {};

  subscribe = () => {
    this.props.subscribeMailingList();
  };

  render() {
    return (
      <div id="stripe-payment-btn" onClick={this.subscribe}>
        SUBSCRIBE MAILINGLIST
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    subscribeMailingList: () => dispatch(action.subscribeMailingList()),
  };
};

export default connect(null, mapDispatchToProps)(Dev);
