import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "../../widgets/Basics/Container";
import Footer from "../../widgets/Footer/Footer";
import NavBar from "../../widgets/NavBar/NavBar";
import Body from  './Body'

const AllBrands = (props : any) => {
    var first = '';

    return <div className="shop">
    <NavBar></NavBar>
    <Container>
        <Body></Body>
    </Container>
    <Footer></Footer>
    </div>
}

export default AllBrands
