import React from "react";

import GenericInput from "../GenericInput/GenericInput";
import { FormModel } from "../../models/rest";

export default function GenericForm(props: {
  onSubmit: any;
  form: FormModel;
  handler: any;
  children: any;
  domains?: any;
}) {
  return (
    <form onSubmit={props.onSubmit}>
      {Object.keys(props.form).map((el) => {
        const childrenChoices: any = {};
        if (props.form[el] && props.form[el].childs) {
          Object.keys((props.form[el] as any).childs).forEach((childKey) => {
            childrenChoices[childKey] =
              props.domains && props.domains[childKey];
          });
        }
        return (
          <GenericInput
            key={el}
            name={el}
            {...props.form[el]}
            handler={props.handler(props.form[el].type)}
            choices={
              props.form[el].choices
                ? props.form[el].choices
                : props.domains && props.domains[el]
            }
            childChoices={childrenChoices}
            emptyLabel={"Seleziona"}
          />
        );
      })}
      {props.children}
    </form>
  );
}
