import React, { useState } from "react";
import { useHistory } from "react-router";
import { withFirebase } from "../../../../hoc/firebase/context";
import { InputText } from "../../widgets/Input/Text";
import "./scheda.scss";

function RecuperaAlert(props: any) {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  async function send() {
    console.log(props["firebase"]);
    try {
      setSubmitting(true);
      if (email == "") {
        setSubmitting(false);
        setSubmittedFlag(true);
      } else {
        // call function here props.signIn()
        await props["firebase"].doPasswordReset(email).catch((e: any) => {
          console.log(e);
          debugger;
        });
        toHome();
        setSubmitting(false);
      }
    } catch (error) {
      setError(error);
    }
  }

  const history = useHistory();
  const toHome = () => {
    history.push({
      pathname: "/shop",
      state: {},
    });
  };
  const toRegistration = () => {
    history.push({
      pathname: "/registration",
      state: {},
    });
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <div className="loginAlert">
      <div className="shadow"></div>
      <div className="alertBody">
        <div className="alertFlex">
          <h2>Cambia Password</h2>
          <p>
            {error != ""
              ? "Ci dispiace, sembra che i dati inseriti siano errati"
              : ""}
          </p>
          <InputText
            placeholder={"Indirizzo e-mail"}
            callback={handleEmail}
            missed={submittedFlag && email == ""}
            missedText="Questo campo è obbligatorio"
            secureText={false}
            onKeyDown={() => {}}
          ></InputText>
          <div className="flex"></div>
          <a
            className="cta"
            onClick={() => (isSubmitting ? () => {} : send())}
            style={{ opacity: isSubmitting ? "0.6" : "1" }}
          >
            <p>{isSubmitting ? "Caricamento" : "Invia"}</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default withFirebase(RecuperaAlert);
