import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Brand } from "../../../models/product";
import { carouselResponsiveBreakpoints } from "../../../utils/consts";
import "./Partners.scss";

function arrayToChunk(array: any[], chunkSize: number) {
  const ret = [];
  for (var i = 0; i < array.length; i += chunkSize)
    ret.push(array.slice(i, i + chunkSize));
  return ret;
}

function PartnerBlock(props: {
  brands: Brand[];
  isMobile: boolean | undefined;
}) {
  return (
    <React.Fragment>
      {arrayToChunk(props.brands, props.isMobile ? 3 : 4).map(
        (brands: Brand[], index) => {
          return (
            <div key={"brand-row" + index} className="row">
              {brands.map((brand) => (
                <div
                  key={brand.id}
                  className="image"
                  style={{ backgroundImage: `url('${brand.image}')` }}
                  // style={{
                  //   backgroundImage:
                  //     'url("https://storage.googleapis.com/eurosaga-agents.appspot.com/Brand/oYSp4hO38g6BPILWLoeh/main.png?GoogleAccessId=firebase-adminsdk-8kdl7%40eurosaga-agents.iam.gserviceaccount.com&Expires=16447014000&Signature=SD95gNTp7AP%2BJaHJpH%2BIqxmphc9zgOoPFJalaSS8ElUnr9h29bQm9egoblriYloetXRwhTCI0u5%2FHl6WkakzO%2FTjnZ8wEljvxV4oNBaU10GdhFPkc%2FTwUkLOa3KMDvzeR32VqJ45dmPNCoQw5WmdGBz3IPZ%2F4pfiQyx5MldiFjOu1z%2BYOEW%2Fxf0MEYAf%2BUKbGlegOuTmTFc%2F8UF96U8fZ%2F6ekka7B9aJ%2FJNwhB6jSxHJi7nkbb8gy8HZ%2FdvLmy%2BO7HGP0Ovdye09iu4l7vefEkUwgNUStw2eNK1lLR%2BTE7P6ho7HUuo3%2B3EhpZfh2M9ODo93I%2Bl41wK%2Bvpfq7EzBHw%3D%3D")',
                  // }}
                ></div>
              ))}
            </div>
          );
        }
      )}
    </React.Fragment>
  );
}

class Partners extends Component<{ brands: Brand[]; t: any }> {
  state = {
    isMobile: undefined,
    windowSize: undefined,
  };

  handleResize = () => {
    const windowSize = window.innerWidth;
    this.setState({ windowSize, isMobile: windowSize < 767 });
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div className="Partners" id="#brands">
        <h3>{this.props.t("partner:title")}</h3>

        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={carouselResponsiveBreakpoints}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={true}
          transitionDuration={800}
          arrows={false}
        >
          {arrayToChunk(this.props.brands, this.state.isMobile ? 6 : 12).map(
            (el, index) => (
              <PartnerBlock
                key={"row" + index}
                brands={el}
                isMobile={this.state.isMobile}
              />
            )
          )}
        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands
      .filter((el: Brand) => !el.hide)
      .sort((a: Brand, b: Brand) => (b.priority || 0) - (a.priority || 0)),
  };
};

export default compose(
  withTranslation("partner"),
  connect(mapStateToProps)
)(Partners as any);
