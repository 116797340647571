import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import mapImage from "../../../assets/images/globe.gif";
import { scrollTo } from "../../../utils/functions";
import "./AboutUs.scss";

class AboutUs extends Component<any> {
  joinUsHandler = (event: any) => {
    event.preventDefault();
    scrollTo("#contactUs");
  };
  render() {
    return (
      <div className="AboutUs" id="#aboutUs">
        <div className="top-row">
          <div className="left-column">
            <h1>
              {this.props.t("aboutUs:title1")}
              <span>{this.props.t("aboutUs:title2")}</span>
              {this.props.t("aboutUs:title3")}
            </h1>
            <h3>{this.props.t("aboutUs:subtitle-1")}</h3>
            <h3>{this.props.t("aboutUs:subtitle-2")}</h3>
            <h3>{this.props.t("aboutUs:subtitle-3")}</h3>
          </div>
          <div className="right-column">
            <p>{this.props.t("aboutUs:desc-1")}</p>
            <p>{this.props.t("aboutUs:desc-2")}</p>
            <p>{this.props.t("aboutUs:desc-3")}</p>
          </div>
        </div>
        <div className="bottom-row">
          <div className="left-column">
            <h4>{this.props.t("aboutUs:bottom-title")}</h4>
            <p className="first-paragraph">
              {this.props.t("aboutUs:bottom-par")}
            </p>
            <div className="info-block first-block">
              <div className="icon-container">
                <div className="eurosaga icon-search"></div>
              </div>
              <div className="text-container">
                <h5>{this.props.t("aboutUs:bottom-subtitle1")}</h5>
                <p>
                  {this.props.t("aboutUs:bottom-subpar1.1")}
                  <br />
                  {this.props.t("aboutUs:bottom-subpar1.2")}
                </p>
                <a href="/" onClick={this.joinUsHandler}>
                  {this.props.t("aboutUs:bottom-link")}
                </a>
              </div>
            </div>
            <div className="info-block">
              <div className="icon-container termometro">
                <div className="eurosaga icon-termometro"></div>
              </div>
              <div className="text-container">
                <h5>{this.props.t("aboutUs:bottom-subtitle2")}</h5>
                <p>{this.props.t("aboutUs:bottom-subpar2")}</p>
              </div>
            </div>
          </div>
          <div
            className="map"
            style={{ backgroundImage: `url('${mapImage}')` }}
          ></div>
        </div>
      </div>
    );
  }
}

export default withTranslation("aboutUs")(AboutUs);
