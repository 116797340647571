import React, { Component } from "react";
import { ProductRest } from "../../../models/rest";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { getInitState, formatForm, inputHandler } from "../../../utils/form";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

class NewProducts extends Component<any> {
  state = { form: {} };

  componentDidMount() {
    this.setState(getInitState(ProductRest));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    const objToSave = formatForm(this.state.form);
    this.props.createProduct(
      objToSave,
      this.props.history.push("/admin/products")
    );
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
          domains={this.props.domain}
        >
          <div className="text-center mb-3">
            <button className="btn btn-primary" type="submit">
              CREA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createProduct: (product: any, onSuccess: any) => {
      dispatch(actions.newProduct(product, onSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProducts);
