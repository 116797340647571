import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { UserVerify } from "../../../models/rest";
import { User } from "../../../models/user";
import * as actions from "../../../store/actions";
import { getInitState, inputHandler } from "../../../utils/form";

class ModifyBrand extends Component<any> {
  state = { form: {} };

  componentDidMount() {
    const id = this.props.location.state.id;
    const user = this.props.users.find((el: User) => el.id === id);
    this.setState(getInitState(UserVerify, user));
  }

  submitHandler = (event: any) => {
    event.preventDefault();

    this.props.modifyUserVerify(
      (this.state.form as any).id.value,
      (this.state.form as any).verified.value === "true",
      this.props.history.push("/admin/users")
    );
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
          domains={this.props.domain}
        >
          <div className="text-center mb-3">
            <button className="btn btn-primary" type="submit">
              MODIFICA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    users: state.utils.users,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyUserVerify: (id: string, verified: boolean, onSuccess: any) => {
      dispatch(actions.modifyUserVerify(id, verified, onSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyBrand);
