import axios from "axios";
import { User } from "./../../models/user";
import * as actionTypes from "./actionTypes";

export const setScreenWidth = (screenWidth: any) => {
  return {
    type: actionTypes.SET_SCREEN_WIDTH,
    screenWidth: screenWidth,
  };
};

export const setAuthToken = (token: any, isEditor: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.SET_AUTH_TOKEN, token: token, isEditor });
  };
};

export const unsetAuthToken = () => {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.UNSET_AUTH_TOKEN });
  };
};

export const startHttpCall = () => {
  return { type: actionTypes.START_HTTP_CALL };
};

export const endHttpCall = () => {
  return { type: actionTypes.END_HTTP_CALL };
};

export const createUserAccount = (userData: User) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/user/new`;
    axios
      .put(url, userData)
      .then((resp: { data: any }) => {
        dispatch(endHttpCall());
      })
      .catch((err: any) => {
        dispatch(endHttpCall());
      });
  };
};

export const saveUsers = (users: User[]) => {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.SAVE_USERS, users });
  };
};

export const saveMe = (user: User) => {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.SAVE_ME, user });
  };
};

export const modifyMe = (user: User) => {
  return (dispatch: any) => {
    let url = `${process.env.REACT_APP_BACK_END_URL}/user/modifyMe`;
    axios
      .post(url, user)
      .then((_: any) => {
        dispatch(getMe());
      })
      .catch((err: any) => {});
  };
};

export const getAllUsers = () => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/user/all`;
    axios
      .get(url)
      .then((resp: { data: User[] }) => {
        dispatch(saveUsers(resp.data));
        dispatch(endHttpCall());
      })
      .catch((err: any) => {
        dispatch(endHttpCall());
      });
  };
};

export const getMe = () => {
  return (dispatch: any) => {
    let url = `${process.env.REACT_APP_BACK_END_URL}/user/me`;
    axios
      .get(url)
      .then((resp: { data: User }) => {
        dispatch(saveMe(resp.data));
      })
      .catch((err: any) => {});
  };
};

export const modifyUserVerify = (
  id: string,
  verify: boolean,
  onSuccess: any
) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/user/setVerify`;
    axios
      .post(url, { id, verify })
      .then((_: any) => {
        dispatch(endHttpCall());
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err: any) => {
        dispatch(endHttpCall());
      });
  };
};

export const subscribeMailingList = () => {
  return (dispatch: any) => {
    let url = `${process.env.REACT_APP_BACK_END_URL}/user/mailingListSubscribe`;
    axios
      .put(url, {})
      .then((_: any) => {
        dispatch(getMe());
      })
      .catch((err: any) => {});
  };
};
