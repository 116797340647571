import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router";
import Container from '../../widgets/Basics/Container';
import Body from './Body'
import RightColumn from './RightColumn'
import './checkout.scss'
import Footer from '../../widgets/Footer/Footer';
import NavBar from './../../widgets/NavBar/NavBar';

const Checkout = () => {
    const [index, setIndex] = useState('dashboard');
    const [spedition, setSpedition] = useState(undefined);
    const [costs, setCosts] = useState(undefined);
    const [coupon, setCoupon] = useState(undefined);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        var params = location.pathname.split("/");

        try {
            var tempIndex = params[2]
            setIndex(tempIndex)
        } catch (e) {
            history.push({
                pathname: "/catalogo",
                state: {},
            });
        }
    },[location])

    return (
        <div className="shop checkoutPage">
            <NavBar></NavBar>
            <div>
                <div className="rightBackground"></div>
                <Container>
                    <div className="flex">
                        <Body costs={costs} spedition={spedition} eventSpedition={setSpedition} eventCosts={setCosts} index={index} coupon={coupon}></Body>
                        <RightColumn costs={costs} spedition={spedition} eventCoupon={setCoupon}></RightColumn>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Checkout