import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import backgroundImage1024 from "../../../assets/images/landing_1024.jpg";
import backgroundImage1440 from "../../../assets/images/landing_1440.jpg";
import backgroundImage1920 from "../../../assets/images/landing_1920.jpg";
import backgroundImage768 from "../../../assets/images/landing_768.jpg";
import backgroundImageMobile from "../../../assets/images/landing_mobile.jpg";
import { breakpoints } from "../../../utils/consts";
import "./Home.scss";

class Home extends Component<any> {
  state = {
    isNewsBarOpen: false,
    withNews: false,
    windowSize: 0,
  };

  newsClickHandler = () => {
    this.setState((prevState: any) => {
      return { isNewsBarOpen: !prevState.isNewsBarOpen };
    });
  };
  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const windowSize = window.innerWidth;
    this.setState({
      windowSize,
    });
  };

  render() {
    return (
      <div
        className="Home"
        style={{
          backgroundImage: `url('${
            this.state.windowSize < breakpoints.sm
              ? backgroundImageMobile
              : this.state.windowSize < breakpoints.md
              ? backgroundImage768
              : this.state.windowSize < breakpoints.lg
              ? backgroundImage1024
              : this.state.windowSize < breakpoints.xl
              ? backgroundImage1440
              : backgroundImage1920
          }')`,
        }}
      ></div>
    );
  }
}

export default compose(withRouter)(Home);
