import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions";
import { InputTextWithDefaultValue } from "../../../widgets/Input/Text";
import { User } from "./../../../../../models/user";

const Dashboard = (props: any) => {
  const [error, setError] = useState("");
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [user, setUser] = useState<User>({});

  useEffect(() => {
    var tempUser: User = props["me"];
    setUser(tempUser);
  }, []);

  async function save() {
    try {
      setSubmitting(true);
      await props["modifyMe"](user);
      const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      setSubmitting(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }

  const handleName = (e: any) => {
    var tempUser = user;
    tempUser.name = e.target.value;
    setUser(tempUser);
  };

  const handleSurname = (e: any) => {
    var tempUser = user;
    tempUser.surname = e.target.value;
    setUser(tempUser);
  };

  const handleEmail = (e: any) => {
    var tempUser = user;
    tempUser.email = e.target.value;
    setUser(tempUser);
  };

  const handlePhone = (e: any) => {
    var tempUser = user;
    //tempUser.addressNumber = e.target.value;
    setUser(tempUser);
  };

  const handleCompany = (e: any) => {
    var tempUser = user;
    tempUser.company = e.target.value;
    setUser(tempUser);
  };

  const handleType = (e: any) => {
    var tempUser = user;
    tempUser.type = e.target.value;
    setUser(tempUser);
  };

  const handleIVA = (e: any) => {
    var tempUser = user;
    tempUser.iva = e.target.value;
    setUser(tempUser);
  };

  const handleFiscalCode = (e: any) => {
    var tempUser = user;
    tempUser.fiscalCode = e.target.value;
    setUser(tempUser);
  };

  return (
    <div className="page">
      <h2>Dashboard</h2>
      <h3>Ciao {user.name}</h3>
      <p>
        Benvenuto nella dashboard del tuo account, navigando nel menù a lato
        potrai gestire e modficare tuoi dati, controllare i tuoi ordini e la tua
        wishlist ed effettuare il logout dal sito.
      </p>

      <div className="section">
        <h3>Informazioni Account</h3>
        <div className={"divider"}></div>
        <div className={"flex"}>
          <div className="option">
            <p>Nome</p>
            <InputTextWithDefaultValue
              value={user.name}
              placeholder={"Nome"}
              callback={handleName}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>Cognome</p>
            <InputTextWithDefaultValue
              value={user.surname}
              placeholder={"Cognome"}
              callback={handleSurname}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
        <div className={"flex"}>
          <div className="option">
            <p>E-mail</p>
            <InputTextWithDefaultValue
              value={user.email}
              placeholder={"Email"}
              callback={handleEmail}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>Telefono</p>
            <InputTextWithDefaultValue
              value={undefined}
              placeholder={"Telefono"}
              callback={handlePhone}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
      </div>

      <div className="section" style={{ display: "none" }}>
        <h3>Informazioni generali</h3>
        <div className={"divider"}></div>
        <div className={"flex"}>
          <div className="option">
            <p>Società</p>
            <InputTextWithDefaultValue
              value={user.company}
              placeholder={"Azienda"}
              callback={handleCompany}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>Tipologia</p>
            <InputTextWithDefaultValue
              value={user.type}
              placeholder={"Tipologia"}
              callback={handleType}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
        <div className={"flex"}>
          <div className="option">
            <p>P. Iva</p>
            <InputTextWithDefaultValue
              value={user.iva}
              placeholder={"Partita IVA"}
              callback={handleIVA}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>Codice Fiscale</p>
            <InputTextWithDefaultValue
              value={user.fiscalCode}
              placeholder={"Codice Fiscale"}
              callback={handleFiscalCode}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
      </div>

      <a
        className="cta"
        onClick={() => (isSubmitting ? () => {} : save())}
        style={{ opacity: isSubmitting ? "0.6" : "1" }}
      >
        <p>{isSubmitting ? "Caricamento" : "Salva"}</p>
      </a>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    me: state.utils.me,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyMe: (user: User) => {
      dispatch(actions.modifyMe(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
