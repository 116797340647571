import { DomainRest, ShopImagesName } from "../../models/rest";
import * as actionTypes from "../actions/actionTypes";

const initialState = Object.keys(DomainRest).reduce(
  (map: any, obj) => {
    map[obj] = [];
    map[obj + "Map"] = {};
    return map;
  },
  { filterAs: ShopImagesName.map((el) => ({ label: el, value: el })) }
);

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_DOMAIN:
      return {
        ...state,
        [action.domainName]: action.domainValue
          .map((el: { id: string; value: string }) => {
            return { value: el.id, label: el.value };
          })
          .sort((a: any, b: any) => {
            return a.label > b.label ? 1 : -1;
          }),
        [action.domainName + "Map"]: action.domainValue.reduce(
          (map: any, el: { id: string; value: string }) => {
            map[el.id] = el.value;
            return map;
          },
          {}
        ),
      };
    case actionTypes.SAVE_ALL_BRANDS:
      const brands = action.brands.map(
        (brand: { id: String; name: string }) => {
          return { value: brand.id, label: brand.name };
        }
      );
      const brandMap = action.brands.reduce((map: any, brand: any) => {
        map[brand.id] = brand.name;
        return map;
      }, {});
      return {
        ...state,
        brand: brands,
        brandMap: brandMap,
      };
    default:
      return { ...state };
  }
};

export default reducer;
