import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Logo from "../../../../../assets/images/logo.svg";
import * as actions from "../../../../../store/actions";
import "../checkout.scss";
import { User } from "./../../../../../models/user";
import InputSelectForCheckout from "./../../../widgets/Input/SelectForCheckout";
import { InputTextWithDefaultValue } from "./../../../widgets/Input/Text";

const Informazione = (props: any) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [user, setUser] = useState<User>({});
  var region = "";
  const [flagIfDisagiato, setFlagIfDisagiato] = useState(false);

  useEffect(() => {
    console.log(props["me"]);
    var tempUser: User = props["me"];
    setUser(tempUser);
    if (setIfDisagiato(tempUser)) {
      props["eventSpedition"](18.5);
    } else {
      props["eventSpedition"](0);
    }
  }, []);

  async function save() {
    try {
      setSubmittedFlag(false);
      setSubmitting(true);
      await props["modifyMe"](user);
      const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      setSubmitting(false);
      if (
        user.speditionName != "" &&
        user.speditionSurname != "" &&
        user.address != "" &&
        user.addressNumber != "" &&
        user.cap != "" &&
        user.city != "" &&
        user.province != "" &&
        user.region != ""
      ) {
        goToSpedizione();
      } else {
        setSubmittedFlag(true);
      }
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }

  function setIfDisagiato(user: any) {
    if (user.cap == "80071") return true;
    if (user.cap == "7046") return true;
    if (user.cap == "80070") return true;
    if (user.cap == "9011") return true;

    if (user.cap == "57031") return true;
    if (user.cap == "80073") return true;
    if (user.cap == "57034") return true;
    if (user.cap == "9014") return true;

    if (user.cap == "80074") return true;
    if (user.cap == "91023") return true;
    if (user.cap == "80070") return true;
    if (user.cap == "80075") return true;

    if (user.cap == "58012") return true;
    if (user.cap == "58012") return true;
    if (user.cap == "58012") return true;
    if (user.cap == "80077") return true;

    if (user.cap == "7024") return true;
    if (user.cap == "80076") return true;
    if (user.cap == "57031") return true;
    if (user.cap == "92010") return true;

    if (user.cap == "30126") return true;
    if (user.cap == "92010") return true;
    if (user.cap == "98055") return true;
    if (user.cap == "57030") return true;

    if (user.cap == "57033") return true;
    if (user.cap == "91010") return true;
    if (user.cap == "57034") return true;
    if (user.cap == "30100") return true;

    if (user.cap == "4027") return true;
    if (user.cap == "91017") return true;
    if (user.cap == "4027") return true;
    if (user.cap == "57037") return true;

    if (user.cap == "57030") return true;
    if (user.cap == "80079") return true;
    if (user.cap == "57038") return true;
    if (user.cap == "57039") return true;

    if (user.cap == "57031") return true;
    if (user.cap == "30124") return true;
    if (user.cap == "9017") return true;
    if (user.cap == "80070") return true;

    if (user.cap == "71100") return true;
    if (user.cap == "90010") return true;
    if (user.cap == "4020") return true;
    if (user.cap == "30142") return true;

    if (user.cap == "30123") return true;
    if (user.cap == "30141") return true;
    if (user.cap == "30142") return true;
    if (user.cap == "30141") return true;

    if (user.cap == "30133") return true;
    if (user.cap == "30141") return true;
    if (user.cap == "30124") return true;
    if (user.cap == "30121") return true;

    if (user.cap == "30132") return true;
    if (user.cap == "30135") return true;
    if (user.cap == "30142") return true;
    if (user.cap == "30126") return true;

    if (user.cap == "30126") return true;
    if (user.cap == "30142") return true;
    if (user.cap == "30126") return true;
    if (user.cap == "30132") return true;

    if (user.cap == "30126") return true;
    if (user.cap == "30141") return true;
    if (user.cap == "30126") return true;
    if (user.cap == "30122") return true;

    if (user.cap == "30173") return true;

    //new caps
    if (user.cap == "02022") return true;
    if (user.cap == "02024") return true;
    if (user.cap == "11011") return true;
    if (user.cap == "11012") return true;

    if (user.cap == "11014") return true;
    if (user.cap == "11014") return true;
    if (user.cap == "11015") return true;
    if (user.cap == "11016") return true;

    if (user.cap == "11017") return true;
    if (user.cap == "11018") return true;
    if (user.cap == "11021") return true;
    if (user.cap == "11022") return true;

    if (user.cap == "11025") return true;
    if (user.cap == "11028") return true;
    if (user.cap == "23029") return true;
    if (user.cap == "67012") return true;

    if (user.cap == "67013") return true;
    if (user.cap == "67014") return true;
    if (user.cap == "67015") return true;
    if (user.cap == "67023") return true;

    if (user.cap == "67024") return true;
    if (user.cap == "67025") return true;
    if (user.cap == "67029") return true;
    if (user.cap == "67032") return true;

    if (user.cap == "67033") return true;
    if (user.cap == "67034") return true;
    if (user.cap == "67036") return true;
    if (user.cap == "67038") return true;

    if (user.cap == "67052") return true;
    if (user.cap == "67054") return true;
    if (user.cap == "67060") return true;
    if (user.cap == "85012") return true;

    if (user.cap == "85014") return true;
    if (user.cap == "85017") return true;
    if (user.cap == "85018") return true;
    if (user.cap == "85031") return true;

    if (user.cap == "85032") return true;
    if (user.cap == "85035") return true;
    if (user.cap == "85036") return true;
    if (user.cap == "85039") return true;

    if (user.cap == "85051") return true;
    if (user.cap == "85053") return true;
    if (user.cap == "85054") return true;
    if (user.cap == "85058") return true;

    if (user.cap == "08034") return true;
    if (user.cap == "08037") return true;
    if (user.cap == "09011") return true;
    if (user.cap == "09053") return true;

    if (user.cap == "09063") return true;
    if (user.cap == "09064") return true;
    if (user.cap == "88817") return true;
    if (user.cap == "88817") return true;
    if (user.cap == "89053") return true;
  }

  const handleName = (e: any) => {
    var tempUser = user;
    tempUser.speditionName = e.target.value;
    setUser(tempUser);
  };

  const handleSurname = (e: any) => {
    var tempUser = user;
    tempUser.speditionSurname = e.target.value;
    setUser(tempUser);
  };

  const handleCity = (e: any) => {
    var tempUser = user;
    tempUser.city = e.target.value;
    setUser(tempUser);
  };

  const handleCAP = (e: any) => {
    var tempUser = user;
    tempUser.cap = e.target.value;
    setUser(tempUser);
    modifyMe();
    if (setIfDisagiato(tempUser)) {
      props["eventSpedition"](18.5);
    } else {
      if (
        user.region == "Sicilia" ||
        user.region == "Sardegna" ||
        user.region == "Calabria"
      )
        props["eventSpedition"](5.9);
      else props["eventSpedition"](0);
    }
  };

  const handleAddress = (e: any) => {
    var tempUser = user;
    tempUser.address = e.target.value;
    setUser(tempUser);
  };

  const handleAddNumber = (e: any) => {
    var tempUser = user;
    tempUser.addressNumber = e.target.value;
    setUser(tempUser);
  };

  const handlePhone = (e: any) => {
    var tempUser = user;
    tempUser.phone = e.target.value;
    setUser(tempUser);
  };

  const handleProvince = (e: any) => {
    var tempUser = user;
    tempUser.province = e.target.value;
    setUser(tempUser);
    modifyMe();
  };

  async function modifyMe() {
    setSubmitting(true);
    await props["modifyMe"](user);
    setSubmitting(false);
  }

  const handleRegion = (e: any) => {
    var tempUser = user;
    tempUser.region = e.target.value;
    setUser(tempUser);
    modifyMe();
    if (!flagIfDisagiato) {
      if (
        user.region == "Sicilia" ||
        user.region == "Sardegna" ||
        user.region == "Calabria"
      )
        props["eventSpedition"](5.9);
      else props["eventSpedition"](0);
    }
  };

  const history = useHistory();
  async function goToCheckout() {
    history.push({
      pathname: "/catalogo",
      state: {},
    });
  }

  function goToSpedizione() {
    history.push({
      pathname: "/checkout/pagamento",
      state: {},
    });
  }

  return (
    <div className="informazione">
      <img
        onClick={() => goToCheckout()}
        className="logo"
        src={Logo}
        alt="Logo"
        style={{ cursor: "pointer" }}
      />
      <ul className="indexPage">
        <li>
          <p style={{ cursor: "pointer" }} onClick={() => goToCheckout()}>
            Home &gt;{" "}
          </p>
        </li>
        <li>
          <p
            className="active"
            style={{ cursor: "pointer" }}
            onClick={() => goToCheckout()}
          >
            Spedizione &gt;
          </p>
        </li>
        <li>
          <p>Fatturazione</p>
        </li>
      </ul>
      <h3 className="section-title">Informazioni contatto</h3>
      {props["isLogged"] && (
        <p className="info-user">
          {user.name} {user.surname} ({user.email})
        </p>
      )}
      {props["isLogged"] && (
        <p
          className="logout"
          // onClick={() => logout()}
          style={{ cursor: "Pointer" }}
        >
          Logout
        </p>
      )}
      <div className="check" style={{ display: "none" }}>
        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
        <label> Resta aggiornato su promozioni e offerte esclusive</label>
      </div>
      {!props["isLogged"] ? (
        <>
          <a
            className="cta"
            // onClick={() => goToLogin()}
          >
            <p>{isSubmitting ? "Caricamento" : "Accedi per continuare"}</p>
          </a>
          <p className="logout" style={{ cursor: "Pointer" }}>
            Crea un nuovo account
          </p>
        </>
      ) : (
        <div>
          <div>
            <div className="section">
              <h3 className="section-title">Indirizzo di spedizione</h3>
              <div className={"divider"}></div>
              <div className={"flex"}>
                <div className="option">
                  <p>Alla cortese attenzione di</p>
                  <InputTextWithDefaultValue
                    value={user.speditionName}
                    placeholder={"Nome"}
                    callback={handleName}
                    missed={
                      submittedFlag &&
                      user.speditionName == "" &&
                      user.speditionName == undefined
                    }
                    missedText={"Campo obbligatorio"}
                  ></InputTextWithDefaultValue>
                </div>
                <div className="option">
                  <p>&nbsp;</p>
                  <InputTextWithDefaultValue
                    value={user.speditionSurname}
                    placeholder={"Cognome"}
                    callback={handleSurname}
                    missed={
                      submittedFlag &&
                      user.speditionSurname == "" &&
                      user.speditionSurname == undefined
                    }
                    missedText={"Campo obbligatorio"}
                  ></InputTextWithDefaultValue>
                </div>
              </div>
              <div className={"flex"}>
                <div className="option" style={{}}>
                  <p>Via</p>
                  <InputTextWithDefaultValue
                    value={user.address}
                    placeholder={"Via"}
                    callback={handleAddress}
                    missed={
                      submittedFlag &&
                      user.address == "" &&
                      user.address == undefined
                    }
                    missedText={"Campo obbligatorio"}
                    // onKeyDown={() => {}}
                  ></InputTextWithDefaultValue>
                </div>
                <div className="option">
                  <p>Numero</p>
                  <InputTextWithDefaultValue
                    value={user.addressNumber}
                    placeholder={"Numero"}
                    callback={handleAddNumber}
                    missed={
                      submittedFlag &&
                      user.addressNumber == "" &&
                      user.addressNumber == undefined
                    }
                    missedText={"Campo obbligatorio"}
                    // onKeyDown={() => {}}
                  ></InputTextWithDefaultValue>
                </div>
              </div>
              <div className={"flex"}>
                <div className="option">
                  <p>CAP</p>
                  <InputTextWithDefaultValue
                    value={user.cap}
                    placeholder={"CAP"}
                    callback={handleCAP}
                    missed={
                      submittedFlag && user.cap == "" && user.cap == undefined
                    }
                    missedText={"Campo obbligatorio"}
                    // onKeyDown={() => {}}
                  ></InputTextWithDefaultValue>
                </div>
                <div className="option">
                  <p>Città</p>
                  <InputTextWithDefaultValue
                    value={user.city}
                    placeholder={"Città"}
                    callback={handleCity}
                    // callback={handleCity}
                    missed={
                      submittedFlag &&
                      (user.city == "" || user.city == undefined)
                    }
                    missedText={"Campo obbligatorio"}
                    // onKeyDown={() => {}}
                  ></InputTextWithDefaultValue>
                </div>
              </div>
              <div className="option">
                <p>Telefono</p>
                <InputTextWithDefaultValue
                  value={user.phone}
                  placeholder={"Telefono"}
                  callback={handlePhone}
                  missed={
                    submittedFlag && user.phone == "" && user.phone == undefined
                  }
                  missedText={"Campo obbligatorio"}
                  // onKeyDown={() => {}}
                ></InputTextWithDefaultValue>
              </div>
              <div className={"flex"}>
                <div className="option">
                  <p>Provincia</p>
                  <InputSelectForCheckout
                    defaultValue={"" + user.province}
                    placeHolder={"Provincia"}
                    callback={handleProvince}
                  >
                    <optgroup label="Piemonte">
                      <option value="TO">Torino</option>
                      <option value="VC">Vercelli</option>
                      <option value="NO">Novara</option>
                      <option value="CN">Cuneo</option>
                      <option value="AT">Asti</option>
                      <option value="AL">Alessandria</option>
                      <option value="BI">Biella</option>
                      <option value="VB">Verbano-Cusio-Ossola</option>
                    </optgroup>
                    <optgroup label="Valle d'Aosta">
                      <option value="AO">Valle d'Aosta/Vallée d'Aoste</option>
                    </optgroup>
                    <optgroup label="Lombardia">
                      <option value="VA">Varese</option>
                      <option value="CO">Como</option>
                      <option value="SO">Sondrio</option>
                      <option value="MI">Milano</option>
                      <option value="BG">Bergamo</option>
                      <option value="BS">Brescia</option>
                      <option value="PV">Pavia</option>
                      <option value="CR">Cremona</option>
                      <option value="MN">Mantova</option>
                      <option value="LC">Lecco</option>
                      <option value="LO">Lodi</option>
                      <option value="MB">Monza e della Brianza</option>
                    </optgroup>
                    <optgroup label="Trentino-Alto Adige">
                      <option value="BZ">Bolzano/Bozen</option>
                      <option value="TN">Trento</option>
                    </optgroup>
                    <optgroup label="Veneto">
                      <option value="VR">Verona</option>
                      <option value="VI">Vicenza</option>
                      <option value="BL">Belluno</option>
                      <option value="TV">Treviso</option>
                      <option value="VE">Venezia</option>
                      <option value="PD">Padova</option>
                      <option value="RO">Rovigo</option>
                    </optgroup>
                    <optgroup label="Friuli-Venezia Giulia">
                      <option value="UD">Udine</option>
                      <option value="GO">Gorizia</option>
                      <option value="TS">Trieste</option>
                      <option value="PN">Pordenone</option>
                    </optgroup>
                    <optgroup label="Liguria">
                      <option value="IM">Imperia</option>
                      <option value="SV">Savona</option>
                      <option value="GE">Genova</option>
                      <option value="SP">La Spezia</option>
                    </optgroup>
                    <optgroup label="Emilia-Romagna">
                      <option value="PC">Piacenza</option>
                      <option value="PR">Parma</option>
                      <option value="RE">Reggio nell'Emilia</option>
                      <option value="MO">Modena</option>
                      <option value="BO">Bologna</option>
                      <option value="FE">Ferrara</option>
                      <option value="RA">Ravenna</option>
                      <option value="FC">Forlì-Cesena</option>
                      <option value="RN">Rimini</option>
                    </optgroup>
                    <optgroup label="Toscana">
                      <option value="MS">Massa-Carrara</option>
                      <option value="LU">Lucca</option>
                      <option value="PT">Pistoia</option>
                      <option value="FI">Firenze</option>
                      <option value="LI">Livorno</option>
                      <option value="PI">Pisa</option>
                      <option value="AR">Arezzo</option>
                      <option value="SI">Siena</option>
                      <option value="GR">Grosseto</option>
                      <option value="PO">Prato</option>
                    </optgroup>
                    <optgroup label="Umbria">
                      <option value="PG">Perugia</option>
                      <option value="TR">Terni</option>
                    </optgroup>
                    <optgroup label="Marche">
                      <option value="PU">Pesaro e Urbino</option>
                      <option value="AN">Ancona</option>
                      <option value="MC">Macerata</option>
                      <option value="AP">Ascoli Piceno</option>
                      <option value="FM">Fermo</option>
                    </optgroup>
                    <optgroup label="Lazio">
                      <option value="VT">Viterbo</option>
                      <option value="RI">Rieti</option>
                      <option value="RM">Roma</option>
                      <option value="LT">Latina</option>
                      <option value="FR">Frosinone</option>
                    </optgroup>
                    <optgroup label="Abruzzo">
                      <option value="AQ">L'Aquila</option>
                      <option value="TE">Teramo</option>
                      <option value="PE">Pescara</option>
                      <option value="CH">Chieti</option>
                    </optgroup>
                    <optgroup label="Molise">
                      <option value="CB">Campobasso</option>
                      <option value="IS">Isernia</option>
                    </optgroup>
                    <optgroup label="Campania">
                      <option value="CE">Caserta</option>
                      <option value="BN">Benevento</option>
                      <option value="NA">Napoli</option>
                      <option value="AV">Avellino</option>
                      <option value="SA">Salerno</option>
                    </optgroup>
                    <optgroup label="Puglia">
                      <option value="FG">Foggia</option>
                      <option value="BA">Bari</option>
                      <option value="TA">Taranto</option>
                      <option value="BR">Brindisi</option>
                      <option value="LE">Lecce</option>
                      <option value="BT">Barletta-Andria-Trani</option>
                    </optgroup>
                    <optgroup label="Basilicata">
                      <option value="PZ">Potenza</option>
                      <option value="MT">Matera</option>
                    </optgroup>
                    <optgroup label="Calabria">
                      <option value="CS">Cosenza</option>
                      <option value="CZ">Catanzaro</option>
                      <option value="RC">Reggio di Calabria</option>
                      <option value="KR">Crotone</option>
                      <option value="VV">Vibo Valentia</option>
                    </optgroup>
                    <optgroup label="Sicilia">
                      <option value="TP">Trapani</option>
                      <option value="PA">Palermo</option>
                      <option value="ME">Messina</option>
                      <option value="AG">Agrigento</option>
                      <option value="CL">Caltanissetta</option>
                      <option value="EN">Enna</option>
                      <option value="CT">Catania</option>
                      <option value="RG">Ragusa</option>
                      <option value="SR">Siracusa</option>
                    </optgroup>
                    <optgroup label="Sardegna">
                      <option value="SS">Sassari</option>
                      <option value="NU">Nuoro</option>
                      <option value="CA">Cagliari</option>
                      <option value="OR">Oristano</option>
                      <option value="OT">Olbia-Tempio</option>
                      <option value="OG">Ogliastra</option>
                      <option value="VS">Medio Campidano</option>
                      <option value="CI">Carbonia-Iglesias</option>
                    </optgroup>
                  </InputSelectForCheckout>
                </div>
                <div className="option">
                  <p>Regione</p>
                  <InputSelectForCheckout
                    defaultValue={user.region}
                    placeHolder={"Regione"}
                    callback={handleRegion}
                  >
                    <option value="Abruzzo">Abruzzo</option>
                    <option value="Basilicata">Basilicata</option>
                    <option value="Calabria">Calabria</option>
                    <option value="Campania">Campania</option>
                    <option value="Emilia-Romagna">Emilia-Romagna</option>
                    <option value="Friuli-Venezia Giulia">
                      Friuli-Venezia Giulia
                    </option>
                    <option value="Lazio">Lazio</option>
                    <option value="Liguria">Liguria</option>
                    <option value="Lombardia">Lombardia</option>
                    <option value="Marche">Marche</option>
                    <option value="Molise">Molise</option>
                    <option value="Piemonte">Piemonte</option>
                    <option value="Puglia">Puglia</option>
                    <option value="Sardegna">Sardegna</option>
                    <option value="Sicilia">Sicilia</option>
                    <option value="Toscana">Toscana</option>
                    <option value="Trentino-Alto Adige">
                      Trentino-Alto Adige
                    </option>
                    <option value="Umbria">Umbria</option>
                    <option value="Valle d'Aosta">Valle d'Aosta</option>
                    <option value="Veneto">Veneto</option>
                  </InputSelectForCheckout>
                </div>
              </div>
            </div>
          </div>
          <div className="flex vertical">
            <p
              style={{ cursor: "pointer" }}
              onClick={() => goToCheckout()}
              className="backTo"
            >
              &lt; Torna al carrello
            </p>
            <a
              className="cta"
              onClick={() => (isSubmitting ? () => {} : save())}
              style={{ opacity: isSubmitting ? "0.6" : "1" }}
            >
              <p>{isSubmitting ? "Caricamento" : "Avanti"}</p>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    me: state.utils.me,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyMe: (user: User) => {
      dispatch(actions.modifyMe(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Informazione);
