import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import Container from "../../widgets/Basics/Container";
import Hide from "../../widgets/Basics/Hide";
import Footer from "../../widgets/Footer/Footer";
import NavBar from "../../widgets/NavBar/NavBar";
import Body from './Body'
import Header from "./Header";
import './scheda.scss'
import LoginAlert from './LoginAlert'
import BestOf from './BestOf'

function Scheda(props : any) {
    const [prods, setProds] = useState();
    const [prod, setProd] = useState();
    const [brand, setBrand] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();

    useEffect(() => {
        var params = location.pathname.split("/");

        /*if (props != undefined) {
            if (props["isLogged"] != null && !props["isLogged"]) {
                this.o
            }
        }*/

        try {
            var prod_name = params[2] //props["prod_id"];
            props != null && props["products"].map((item : any) => {
                if (prod_name == item.name) {
                    setProd(item)
                    props["brands"].map((element : any) => {
                        if (element.id == item.brand) {
                          setBrand(element)
                          return;
                        }
                    });
                    setIsLoading(false)
                }
            })
            setProds(props["products"].sort( () => .5 - Math.random() ))
        } catch (e) {
            setError(e)
        }
    }, [location])

    if (error != null) {
        return (<>{error}</>) //TODO
    }

    if (isLoading) {
        return (<></>) //TODO
    }

    return (
        <>
            <NavBar></NavBar>
            <div className="shop scheda">
                <Hide forMobile={true}>
                    <Header prod={prod}></Header>
                </Hide>
                <Container>
                    <div className="head">
                        <Hide forMobile={false}>
                            <Header prod={prod}></Header>
                        </Hide>
                        <Body prod={prod} brand={brand}></Body>
                    </div>
                    <BestOf products={prods}></BestOf>
                </Container>
            </div>
            <Footer></Footer>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
      brands: state.brand.brands,
      isLogged: state.utils.isLogged,
      products: state.product.products.sort( () => .5 - Math.random() ),
    }
}

export default connect(mapStateToProps)(Scheda)