import React from "react";
import "./input.scss";

export const InputTextWithDefaultValue = ({
  placeholder,
  callback,
  missed,
  missedText,
  value,
}) => (
  <div className="input-text-wrapper">
    <input
      className="input-text"
      type="text"
      placeholder={placeholder}
      onChange={callback}
      defaultValue={value}
    ></input>
    {missed != null && missed && <p style={{ color: "red" }}>{missedText}</p>}
  </div>
);

export const InputText = ({
  placeholder,
  callback,
  missed,
  missedText,
  secureText,
  onKeyDown,
}) => (
  <div className="input-text-wrapper">
    <input
      className="input-text"
      type={secureText ? "password" : "text"}
      placeholder={placeholder}
      onChange={callback}
      onKeyDown={onKeyDown != undefined ? onKeyDown : () => {}}
    ></input>
    {missed != null && missed && <p style={{ color: "red" }}>{missedText}</p>}
  </div>
);
