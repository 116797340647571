import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import "./catalogo.scss";
import { ProdCard } from "./widgets/ProdCard";
import Lattina from "../../../../assets/images/lattina.png";
import BrandLogo from "../../../../assets/images/partners/brewdog.png";
import ChevronDown from "../../../../assets/icons/chevron-down.svg";
import Filters from "../../../../assets/icons/filters.svg";
import Container from "../../widgets/Basics/Container";
import Hide from "../../widgets/Basics/Hide";
import Burger from "../../../../assets/icons/burger.svg";
import CloseIcon from "../../../../assets/images/close_grey.png"
import FiltersWidget from "./Filters";
import { Product } from "../../../../models/product";
import Brands from "../Home/Brands";
import InputSelect from "../../widgets/Input/Select";
import DoubleRangeSlider from "../../widgets/Input/DoubleRange";
import RangeSlider from "rn-range-slider";
import Filter from "./Filter";
import { resetFilters } from './../../../../store/actions/product';
import DoubleRange from './widgets/DoubleRange';

function Feed(props: any) {
  const [flagMobileMenu, setFlagMobileMenu] = useState(false);
  const [flagOrdinamentoMobileMenu, setOrdinamentoMobileMenu] = useState(false)
  const [flagPriceFilterMenu, setFlagPriceFilterMenu] = useState(false)
  const [brandsForSelect, setBrandsForSelect] = useState(props["brandsForSelect"])
  const sortFilters = [{key: "name", order: "asc"}, {key: "price", order: "asc"}, {key: "price", order: "desc"}, {key: "promo", order: undefined}]
  var sortFilter = false, sortBirrerie = false, sortStile = false, sortFormat = false, sortPrice = false;
  const [flagFiltersDesktop, setFlagFiltersDesktop] = useState(false);

  const minMax = "";
  var countProd = 0;
  var maxCountForPage = 13;
  const [page, setPage] = useState(1);
    // add loader refrence 
  const loader = useRef<any>(null);

  const handleOrder = (e : any) => {
    if(e.target.value != -1) setSortFilter(e.target.value)
  }

  const handleNation = (e: any) => {
    props["setFilter"]("nation", e.target.value, true);
    sortFilter = true;
  }

  const handleBrand = (e: any) => {
    props["setFilter"]("brand", e.target.value, true);
    sortBirrerie = true;
  }

  const handleStyle = (e: any) => {
    props["setFilter"]("style", e.target.value, true);
    sortStile = true;
  }

  const handleFormat = (e: any) => {
    props["setFilter"]("size", e.target.value, true);
    sortFormat = true;
  }

  function setSortFilter(value : any) {
    const filter = sortFilters[parseInt(value)];
    props["setSortF"](filter.key, filter.order)
    setOrdinamentoMobileMenu(!flagOrdinamentoMobileMenu)
    sortPrice = true;
  }

  function applyFilters() {
    setFlagMobileMenu(!flagMobileMenu)
  }

  function handleResetFiltersDesktop() {
    props["resetFilters"]()
  }

  async function resetFilters() {
    props["resetFilters"]()
    setFlagMobileMenu(!flagMobileMenu)
  }

  useEffect(() => {
    var options = {
       root: null,
       rootMargin: "20px",
       threshold: 1.0
    };
   // initialize IntersectionObserver
   // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
       observer.observe(loader.current)
    }

    if (props["brandsForSelect"] != undefined) {
      setBrandsForSelect(props["brandsForSelect"]);
      brandsForSelect.sort(compare);
    }
  }, []);

  const handleObserver = (entities : any) => {
    console.log(page)
    const target = entities[0];
    if (target.isIntersecting) {   
        setPage((page) => page + 1)
    }
  }

  function compare( a : any, b : any ) {
    if ( a.label < b.label ){
      return -1;
    }
    if ( a.label > b.label ){
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Hide forMobile={false}>
        <div className="nav">
          <Container>
            {props["category"] != undefined && props["category"] != "" && (<h3><a href="/shop" style={{color: "black", textDecoration: "none"}}>Home</a> / <a href="/shop" style={{color: "black", textDecoration: "none"}}>{props.category}</a></h3>)}
          </Container>
        </div>
        <div className="desktop-filters">
          <Container>
            <ul>
              <li>
                <InputSelect placeHolder="Ordina Per" callback={handleOrder}>
                  <option value={-1}>Ordina Per</option>
                  <option key={"select-alp"} value={0}>
                    Dalla A alla Z
                  </option>
                  <option key={"select-prez-asc"} value={1}>
                    Prezzo crescente
                  </option>
                  <option key={"select-prez-desc"} value={2}>
                    Prezzo decrescente
                  </option>
                </InputSelect>
              </li>
              <li>
                <InputSelect placeHolder={'Brands'} callback={handleBrand}>
                  <option value="">Birreria</option>
                  {props != null &&
                    brandsForSelect != undefined &&
                    brandsForSelect.map((item: any) => {
                      return (
                        <option
                          key={"select" + item.label}
                          value={"" + item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                </InputSelect>
              </li>
              <li>
                <InputSelect placeHolder='Stili' callback={handleStyle}>
                  <option value="">Stile</option>
                  {props != null &&
                    props["styles"] != undefined &&
                    props["styles"].map((item: any) => {
                      return (
                        <option
                          key={"select" + item.label}
                          value={"" + item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                </InputSelect>
              </li>
              <li>
                <InputSelect placeHolder={'Dimensioni'} callback={handleFormat}>
                  <option value="">Formato</option>
                  {props != null &&
                    props["sizes"] != undefined &&
                    props["sizes"].map((item: any) => {
                      if (item.label == "25cl" || item.label == "33cl" || item.label == "37.5cl" || item.label == "44cl" || item.label == "50cl")
                      return (
                        <option
                          key={"select" + item.label}
                          value={"" + item.value}
                        >
                          {item.label}
                        </option>
                      );
                      else return <></>;
                    })}
                </InputSelect>
              </li>
              <li>
                <div onClick={() => setFlagPriceFilterMenu(!flagPriceFilterMenu)}>
                  <div style={{pointerEvents: "none"}}>
                    <InputSelect placeHolder="Prezzo" callback={() => { }} >
                      <option>{minMax == "" ? "Prezzo" : minMax}</option>
                    </InputSelect>
                  </div>
                </div>
                <div className={"shop-profile-menu"} style={{ display: flagPriceFilterMenu ? 'block' : 'none' }}>
                  <div className="list-profile-menu">
                    <div><DoubleRange forMobile={false}></DoubleRange></div>
                  </div>
                </div>
              </li>
            </ul>
            <h5 onClick={() => handleResetFiltersDesktop()} style={{marginTop: '16px', fontSize: '16px', cursor: 'pointer'}}>Resetta Filtri</h5>
          </Container>
        </div>
      </Hide>
      <div className="shop feed">
        <Container>
          <div className="shop main">
            <Hide forMobile={true}>
              <p className="shop index-page">
                <a href="/shop">Home</a> / <a href="/catalogo">{props.category}</a>
              </p>
              <div className="shop flex-h-space-btw">
                <div className="flex-h-center" onClick={() => setOrdinamentoMobileMenu(!flagOrdinamentoMobileMenu)} style={{cursor: "pointer"}}>
                  <p>Ordina per</p>
                  <img className="label-icon-chevron" src={ChevronDown} />
                </div>

                <div
                  className="flex-h-center"
                  onClick={() => setFlagMobileMenu(!flagMobileMenu)}
                >
                  <p>Filtri</p>
                  <img className="label-icon-filters" src={Filters} />
                </div>
              </div>
              <div className="ordine-per-menu" style={{display: flagOrdinamentoMobileMenu ? "block" : "none"}}>
                <ul>
                  <li>
                    <p onClick={() => setSortFilter(0)}>Dalla A alla Z</p>
                  </li>
                  <li>
                    <p onClick={() => setSortFilter(1)}>Prezzo crescente</p>
                  </li>
                  <li>
                    <p onClick={() => setSortFilter(2)}>Prezzo decrescente</p>
                  </li>
                </ul>
              </div>
            </Hide>
            <Hide forMobile={true}>
              <div className="shop feed-filters"></div>
            </Hide>
          </div>
          <p className="counter-prod-list">
            {props != undefined ? Object.keys(props["products"]).length : "0"}{" "}
            prodotti trovati
          </p>
          <div className="products-container">
            {props != null &&
              props["products"].map((item: any) => {
                const image = item.image;
                if (image != undefined) {
                  var brand = "";
                  var brandLogo = "";
                  var brandNation = "";
                  props["brands"] != undefined &&
                    props["brands"].map((element: any) => {
                      if (element.id == item.brand) {
                        brand = element.name;
                        brandLogo = element.image;
                        brandNation = element.nation;
                      }
                    });
                  const title = item.name;
                  const format = "";
                  const pz = 0;
                  const types = item["types"];
                  const price = types[0].price;
                  const oldPrice = types[0].oldPrice;
                  const discountPercentage = types[0].discountPercentage;
                  const quantity = 0;
                  const packs = props["packs"];
                  const pack = packs[types[0].pack]
                  const stock = types[0].availablePieces;
                  const newFlag = item["new"];

                  if (price!= undefined && pack != undefined && format != undefined && pz != undefined && types != undefined)
                  if ((countProd < (maxCountForPage * page) - 1)) {
                  countProd++;
                    return (
                      <ProdCard
                        image={image}
                        brandLogo={brandLogo}
                        title={title}
                        brand={brand}
                        format={format}
                        pz={pz}
                        price={price}
                        quantity={quantity}
                        isFavorite={false}
                        stock={stock}
                        new={newFlag}
                        oldPrice={oldPrice}
                        discountPercentage={discountPercentage}
                        key={"prodCard"+title}
                      ></ProdCard>
                    );
                  } else {
                    if (countProd < (maxCountForPage * page)) {
                      countProd++;
                      return (
                        <div key={"loadingDiv"} ref={loader}>
                          <p style={{visibility: "hidden"}}>Loader</p>
                        </div>
                      )
                    }
                  }
                } else {
                  return <></>;
                }
              })}
          </div>
        </Container>
      </div>
      <Hide forMobile={true}>
        <div
          className={
            flagMobileMenu
              ? "shop feed-mobile-filters"
              : "shop feed-mobile-filters hide-mobile-menu"
          }
        >
          <div className="header">
            <h2>Filtri</h2>
            <img
              onClick={() => setFlagMobileMenu(!flagMobileMenu)}
              className="icon"
              src={CloseIcon}
              alt="Burger Icon"
            />
          </div>
          <ul className="filters_list">
            <li className="filters_li">
              <Filter name={"Birrerie"} i={1}>
                <InputSelect placeHolder={'Brands'} callback={handleBrand}>
                  {props != null &&
                    brandsForSelect != undefined &&
                    brandsForSelect.map((item: any) => {
                      return (
                        <option
                          key={"selectMobileBirrerie" + item.label}
                          value={"" + item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                </InputSelect>
              </Filter>
            </li>
            <li className="filters_li">
              <Filter name={"Stili"} i={2}>
                <InputSelect placeHolder='Stili' callback={handleStyle}>
                  {props != null &&
                    props["styles"] != undefined &&
                    props["styles"].map((item: any) => {
                      return (
                        <option
                          key={"selectMobileStili" + item.label}
                          value={"" + item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                </InputSelect>
              </Filter>
            </li>
            <li className="filters_li">
              <Filter name={"Dimensioni"} i={3}>
                <InputSelect placeHolder={'Dimensioni'} callback={handleFormat}>
                  {props != null &&
                    props["sizes"] != undefined &&
                    props["sizes"].map((item: any) => {
                      if (item.label == "0.25l" || item.label == "0.33l" || item.label == "0.375l" || item.label == "0.44l" || item.label == "0.5l")
                      return (
                        <option
                          key={"selectMobileSizes" + item.label}
                          value={"" + item.value}
                        >
                          {item.label}
                        </option>
                      );
                      return <></>;
                    })}
                </InputSelect>
              </Filter>
            </li>
            <li className="filters_li">
              <Filter name={"Prezzo"} i={4}>
                <div style={{width: "100%", paddingTop: "16px", paddingLeft: "16px", paddingRight: "16px"}}><DoubleRange forMobile={true}></DoubleRange></div>
              </Filter>
            </li>
          </ul>
          <div className="filterFlex">
            <a
              className="apply_filters outlined"
              onClick={() => resetFilters()}
            >
              Cancella
            </a>
            <a
              className="apply_filters"
              onClick={() => applyFilters()}
            >
              Applica
            </a>
          </div>
        </div>
      </Hide>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    products: state.product.searchedProducts,
    brands: state.brand.brands,
    brandsForSelect: state.domain.brand,
    nations: state.domain.nation,
    styles: state.domain.style,
    sizes: state.domain.size,
    packs: state.domain.packMap,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilter: (key: string, value: string) => {
      dispatch(actions.setFilter(key, value, true));
    },
    resetFilters: () => {
      dispatch(actions.resetFilters());
    },
    setSortF: (sortKey : any, order : any) => {
      dispatch(actions.setSort(sortKey, order))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
