import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { firebaseConfig, reducers } from "./config";
import { initInterceptors } from "./hoc/axios/axios";
import Firebase, { FirebaseContext } from "./hoc/firebase";
import { initStore } from "./hoc/redux";
import ScrollTop from "./hoc/ScrollTop";
import "./i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { endHttpCall, startHttpCall, unsetAuthToken } from "./store/actions";

const store = initStore(reducers);

function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.error(e);
  }
}

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

initInterceptors(store, unsetAuthToken);

const startHttp = () => store.dispatch(startHttpCall() as never);
const endHttp = () => store.dispatch(endHttpCall() as never);

const app = (
  <Provider store={store}>
    <FirebaseContext.Provider
      value={new Firebase({ ...firebaseConfig, startHttp, endHttp })}
    >
      <React.StrictMode>
        <BrowserRouter>
          <ScrollTop />
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </FirebaseContext.Provider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
