import React, { Component } from "react";
import Container from "../../widgets/Basics/Container";
import './catalogo.scss';

type Props = {
    image: string,
    headline: string,
    subheadline: string,
  }

const Header = ({ image, headline, subheadline} : Props) => {
    if (image == undefined || image == "") return <div style={{paddingTop: "48px"}}></div>
    
    return <div className="shop catalogo-header" style={{backgroundImage: 'url('+image+')'}}>
        <div className="catalogo-header-shadow"></div>
        <Container>
            <div className="details">
                <h1 className="headline">{headline}</h1>
                <p className="subheadline">{subheadline}</p>
            </div>
        </Container>
    </div>;
}

export default Header;
