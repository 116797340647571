import * as actionTypes from "./actionTypes";
import axios from "axios";

import { startHttpCall, endHttpCall } from "./utils";

export const saveAllBrands = (brands: any) => {
  return {
    type: actionTypes.SAVE_ALL_BRANDS,
    brands: brands,
  };
};

export const getAllBrands = () => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/brand/all`;
    axios
      .get(url)
      .then((resp) => {
        dispatch(saveAllBrands(resp.data));
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const newBrand = (brand: any, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/brand/new`;
    axios
      .put(url, brand)
      .then((_) => {
        dispatch(getAllBrands());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const modifyBrand = (brand: any, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/brand/modify`;
    axios
      .post(url, brand)
      .then((_) => {
        dispatch(getAllBrands());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const deleteBrand = (id: any, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/brand/${id}`;
    axios
      .delete(url)
      .then((_) => {
        dispatch(getAllBrands());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};
