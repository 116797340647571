export function scrollTo(sectionName: string) {
  var elmnt = document.getElementById(sectionName);
  if (elmnt) {
    elmnt.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
