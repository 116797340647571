import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Container from '../../widgets/Basics/Container';
import Body from './Body';
import Sidebar from './Sidebar'
import './profile.scss'
import NavBar from '../../widgets/NavBar/NavBar';
import Footer from '../../widgets/Footer/Footer';

const Profile = () => {
    const [index, setIndex] = useState('dashboard');
    const [body, setBody] = useState(null);
    const location = useLocation();

    useEffect(() => {
        var params = location.pathname.split("/");

        try {
            var tempIndex = params[2]
            setIndex(tempIndex)
        } catch (e) {
            setIndex('dashboard')
        }
    },[ ])

    return (
        <div className="shop profilePage">
            <NavBar></NavBar>
            <Container>
                <div className="flex">
                    <Sidebar index={index}></Sidebar>
                    <Body index={index}></Body>
                </div>
            </Container>
            <Footer></Footer>
        </div>
    )
}

export default Profile