import React, {useState} from "react"
import { InputText } from "../../widgets/Input/Text"
import Cart from "./Cart"
import './checkout.scss';

const RightColumn = ({costs, spedition, eventCoupon} : any) => {

    return (
        <div className="rightCol">
            <Cart costSpedition={spedition} additionalCosts={costs} eventCoupon={eventCoupon}></Cart>
        </div>
    )
}

export default RightColumn