import axios from "axios";
import { Product } from "./../../models/product";
import * as actionTypes from "./actionTypes";
import { endHttpCall, startHttpCall } from "./utils";

export const saveAllProducts = (products: Product[]) => {
  return {
    type: actionTypes.SAVE_ALL_PRODUCTS,
    products: products,
  };
};

export const getAllProducts = () => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/product/all`;
    axios
      .get(url)
      .then((resp: { data: Product[] }) => {
        dispatch(saveAllProducts(resp.data));
        dispatch(searchCalculate());
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const newProduct = (product: Product, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/product/new`;
    axios
      .put(url, product)
      .then((_) => {
        dispatch(getAllProducts());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const modifyProduct = (product: Product, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/product/modify`;
    axios
      .post(url, product)
      .then((_) => {
        dispatch(getAllProducts());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const deleteProduct = (id: string, onSuccess: any) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/product/${id}`;
    axios
      .delete(url)
      .then((_) => {
        dispatch(getAllProducts());
        onSuccess();
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};

export const setFilterValue = (fieldKey: any, value: any) => {
  return {
    type: actionTypes.SET_FILTER,
    fieldKey: fieldKey,
    value: value,
  };
};

export const searchCalculate = () => {
  return {
    type: actionTypes.SEARCH_CALCULATE,
  };
};

// setta il filtro in memoria e se è chiamato con true terzo parametro
// calcola anche i risultati di ricerca
export const setFilter = (
  fieldKey: any,
  value: any,
  withSearchCalculate: any
) => {
  return (dispatch: any) => {
    dispatch(setFilterValue(fieldKey, value));
    if (withSearchCalculate) {
      dispatch(searchCalculate());
    }
  };
};

export const resetFilters = () => {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.RESET_FILTERS });
    dispatch(searchCalculate());
  };
};

export const setSort = (sortKey: "price" | "name", order?: "asc" | "desc") => {
  return {
    type: actionTypes.SET_SORT,
    fieldKey: sortKey,
    value: order,
  };
};
