import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions";
import DeleteIcon from "../../../../../assets/images/cancel_button.png"
import Counter from "../../Prods/Counter";
import '../navbar.scss'
import './item.scss'
import { Product } from "../../../../../models/product";
import { Format } from './../../../../../models/product';

const Item = ({ prod, id, image, title, brand, format, pz, price, quantity, type, changeChartProduct, onChangeCount, onResetCount, increaseProduct, decreaseProduct }: any) => {
    const [isChanged, setIsChanged] = useState(false);
    const [count, setFCount] = useState(quantity)
    const [isDeleted, setIsDeleted] = useState(false)

    useEffect(() => {
        setFCount(quantity)
        onResetCount(price * quantity)
    }, [quantity])

    function setCount(x : number) {
        if (count == -1) setFCount(quantity)
        onChangeCount(price * count, price * x, count, x)
        setFCount(x);
        if (x == count+1) {
            increaseProduct(prod, type)
        } else {
            decreaseProduct(prod, type)
        }
    }

    function deleteElement() {
        changeChartProduct(prod, type, -count)
        setIsDeleted(true)
        setFCount(-1);
    }

    return (
        <div className="shop shop-item-in-cart" style={{display: count < 0 ? 'none' : 'block'}}>
            <div className="shop shop-v-middle">
                <img className="shop cart-prod-image" src={image}></img>
                <div className="shop cart-prod-details">
                    <div className="shop shop-row-space-between">
                        <h3 className="shop cart-prod-details-title">{title}</h3>
                        <img className="delete-icon" src={DeleteIcon} onClick={() => deleteElement()} />
                    </div>
                    <p className="shop cart-prod-details-brand">{brand}</p>
                    <div className="shop shop-v-middle">
                        <div className="shop shop-row-space-between">
                            <Counter forCart={true} inizialValue={count} onChange={setCount} max={prod.types[0].availablePieces}></Counter>
                            <p className="shop cart-prod-details-price">€ {(price * count).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

const mapStateToProps = (state: any) => {
    return {
      brands: state.domain.brandMap,
      sizes: state.domain.sizeMap,
      packs: state.domain.packMap,
      styles: state.domain.style,
      isLogged: state.utils.isLogged,
      cart: state.shop.chart,
      prods: state.product.products,
    }
  }
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      setFilter: (key: string, value: string) => {
        dispatch(actions.setFilter(key, value, true));
      },
      changeChartProduct: (prod: Product, format: Format, count: number) => {
        dispatch(actions.changeChartProductAmount(prod, format, count))
      },
      increaseProduct: (prod: Product, format: Format, count: number) => {
        dispatch(actions.increaseProductAmount(prod, format))
      },
      decreaseProduct: (prod: Product, format: Format, count: number) => {
        dispatch(actions.decreaseProductAmount(prod, format))
      }
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Item);
