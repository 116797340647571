import { Coupon } from "../../models/product";
import * as actionTypes from "../actions/actionTypes";

const initialState: { coupons: Coupon[] } = {
  coupons: [],
};

const reducer = (
  state = initialState,
  action: { type: string; coupons: any[] }
) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_COUPON:
      const coupons: Coupon[] = [];
      action.coupons
        .sort((b1, b2) => (b1.name > b2.name ? 1 : -1))
        .forEach((brand: Coupon) => {
          coupons.push(brand);
        });
      return {
        ...state,
        coupons: coupons,
      };
    default:
      return { ...state };
  }
};

export default reducer;
