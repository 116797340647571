import React, { useEffect, useState } from "react";
import Counter from "../../../widgets/Prods/Counter";
import { useHistory } from "react-router";
import * as actions from "../../../../../store/actions";
import { connect } from "react-redux";
import './prod.scss';

import Cart from '../../../../../assets/icons/cart_white.svg';
import CuoreFilled from '../../../../../assets/images/cuoreFilled.png';
import CuoreOutlined from '../../../../../assets/images/cuoreOutlined.png';

type ItemProps = {
    image: string,
    title: string,
    brand: string,
    brandLogo: string,
    format: string,
    pz: number,
    price: number,
    quantity: number,
    isFavorite: boolean,
    stock: number,
}

function ProdCardW(props : any) {
    const [isFav, setIsFav] = useState(false);

    const setFav = () => {
        setIsFav(!isFav);
    }

    useEffect(() => {
        setIsFav(props["isFavorite"])
    }, [])

    const history = useHistory();

    const link = ({ data, e }: any) => {
        go();
    };

    async function go() {
        history.push({
            pathname: "/prod/"+props["title"],
            state: {
                categoria: 'alpaca',
                title: 'Tutte le birre',
                description: 'Una selezione di oltre 800 prodotti, cerca e ordina quello che più ti piace',
                image: 'https://source.unsplash.com/random',
            },
        });
    }

    async function addToCart() {
        history.push({
            pathname: "/",
            state: {
            },
        });
        history.push({
            pathname: "/prod/"+props["title"],
            state: {
                categoria: 'alpaca',
                title: 'Tutte le birre',
                description: 'Una selezione di oltre 800 prodotti, cerca e ordina quello che più ti piace',
                image: 'https://source.unsplash.com/random',
            },
        });
    }

    async function goToAuth() {
        history.push({
            pathname: "/prod/"+props["title"],
            state: {
                categoria: 'alpaca',
                title: 'Tutte le birre',
                description: 'Una selezione di oltre 800 prodotti, cerca e ordina quello che più ti piace',
                image: 'https://source.unsplash.com/random',
            },
        });
    }

    return <div className="shop catalogo-item">
        <div className="shop wrapper" onClick={link} style={{cursor: "pointer", position: 'relative'}}>
            
            <div className="shop catalogo-item catalogo-header">
                <div className="flex-prod-image" style={{zIndex: 10}}>
                    <img loading="lazy" className="prodImage" src={props["image"]} />
                </div>
                <div className="flex-h-space-btw" style={{position: "relative", zIndex: 100}}>
                    <img className="flex-icon" src={props["brandLogo"]} />
                    { props["new"] == true && ( <div className="flex-icon new"><p>New</p></div> ) }
                    <img onClick={() => setFav()} className="flex-icon" style={{boxSizing: 'border-box', padding: 4, cursor: 'pointer', display: 'none'}} src={isFav ? CuoreFilled : CuoreOutlined} />
                </div>
            </div>
            <p className="brand-label">&nbsp;{props["brand"] != undefined ? props["brand"] : "&nbsp;"}&nbsp;</p>
            <h3 className="name-label">{props["title"]}</h3>
            <p className="price-label last old" style={{display: props["oldPrice"] != undefined ? "block" : "none"}}><span className="line">€{props["oldPrice"] != undefined ? props["oldPrice"].toFixed(2) : ""}</span> -{props["discountPercentage"] != undefined ? props["discountPercentage"].toFixed(0) : ""}%</p>
            <p className="price-label last" style={{visibility: props["stock"] == -1 ? "hidden" : "visible"}}>€{props["price"] != undefined ? props["price"].toFixed(2) : ""}</p>
            <p className="price-label-taxes" style={{display: "none"}}>Iva esclusa</p>
            <a onClick={props["stock"] > 0 ? () => addToCart() : () => {}} className="prod-add-to-cart">
                <p>{props["stock"] > 0 ? "Aggiungi" : props["stock"] == -1 ? "Soon" : "Sold Out"}</p>
                { props["stock"] > 0 && (<img className="prod-add-to-cart-icon" src={Cart} />)}
            </a>
        </div>
    </div>;
}

const mapStateToProps = (state: any) => {
    return {
      isLogged: state.utils.isLogged,
    }
  }

const mapDispatchToProps = (dispatch: any) => {
    return {
      setFilter: (key: string, value: string) => {
        dispatch(actions.setFilter(key, value, true));
      },
    };
  };
  
export const ProdCard = connect(mapStateToProps, mapDispatchToProps)(ProdCardW);
