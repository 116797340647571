import { Product, Format, Chart, Spedition } from "./../../models/product";
import * as actionTypes from "../actions/actionTypes";

const initialState: {
  chart: Chart;
  spedition: Spedition;
  orders: any[];
} = {
  chart: {},
  spedition: {},
  orders: [],
};

const reducer = (
  state = initialState,
  action: {
    type: string;
    product: Product;
    format: Format;
    amount: number;
    orders: any[];
    spedition: Spedition;
  }
) => {
  switch (action.type) {
    case actionTypes.CHANGE_CHART_PRODUCT_AMOUNT:
      let numbers = 0;
      if (
        action.product &&
        action.product.id &&
        action.format &&
        action.format.size &&
        action.format.pack &&
        action.amount
      ) {
        if (
          state.chart[action.product.id] &&
          state.chart[action.product.id][action.format.size] &&
          state.chart[action.product.id][action.format.size][action.format.pack]
        ) {
          const el =
            state.chart[action.product.id][action.format.size][
              action.format.pack
            ];

          numbers =
            el || el === 0
              ? state.chart[action.product.id][action.format.size][
                  action.format.pack
                ] + action.amount
              : action.amount;
        } else {
          numbers = action.amount;
        }
        return {
          ...state,
          chart: {
            ...state.chart,
            [action.product.id]: {
              ...state.chart[action.product.id],
              [action.format.size]: {
                [action.format.pack]: numbers,
              },
            },
          },
        };
      } else {
        return state;
      }
    case actionTypes.CHART_RESET:
      return {
        ...state,
        chart: {},
        spedition: {},
      };
    case actionTypes.SAVE_ALL_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    case actionTypes.SET_SPEDITION_DATA:
      return { ...state, spedition: action.spedition };
    default:
      return { ...state };
  }
};

export default reducer;
