import React from 'react'
import Dashboard from './subpages/Dashboard'
import IndirizzoFatturazione from './subpages/IndirizzoFatturazione'
import IndirizzoSpedizione from './subpages/IndirizzoSpedizione'
import Logout from './subpages/Logout'
import Ordini from './subpages/Ordini'
import Wishlist from './subpages/Wishlist'

const Body = ({index} : any) => {
    if (index == "dashboard")
        return ( <Dashboard></Dashboard> )
    if (index == "fatturazione")
        return ( <IndirizzoFatturazione></IndirizzoFatturazione> )
    if (index == "spedizione")
        return ( <IndirizzoSpedizione></IndirizzoSpedizione> )
    if (index == "ordini")
        return ( <Ordini></Ordini> )
    if (index == "wishlist")
        return ( <Wishlist></Wishlist> )
    if (index == "logout")
        return ( <Logout></Logout> )

    return ( <Dashboard></Dashboard> )
}

export default Body