import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "../../widgets/Basics/Container";
import './all_brands.scss'
import * as actions from "../../../../store/actions";
import { useHistory } from "react-router";

const Body = (props : any) => {
    var first = '';
    const history = useHistory();

    async function go(item : any) {
        props["setFilter"]("brand", item.id, true);
        history.push({
          pathname: "/catalogo",
          state: {},
        });
      }

    return <Container>
        <div className="listOfBrandsByName">
        {props != undefined && props["brands"] != null && props["brands"].map((element: any) => {
            if (element.name[0] != first) {
                first = element.name[0]
                return <>
                    <h3>{element.name[0]}</h3>
                    <p onClick={() => go(element)}>{element.name}</p>
                    <div className="divider"></div>
                </>
            } else
            return <>
                <p onClick={() => go(element)}>{element.name}</p>
                <div className="divider"></div>
            </>
        })}
        </div>
    </Container>
}

const mapStateToProps = (state: any) => {
    return {
      brands: state.brand.brands,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      setFilter: (key: string, value: string) => {
        dispatch(actions.setFilter(key, value, true));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Body);
