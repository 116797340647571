import React, { Component } from "react";
import { InputType } from "../../models/rest";
import "./GenericInput.scss";

type InputProps = {
  handler: any;
  name: string;
  value: any;
  type: InputType;
  label: string;
  choices?: { label: string; value: string }[];
  childChoices?: { [key: string]: { label: string; value: string }[] };
  required?: boolean;
  formInvalid?: boolean;
  placeholder?: string;
  autoComplete?: string;
  emptyLabel?: string;
  fileName?: string;
  previousValue?: any;
  icon?: string;
  hideLabel?: boolean;
  childs?: any; // campo usato dagli input di tipo array per passargli i figli tra cui ciclare
};

class GenericInput extends Component<InputProps> {
  state = {
    touched: false,
    blurred: false,
    focus: false,
  };
  // manage the click to remove radio selection
  radioClick = (event: any, name: any, value: any) => {
    if (event.target.value === value) {
      event.target.value = "";
      this.props.handler(event, name);
    }
  };

  textChange = (event: any) => {
    if (!this.state.touched) this.setState({ touched: true });
    this.props.handler(event, this.props.name);
  };

  blurHandler = () => {
    this.setState({ blurred: true, focus: false });
  };

  focusHandler = () => {
    this.setState({ focus: true });
  };

  render() {
    let field = null;
    const inputCss = ["form-control"];
    if (this.state.touched) {
      inputCss.push("touched");
    }
    if (this.state.blurred) {
      inputCss.push("blurred");
    }
    // variabile che rappresenta se bisogna visualizzare l'errore
    const viewError =
      this.props.required &&
      !this.props.value &&
      (this.state.touched || this.props.formInvalid);
    if (viewError) {
      inputCss.push("invalid");
    }
    switch (this.props.type) {
      case "text":
        field = (
          <input
            placeholder={this.props.placeholder}
            className={inputCss.join(" ")}
            type={this.props.type}
            value={this.props.value}
            onChange={this.textChange}
            required={this.props.required}
            autoComplete={this.props.autoComplete}
            onBlur={this.blurHandler}
            onFocus={this.focusHandler}
          />
        );
        break;
      case "email":
        field = (
          <input
            placeholder={this.props.placeholder}
            className={inputCss.join(" ")}
            type={this.props.type}
            value={this.props.value}
            onChange={this.textChange}
            required={this.props.required}
            autoComplete={this.props.autoComplete}
            onBlur={this.blurHandler}
          />
        );
        break;
      case "number":
        field = (
          <input
            placeholder={this.props.placeholder}
            className="form-control"
            type={this.props.type}
            value={this.props.value}
            onChange={(event) => this.props.handler(event, this.props.name)}
          />
        );
        break;
      case "note":
        field = (
          <textarea
            className={inputCss.join(" ")}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.textChange}
            onBlur={this.blurHandler}
            required={this.props.required}
          />
        );
        break;
      case "radio":
        field = (
          <React.Fragment>
            {this.props.choices
              ? this.props.choices.map((choice: any) => {
                  let choiceCss = ["form-check", "text-left"];
                  if (
                    this.props.required &&
                    this.props.choices &&
                    this.props.choices.length === 1 &&
                    this.props.value !== choice.value &&
                    (this.state.touched || this.props.formInvalid)
                  ) {
                    choiceCss.push("required");
                  }
                  return (
                    <div key={choice.value} className={choiceCss.join(" ")}>
                      <input
                        type="radio"
                        name={this.props.label}
                        id={this.props.name + choice.value}
                        value={choice.value}
                        checked={this.props.value === choice.value}
                        onChange={(event) =>
                          this.props.handler(event, this.props.name)
                        }
                        className="styled-radio"
                        required={this.props.required}
                        onClick={(event) =>
                          this.radioClick(
                            event,
                            this.props.name,
                            this.props.value
                          )
                        }
                      />
                      <label
                        htmlFor={this.props.name + choice.value}
                        id={"label" + this.props.name + choice.value}
                        className="label"
                      >
                        {choice.label}
                        {choice.spanText ? (
                          <span onClick={choice.spanClick}>
                            {choice.spanText}
                          </span>
                        ) : null}
                      </label>
                    </div>
                  );
                })
              : null}
          </React.Fragment>
        );
        break;
      case "checkbox":
        field = this.props.choices ? (
          <div className="choicesContainer">
            {this.props.choices.map((choice: any) => {
              return (
                <div key={choice.value} className="d-flex ml-3 form-check">
                  <input
                    id={choice.value}
                    className="styled-checkbox"
                    type="checkbox"
                    value={choice.value}
                    name={choice.value}
                    checked={this.props.value.indexOf(choice.value) >= 0}
                    onChange={(event) =>
                      this.props.handler(event, this.props.name)
                    }
                  />
                  <label className="form-check-label" htmlFor={choice.value}>
                    {choice.label}
                  </label>
                </div>
              );
            })}
          </div>
        ) : null;
        break;
      case "select":
        field = (
          <div className="d-flex">
            <select
              className="form-control"
              value={this.props.value}
              onChange={(event) => this.props.handler(event, this.props.name)}
            >
              {this.props.emptyLabel ? (
                <option key="empty" value="">
                  {this.props.emptyLabel}
                </option>
              ) : null}
              {this.props.choices
                ? this.props.choices.map(
                    (choice: { value: string; label: string }) => {
                      return (
                        <option key={choice.value} value={choice.value}>
                          {choice.label}
                        </option>
                      );
                    }
                  )
                : null}
            </select>
          </div>
        );
        break;
      case "file":
        const style: any = {};
        if (this.props.fileName === "Immagine già caricata") {
          style.color = "red";
        }
        field = (
          <div className="d-flex">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                onChange={(event) => this.props.handler(event, this.props.name)}
              />
              <label
                className="custom-file-label"
                htmlFor="inputFileId"
                style={style}
              >
                {this.props.fileName
                  ? this.props.fileName
                  : "Selezionare un file"}
              </label>
            </div>
            {this.props.previousValue ? (
              <img src={this.props.previousValue} alt="old firebase file" />
            ) : null}
          </div>
        );
        break;
      case "password":
        field = (
          <input
            placeholder={this.props.placeholder}
            className="form-control"
            type={this.props.type}
            value={this.props.value}
            onChange={(event) => this.props.handler(event, this.props.name)}
            required={this.props.required}
            autoComplete="current-password"
          />
        );
        break;
      case "none":
        field = <p>{this.props.value}</p>;
        break;
      case "array":
        field = this.props.value.map((_: any, index: number) => {
          return (
            <React.Fragment key={this.props.name + index}>
              <div className="arrayRow">
                {Object.keys(this.props.childs).map((child) => {
                  let ret;
                  switch (this.props.childs[child].inputType) {
                    case "select":
                      ret = (
                        <div className="d-flex">
                          <select
                            className="form-control"
                            value={this.props.value[index][child]}
                            onChange={(event) =>
                              this.props.handler(
                                event,
                                this.props.name,
                                index,
                                child
                              )
                            }
                          >
                            {this.props.emptyLabel ? (
                              <option key="empty" value="">
                                {this.props.emptyLabel}
                              </option>
                            ) : null}
                            {this.props.childChoices &&
                            this.props.childChoices[child]
                              ? this.props.childChoices[child].map(
                                  (choice: {
                                    value: string;
                                    label: string;
                                  }) => {
                                    return (
                                      <option
                                        key={choice.value}
                                        value={choice.value}
                                      >
                                        {choice.label}
                                      </option>
                                    );
                                  }
                                )
                              : null}
                          </select>
                        </div>
                      );
                      break;
                    case "radio":
                      ret = (
                        <React.Fragment>
                          {this.props.choices
                            ? this.props.choices.map((choice: any) => {
                                let choiceCss = ["form-check", "text-left"];
                                return (
                                  <div
                                    key={choice.value}
                                    className={choiceCss.join(" ")}
                                  >
                                    <input
                                      type="radio"
                                      name={this.props.label}
                                      id={this.props.name + choice.value}
                                      value={choice.value}
                                      checked={
                                        this.props.value[index][child] ===
                                        choice.value
                                      }
                                      onChange={(event) =>
                                        this.props.handler(
                                          event,
                                          this.props.name,
                                          index,
                                          child
                                        )
                                      }
                                      className="styled-radio"
                                      required={this.props.required}
                                    />
                                    <label
                                      htmlFor={this.props.name + choice.value}
                                      id={
                                        "label" + this.props.name + choice.value
                                      }
                                      className="label"
                                    >
                                      {choice.label}
                                      {choice.spanText ? (
                                        <span onClick={choice.spanClick}>
                                          {choice.spanText}
                                        </span>
                                      ) : null}
                                    </label>
                                  </div>
                                );
                              })
                            : null}
                        </React.Fragment>
                      );
                      break;
                    default:
                      ret = (
                        <input
                          key={child}
                          placeholder={this.props.childs[child].placeholder}
                          className="form-control"
                          type={this.props.childs[child].inputType}
                          value={this.props.value[index][child]}
                          onChange={(event) =>
                            this.props.handler(
                              event,
                              this.props.name,
                              index,
                              child
                            )
                          }
                        />
                      );
                  }
                  return (
                    <div key={child} className="childField">
                      <div className="childLabel">
                        {this.props.childs[child].label}
                      </div>
                      {ret}
                    </div>
                  );
                })}
                <button
                  type="button"
                  onClick={() => {
                    if (index !== 0 || this.props.value.length > 1) {
                      this.props.value.splice(index, 1);
                    }
                  }}
                >
                  CANCELLA
                </button>
              </div>
              <hr />
              {this.props.value.length === index + 1 ? (
                <button
                  type="button"
                  onClick={(event) => {
                    this.props.value.push({});
                  }}
                  style={{ backgroundColor: "#c9cc04", marginBottom: "20px" }}
                >
                  NEW
                </button>
              ) : null}
            </React.Fragment>
          );
        });

        break;
      default:
        break;
    }
    const css = ["GenericInput", "d-flex", "form-group"];
    if (!this.props.icon) {
      css.push("column");
    }
    if (this.props.type === "text") {
      css.push("inputText");
    }
    if (this.props.type === "note") {
      css.push("inputNote");
    }
    if (this.props.type === "email") {
      css.push("inputEmail");
    }
    if (this.props.type === "select") {
      css.push("inputSelect");
    }
    if (this.props.type === "radio") {
      css.push("inputRadio");
    }
    if (this.props.type === "checkbox") {
      css.push("checkboxRadio");
    }
    return (
      <div className={css.join(" ")}>
        {this.props.hideLabel ? null : (
          <div className="labelsRow">
            <label className={viewError ? "label red" : "label"}>
              {this.props.label}
            </label>
            <label className="errorLabel">
              {viewError ? "Questo campo è obbligatorio" : ""}
            </label>
          </div>
        )}
        {this.props.icon ? (
          <button
            className={
              this.state.focus
                ? "btn btn-secondary active"
                : "btn btn-secondary"
            }
          >
            <i className={this.props.icon}></i>
          </button>
        ) : null}
        {field}
      </div>
    );
  }
}

export default GenericInput;
