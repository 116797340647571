import React, { useState } from "react";
import "./input.scss";

const InputSelect = ({ placeHolder, children, callback }) => (
  <div className="input-select-wrapper">
    <select className="input-select" type="select" onChange={callback}>
      {children}
    </select>
  </div>
);

export default InputSelect;
