import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Cart from '../../../../assets/icons/cart_white.svg';
import { Product } from '../../../../models/product';
import * as actions from "../../../../store/actions";
import Counter from '../../widgets/Prods/Counter';
import { Format } from './../../../../models/product';
import './scheda.scss';
import Scheda1 from '../../../../assets/images/scheda/scheda1.png';
import Scheda2 from '../../../../assets/images/scheda/scheda2.png';
import Scheda3 from '../../../../assets/images/scheda/scheda3.png';

const Body = ({prod, brand, pack, style, size, nation, changeChartProduct, me} : any) => {
    const [isAdded, setIsAdded] = useState(false);
    var type : any = prod.types[0];
    var count = 1;

    const nations = {
        "31VMVsSdFOuhJZ37CGpt": "Svezia",
        "3WWSYaKn1F5dbcxzpwwk": "Portogallo",
        "59c8OxDnnYBjxdnbUBJP": "Belgio",
        "8Sv4gL7hHo1htjoqsYYA": "Croazia",
        "9v3JwnvqDR8HCHmxajpi": "Messico",
        "AVQA5LO5kynR9kijCanM": "Olanda",
        "CTrrmkzvKCVpNxqhmvj1": "Austria",
        "Drkqu3PAfw1g5qfseEUQ": "Danimarca",
        "E9PRUB53Vm71To8B4WbO": "Slovenia",
        "FS0q2CJMVahX34hIjN7h": "Lettonia",
        "Fto3mTPsP26riYRdmVbC": "Bulgaria",
        "Hitgmd7LcNumW77kmPgF": "Estonia",
        "LISgtiYYHJIf8ilQK9VE": "Russia",
        "LLt3XYaqGveUszlaQe9I": "Irlanda",
        "Lv69HSpxx2CZFKxRBlS8": "Canada",
        "MZ03saVlWbvuGsG36xHl": "Ungheria",
        "PxVF2AwciXodFxZQWe3u": "Australia",
        "SegtZ4qOddo7IcwXk8KB": "Nuova Zelanda",
        "UvTSnn5syOfWLGkaiTgf": "Svizzera",
        "VHnG0QinDaBQYpI7f3Q3": "Slovacchia",
        "XOFtzef2KFGPiHrV5Bd4": "Grecia",
        "Yg8vJTCXOQ6ZAu6qVSKa": "Cipro",
        "aj2kLxjpWaETgrGKbqtT": "Francia",
        "eXGIYN4K6Ggjdaoz7JlI": "Malta",
        "feHf9R3f0KEmmSwr4Rdg": "Lituania",
        "gI2eHsXZXhwCvHBQnc4c": "Finlandia",
        "l6CFA9NHTQhPnY3s8ENg": "Scozia",
        "oL2ueNTMVr2tLou5R1UU": "Repubblica Ceca",
        "ooBw9i2ZOvpkaRi3PgME": "Polonia",
        "p39qeDj9lFzxs0zXbbUS": "Stati Uniti",
        "q57hwJS1eC1dzQRwSg5B": "Gran Bretagna",
        "qqYJM4rHrpdMu1j5n6XC": "Lussemburgo",
        "uPsTgXeOItSY9EoPvyki": "Germania",
        "uX7OB4yLTBSAGudj0toQ": "Romania",
        "zatBLE22BrJlIkS5o66Q": "Italia"
    }

    function setCount(x : number) {
        count = x;
    }

    async function changeType(tempType : any) {
        type = tempType
    }

    async function addToCart() {
        if (count != 0) {
            setIsAdded(true);
            changeChartProduct(
                prod,
                type,
                count,
            )

            setTimeout(() => {  setIsAdded(false) }, 2000);
        }
    }

    return (
        <div className="body">
            <div className="flex">
                <div className="first">
                    <h3 className="brand">{brand.name}</h3>
                    <h1 className="title">{prod.name}</h1>
                    <p className="text">{prod.desc}</p>
                    <p className="ingredienti"><b>Ingredienti:</b> {prod.ingredients}</p>
                    <div className="end-date" style={{display: 'none'}}>
                        <p className="end-date-title">Da consumarsi entro:</p>
                        <p className="end-date-value"><span>---</span></p>
                    </div>
                    <div className="cars">
                        { prod.style != undefined && style != null && <div className="car-tag">Stile <b>{style[prod.style]}</b></div> }
                        <div className="car-tag">Nazione <b>{nation[brand.nation]}</b></div>
                        <div className="car-tag">ABV <b>{prod.alcol}</b></div>
                        <div className="car-tag">Formato <b>{size[prod.types[0].size]}</b></div>
                    </div>
                </div>
                <div className="second">
                    <h3 className="title-formats" style={{display: "none"}}>Seleziona formato</h3>
                    <div style={{display: "none"}}>
                    {
                        prod.types != undefined && prod.types.map((item : any, i : any) => {
                            if(i==0) type = item
                            return (
                                <div className={i == 0 ? "input-format active" : "input-format"} key={"inputFormat"+i}>
                                    <p onClick={() => changeType(item)}>{size[item.size]}</p>
                                </div>
                            )
                        })
                    }
                    </div>
                    
                    <div className="details">
                    <p className="price old" style={{display: type.oldPrice != undefined ? "block" : "none"}}><span className="line">€{type.oldPrice != undefined ? type.oldPrice.toFixed(2) : ""}</span> -{type.discountPercentage != undefined ? type.discountPercentage.toFixed(0) : ""}%</p>
                        <p className="price" style={{visibility: prod.types[0].availablePieces == -1 ? "hidden" : "visible"}}>€{type.price != undefined ? type.price.toFixed(2) : ""}</p>
                        <p className="taxes" style={{visibility: prod.types[0].availablePieces == -1 ? "hidden" : "visible"}}>{ me != undefined && !me.isPrivate ? "Iva inclusa" : "Iva inclusa"}</p>
                        { ((prod.store != undefined || prod.store != "3BPP9YI5YALquwppPNyy") && prod.types[0].availablePieces != -1) ? (
                            <Counter onChange={setCount} forCart={false} inizialValue={1} max={prod.types[0].availablePieces}></Counter>
                        ) : (
                            <h3>{prod.types[0].availablePieces == -1 ? "" : "Non disponibile al momento"}</h3>
                        ) 
                        }
                        <a className={isAdded ? "prod-add-to-cart active" : "prod-add-to-cart"} onClick={prod.types[0].availablePieces > 0 ? () => addToCart() : () => {}}>
                            <p>{(prod.types[0].availablePieces > 0) ? isAdded ? "Aggiunto" : "Aggiungi" : prod.types[0].availablePieces == -1 ? "Soon" : "Sold out"}</p>
                            { <img className="prod-add-to-cart-icon" src={Cart} style={{display: prod.types[0].availablePieces == -1 ? "none" : "block"}}/> }
                        </a>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '32px'}}>
                                <img style={{width: '40px', height: '40px'}} src={Scheda1} />
                                <p className="notes" style={{textAlign: 'left'}}>Spedizione gratuita!</p>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '0px'}}>
                                <img style={{width: '40px', height: '40px'}} src={Scheda2} />
                                <p className="notes" style={{textAlign: 'left'}}>Consegna in 24/48h</p>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '0px'}}>
                                <img style={{width: '40px', height: '40px'}} src={Scheda3} />
                                <p className="notes" style={{textAlign: 'left'}}>Pagamenti sicuri</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="notes" style={{display: 'none'}}>Please note the beer is <b>unfiltered and unpasteurized</b> and needs to be <b>stored refrigerated between 2 and 8°C!</b></p>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
      pack: state.domain.packMap,
      style: state.domain.styleMap,
      size: state.domain.sizeMap,
      nation: state.domain.nationMap,
      me: state.utils.me,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
      setFilter: (key: string, value: string) => {
        dispatch(actions.setFilter(key, value, true));
      },
      changeChartProduct: (prod: Product, format: Format, count: number) => {
          dispatch(actions.changeChartProductAmount(prod, format, count))
      }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body)