import { Brand } from "../../models/product";
import { ProductRest } from "../../models/rest";
import * as actionTypes from "../actions/actionTypes";
import { Product } from "./../../models/product";

const initialState: {
  products: Product[]; // tutti i prodotti in DB
  searchedProducts: Product[]; // i prodotti filtrati da mostrare all'utente in GUI
  filterValues: any; // valori dei filtri di ricerca
  sortValues: { [value in "price" | "name"]: "asc" | "desc" | undefined }; // valori di ordinamento
  brands: { [id: string]: Brand };
} = {
  products: [],
  filterValues: Object.keys(ProductRest.fields).reduce(
    (map: any, fieldKey) => {
      let value: any;
      const searchType = ProductRest.fields[fieldKey].searchType;
      if (searchType) {
        switch (searchType) {
          case "boolean":
            break;
          case "number":
            break;
          case "string":
            value = "";
            break;
        }
        map[fieldKey] = {
          type: ProductRest.fields[fieldKey].searchType,
          value,
        };
      } else if (ProductRest.fields[fieldKey].dbType === "array") {
        Object.keys(ProductRest.fields[fieldKey].childs!).forEach(
          (childKey) => {
            let childValue: any;
            if (ProductRest.fields[fieldKey].childs![childKey].searchType) {
              switch (
                ProductRest.fields[fieldKey].childs![childKey].searchType
              ) {
                case "boolean":
                  break;
                case "number":
                  break;
                case "string":
                  childValue = "";
                  break;
              }
              map[childKey] = {
                type: ProductRest.fields[fieldKey].childs![childKey].searchType,
                value: childValue,
                child: true,
              };
            }
            map[fieldKey] = {
              type: ProductRest.fields[fieldKey].childs![childKey].searchType,
              value: null,
            };
          }
        );
      }
      return map;
    },
    { genericSearch: { type: "generic", value: "" } }
  ),
  sortValues: {
    price: undefined,
    name: undefined,
  },
  searchedProducts: [],
  brands: {},
};

const reducer = (
  state = initialState,
  action: {
    type: string;
    products: Product[];
    fieldKey: string;
    value: string | boolean | number;
    brands: Brand[];
  }
) => {
  switch (action.type) {
    case actionTypes.SAVE_ALL_PRODUCTS:
      const products: Product[] = [];
      action.products.forEach((product) => {
        products.push(product);
      });
      return {
        ...state,
        products: products,
        searchedProducts: products,
      };
    case actionTypes.SET_FILTER:
      const filterValues = { ...state.filterValues };
      const filter = filterValues[action.fieldKey];
      filter.value = action.value;
      return {
        ...state,
        filterValues: { ...filterValues, [action.fieldKey]: filter },
      };
    case actionTypes.RESET_FILTERS:
      const newFilterValues = { ...state.filterValues };
      Object.keys(newFilterValues).forEach((key) => {
        if (["boolean", "number"].indexOf(newFilterValues[key].type) >= 0) {
          newFilterValues[key] = { ...newFilterValues[key], value: null };
        } else {
          newFilterValues[key] = { ...newFilterValues[key], value: "" };
        }
      });
      return { ...state, filterValues: newFilterValues };
    case actionTypes.SEARCH_CALCULATE:
      const filters = state.filterValues;
      const searchedProducts = state.products.filter((product) => {
        let match = true;
        Object.keys(filters).forEach((filterKey) => {
          if (filters[filterKey].child) {
            const containers = (product as any).types;
            switch (filters[filterKey].type) {
              case "boolean":
                if (
                  [true, false].some((val) => val === filters[filterKey].value)
                ) {
                  match =
                    match &&
                    containers &&
                    containers.some(
                      (container: any) =>
                        container[filterKey] === filters[filterKey].value
                    );
                }
                break;
              case "string":
                if (filters[filterKey].value) {
                  match =
                    match &&
                    containers &&
                    containers.some(
                      (container: any) =>
                        container[filterKey] &&
                        container[filterKey].includes(filters[filterKey].value)
                    );
                }
                break;
              case "number":
                if (filters[filterKey].value) {
                  match =
                    match &&
                    containers &&
                    containers.some(
                      (container: any) =>
                        container[filterKey] === filters[filterKey].value
                    );
                }
                break;
              case "numberRange":
                if (
                  filters[filterKey].value &&
                  filters[filterKey].value.length === 2
                ) {
                  const [min, max] = filters[filterKey].value.sort();
                  match =
                    match &&
                    containers &&
                    containers.some(
                      (container: any) =>
                        container[filterKey] >= min &&
                        container[filterKey] <= max
                    );
                }
                break;
            }
          } else {
            switch (filters[filterKey].type) {
              case "boolean":
                if ([true, false].indexOf(filters[filterKey].value) >= 0) {
                  match =
                    match &&
                    (product as any)[filterKey] === filters[filterKey].value;
                }
                break;
              case "string":
                if (filters[filterKey].value) {
                  const prodValue = (product as any)[filterKey];
                  match =
                    match &&
                    prodValue &&
                    prodValue.includes(filters[filterKey].value);
                }
                break;
              case "number":
                if (filters[filterKey].value) {
                  match =
                    match &&
                    (product as any)[filterKey] === filters[filterKey].value;
                }
                break;
              case "stringArray":
                if (filters[filterKey].value) {
                  const prodValue = (product as any)[filterKey];
                  match =
                    match &&
                    prodValue &&
                    prodValue.length &&
                    prodValue.includes(filters[filterKey].value);
                }
                break;
            }
          }
        });
        if (filters.genericSearch.value) {
          const searchValue = filters.genericSearch.value.toUpperCase();
          match =
            match &&
            ((!!product.desc &&
              product.desc.toUpperCase().includes(searchValue)) ||
              (!!product.name &&
                product.name.toUpperCase().includes(searchValue)) ||
              (!!product.brand &&
                state.brands &&
                state.brands[product.brand] &&
                state.brands[product.brand].name
                  .toUpperCase()
                  .includes(searchValue)));
        }
        return match;
      });
      return {
        ...state,
        searchedProducts: searchedProducts,
      };
    case actionTypes.SET_SORT:
      // setto l'ordinamento in store ( per renderlo leggibile )
      const newSortValues = {
        ...state.sortValues,
        price: undefined,
        name: undefined,
        [action.fieldKey]: action.value,
      };
      const orderedProducts = state.searchedProducts.sort((p1, p2) => {
        let ret = 0;
        switch (action.fieldKey) {
          case "name":
            switch (action.value) {
              case "asc":
                ret = p1.name > p2.name ? 1 : -1;
                break;
              case "desc":
                ret = p1.name < p2.name ? 1 : -1;
                break;
            }
            break;
          case "price":
            if (
              p1.types[0] &&
              p1.types[0].price &&
              p2.types[0] &&
              p2.types[0].price
            ) {
              switch (action.value) {
                case "asc":
                  p1.types = p1.types.sort((f1, f2) => {
                    return f1.price > f2.price ? 1 : -1;
                  });
                  p2.types = p2.types.sort((f1, f2) => {
                    return f1.price > f2.price ? 1 : -1;
                  });
                  ret = p1.types[0].price > p2.types[0].price ? 1 : -1;
                  break;
                case "desc":
                  p1.types = p1.types.sort((f1, f2) => {
                    return f1.price < f2.price ? 1 : -1;
                  });
                  p2.types = p2.types.sort((f1, f2) => {
                    return f1.price < f2.price ? 1 : -1;
                  });
                  ret = p1.types[0].price < p2.types[0].price ? 1 : -1;
                  break;
              }
            } else if (p1.types[0] && p1.types[0].price) {
              ret = -1;
            } else {
              ret = 1;
            }
            break;
        }
        return ret;
      });
      return {
        ...state,
        searchedProducts: orderedProducts,
        sortValues: newSortValues,
      };
    case actionTypes.SAVE_ALL_BRANDS:
      const brands: { [id: string]: Brand } = {};
      action.brands.forEach((brand: Brand) => {
        if (brand?.id) {
          brands[brand.id] = brand;
        }
      });
      return {
        ...state,
        brands: brands,
      };
    default:
      return { ...state };
  }
};

export default reducer;
