import * as actionTypes from "./actionTypes";
import axios from "axios";
import { Domains } from "../../models/rest";
import { startHttpCall, endHttpCall } from "./utils";

export const saveDomain = (domainName: any, domain: any) => {
  return {
    type: actionTypes.SAVE_DOMAIN,
    domainName: domainName,
    domainValue: domain,
  };
};

export const getDomain = (domainName: Domains) => {
  return (dispatch: any) => {
    dispatch(startHttpCall());
    let url = `${process.env.REACT_APP_BACK_END_URL}/domain/${domainName}`;
    axios
      .get(url)
      .then((resp) => {
        dispatch(saveDomain(domainName, resp.data));
        dispatch(endHttpCall());
      })
      .catch((err) => {
        dispatch(endHttpCall());
      });
  };
};
