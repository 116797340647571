import React, { useState, useEffect } from 'react'
import '../Scheda_Prodotto/scheda.scss'
import Logo from '../../../../assets/images/logo.svg';
import { withFirebase } from '../../../../hoc/firebase/context';
import { useHistory } from 'react-router';

function LogoutAlert(props : any) {
    const [error, setError] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    async function logout() {
        try {
            setSubmitting(true)
            await props["firebase"].doSignOut()
            setTimeout(function() { 
                toHome();
                setSubmitting(false)
            }, 2000);
        } catch (error) {
          setError(error)
        }
    }

    const history = useHistory();
    const toHome = () => {
      history.push({
        pathname: "/shop",
        state: {},
      });
    };

    const goBack = () => {
      history.goBack();
    };
    
    return (
        <div className="loginAlert">
            <div className="shadow"></div>
            <div className="alertBody">
                <div className="alertFlex">
                    <img onClick={() => toHome()} className="logo" src={Logo} alt="Logo" />
                    <h2 style={{textAlign: 'center'}}>Ci mancherai</h2>
                    
                    <a className="cta" onClick={() => isSubmitting ? () => {} : goBack()} style={{opacity: isSubmitting ? '0.6' : '1', backgroundColor: "#F2F2F2", marginBottom: "8px", marginTop: "24px"}}>
                        <p style={{color: "black"}}>{isSubmitting ? "Caricamento" : "Torna Indietro"}</p>
                    </a>
                    <a className="cta" onClick={() => isSubmitting ? () => {} : logout()} style={{opacity: isSubmitting ? '0.6' : '1'}}>
                        <p>{isSubmitting ? "Caricamento" : "Esci"}</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default withFirebase(LogoutAlert);