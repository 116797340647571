import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { ProductRest } from "../../../models/rest";
import * as action from "../../../store/actions";
import "./Products.scss";

class ModifyProducts extends Component<any> {
  componentDidMount() {}

  iconHandler = (page: "modify" | "delete" | "new", id?: any) => {
    if (page === "modify" || page === "new") {
      this.props.history.push({
        pathname: "/admin/products/" + page,
        state: { id: id },
      });
    } else {
      this.props.deleteProduct(id, this.props.loadAllProducts());
    }
  };

  render() {
    return (
      <div className="ProductsAdmin">
        <button className="newButton" onClick={() => this.iconHandler("new")}>
          NUOVO
        </button>
        <table>
          <thead>
            <tr>
              {Object.keys(ProductRest.fields).map((fieldName) => {
                return ["array", "boolean"].includes(
                  ProductRest.fields[fieldName].dbType
                ) ? null : (
                  <th key={fieldName}>{fieldName}</th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.products
              .sort((a: any, b: any) => {
                const brands = this.props.domain.brandMap;
                const aName = a.brand && brands && brands[a.brand];
                const bName = b.brand && brands && brands[b.brand];
                return aName > bName ? 1 : aName < bName ? -1 : 0;
              })
              .map((product: any, ind: number) => {
                return (
                  <tr key={ind}>
                    {Object.keys(ProductRest.fields).map((fieldKey: any) => {
                      switch (ProductRest.fields[fieldKey].inputType) {
                        case "file":
                          return (
                            <td key={fieldKey}>
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url('${product[fieldKey]}')`,
                                }}
                              ></div>
                            </td>
                          );
                        case "select":
                          return (
                            <td key={fieldKey + product[fieldKey]}>
                              {this.props.domain[fieldKey]
                                ? this.props.domain[fieldKey].find(
                                    (el: any) => el.value === product[fieldKey]
                                  )?.label
                                : null}
                            </td>
                          );
                        case "checkbox":
                          return (
                            <td key={fieldKey + product[fieldKey]}>
                              {this.props.domain[fieldKey]
                                ? product[fieldKey]?.map((el: string) => {
                                    return (
                                      this.props.domain[fieldKey].find(
                                        (el2: any) => el2.value === el
                                      )?.label + " "
                                    );
                                  })
                                : null}
                            </td>
                          );
                        case "radio":
                          return null;
                        // return (
                        //   <td key={fieldKey + product[fieldKey]}>
                        //     {product[fieldKey] ? "Si" : "No"}
                        //   </td>
                        // );
                        case "array":
                          return null;
                        case "note":
                          return (
                            <td key={fieldKey + product[fieldKey]}>
                              {product[fieldKey]?.substring(0, 100)}
                            </td>
                          );
                        default:
                          return (
                            <td key={fieldKey + product[fieldKey]}>
                              {product[fieldKey]}
                            </td>
                          );
                      }
                    })}
                    <td>
                      <i
                        onClick={() => this.iconHandler("modify", product.id)}
                        className="fa fa-edit"
                      ></i>
                      <i
                        onClick={() => this.iconHandler("delete", product.id)}
                        className="fa fa-trash"
                      ></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    products: state.product.products,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadAllProducts: () => dispatch(action.getAllProducts()),
    deleteProduct: (id: any, onSuccess: any) =>
      dispatch(action.deleteProduct(id, onSuccess)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ModifyProducts
);
