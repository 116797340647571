import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "../../widgets/Basics/Container";
import Footer from "../../widgets/Footer/Footer";
import NavBar from "../../widgets/NavBar/NavBar";
import LoginAlert from "../Scheda_Prodotto/LoginAlert";

const Login = ({ brands } : any) => {
    return <>
        <div className="shop scheda">
            <NavBar></NavBar>
            <LoginAlert></LoginAlert>
            <Footer></Footer>
        </div>
    </>
}

const mapStateToProps = (state: any) => {
    return {
      products: state.product.searchedProducts,
      brands: state.brand.brands,
      brandsForSelect: state.domain.brand,
      nations: state.domain.nation,
      styles: state.domain.style,
      sizes: state.domain.size,
    };
};
  
  export default connect(mapStateToProps)(Login);
