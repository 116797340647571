import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { ProductRest } from "../../../models/rest";
import * as actions from "../../../store/actions";
import { formatForm, getInitState, inputHandler } from "../../../utils/form";

class ModifyProducts extends Component<any> {
  state = { form: {} };

  componentDidMount() {
    const productId = this.props.location.state.id;
    const product = this.props.products.find((el: any) => el.id === productId);
    this.setState(getInitState(ProductRest, product));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    // creo il viaggio
    const objToSave = formatForm({
      // id: {  value: this.state.travelId },
      ...this.state.form,
    });
    this.props.modifyTravel(
      objToSave,
      this.props.history.push("/admin/products")
    );
  };

  render() {
    return (
      <div>
        <GenericForm
          onSubmit={this.submitHandler}
          form={this.state.form}
          handler={(type: string) => inputHandler(this, type)}
          domains={this.props.domain}
        >
          <div className="text-center mb-3">
            <button
              style={{ backgroundColor: "green" }}
              className="btn btn-primary"
              type="submit"
            >
              MODIFICA
            </button>
          </div>
        </GenericForm>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    products: state.product.products,
    domain: state.domain,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyTravel: (product: any, onSuccess: any) => {
      dispatch(actions.modifyProduct(product, onSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyProducts);
