import React, { useEffect, useState } from 'react'
import './scheda.scss'
import Logo from '../../../../assets/images/logo.svg';
import { InputText } from '../../widgets/Input/Text';
import { withFirebase } from '../../../../hoc/firebase/context';
import { useHistory } from 'react-router';

function LoginAlert(props : any) {
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submittedFlag, setSubmittedFlag] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    async function send() {
        try {
          setSubmitting(true)
          if (email == '' || password == '') {
            setSubmitting(false)
            setSubmittedFlag(true)
          } else {
            // call function here props.signIn()
            await props["firebase"].doSignInWithEmailAndPassword(email, password)
            toHome();
            setSubmitting(false)
            setSubmittedFlag(false)
          }
        } catch (error) {
          setError(error)
        }
    }

    const history = useHistory();
    const toHome = () => {
      history.push({
        pathname: "/shop",
        state: {},
      });
    };
    const toRegistration = () => {
      history.push({
        pathname: "/registration",
        state: {},
      });
    };

    function toPasswordForgot() {
      history.push({
        pathname: "/recupera-password",
        state: {},
      });
    };

    const handleEmail = (e : any) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e : any) => {
        setPassword(e.target.value)
    }

    const handleKeyDown = (e : any) => {
      if (e.key === 'Enter') {
        send();
      }
    }
    
    return (
        <div className="loginAlert">
            <div className="shadow"></div>
            <div className="alertBody">
                <div className="alertFlex">
                    <h2>Login</h2>
                    <p>{ error != '' ? 'Ci dispiace, sembra che i dati inseriti siano errati' : ''}</p>
                    <InputText 
                        placeholder={'Indirizzo e-mail'} 
                        callback={handleEmail}
                        missed={submittedFlag && email == ''}
                        missedText="Questo campo è obbligatorio"
                        secureText={false}
                        onKeyDown={handleKeyDown}
                    ></InputText>
                    <InputText 
                        placeholder={'Password'} 
                        callback={handlePassword}
                        missed={submittedFlag && email == ''}
                        missedText="Questo campo è obbligatorio"
                        secureText={true}
                        onKeyDown={handleKeyDown}
                    ></InputText>
                    <div className="flex">
                        <div style={{display: "none"}}>
                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            <label> Rimani collegato</label>
                        </div>
                        <p style={{width: "100%", textAlign: 'center'}} onClick={() => toPasswordForgot()}><a> Password dimenticata?</a></p>
                    </div>
                    <a className="cta" onClick={() => isSubmitting ? () => {} : send()} style={{opacity: isSubmitting ? '0.6' : '1'}}>
                        <p>{isSubmitting ? "Caricamento" : "Accedi"}</p>
                    </a>
                    <p><b onClick={() => toRegistration()}>Crea un account</b></p> 
                </div>
            </div>
        </div>
    )
}

export default withFirebase(LoginAlert);