import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions";
import { InputTextWithDefaultValue } from "../../../widgets/Input/Text";
import { User } from "./../../../../../models/user";

const IndirizzoFatturazione = (props: any) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [user, setUser] = useState<User>({});

  useEffect(() => {
    var tempUser: User = props["me"];
    setUser(tempUser);
  }, []);

  async function save() {
    try {
      setSubmitting(true);
      await props["modifyMe"](user);
      const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      setSubmitting(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }

  const handleName = (e: any) => {
    var tempUser = user;
    tempUser.billingName = e.target.value;
    setUser(tempUser);
  };

  const handleSurname = (e: any) => {
    var tempUser = user;
    tempUser.billingSurname = e.target.value;
    setUser(tempUser);
  };

  const handleAddress = (e: any) => {
    var tempUser = user;
    tempUser.billingStreet = e.target.value;
    setUser(tempUser);
  };

  const handleAddNumber = (e: any) => {
    var tempUser = user;
    tempUser.billingStreetNumber = e.target.value;
    setUser(tempUser);
  };

  const handleCAP = (e: any) => {
    var tempUser = user;
    tempUser.billingCap = e.target.value;
    setUser(tempUser);
  };

  const handleCity = (e: any) => {
    var tempUser = user;
    tempUser.billingCity = e.target.value;
    setUser(tempUser);
  };

  const handleProvince = (e: any) => {
    var tempUser = user;
    tempUser.billingProvince = e.target.value;
    setUser(tempUser);
  };

  return (
    <div className="page">
      <h2>Modifica Indirizzo di fatturazione</h2>

      <div className="section">
        <h3>Indirizzo di fatturazione</h3>
        <div className={"divider"}></div>
        <div className={"flex"}>
          <div className="option">
            <p>Alla cortese attenzione di</p>
            <InputTextWithDefaultValue
              value={user.billingName}
              placeholder={"Nome"}
              callback={handleName}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>&nbsp;</p>
            <InputTextWithDefaultValue
              value={user.billingSurname}
              placeholder={"Cognome"}
              callback={handleSurname}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
        <div className="option" style={{ width: "100%", padding: 0 }}>
          <p>Via</p>
          <InputTextWithDefaultValue
            value={user.billingStreet}
            placeholder={"Via"}
            callback={handleAddress}
            missed={false}
            missedText={"Campo obbligatorio"}
            // onKeyDown={() => {}}
          ></InputTextWithDefaultValue>
        </div>
        <div className={"flex"}>
          <div className="option">
            <p>Numero</p>
            <InputTextWithDefaultValue
              value={user.billingStreetNumber}
              placeholder={"Numero"}
              callback={handleAddNumber}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>CAP</p>
            <InputTextWithDefaultValue
              value={user.billingCap}
              placeholder={"CAP"}
              callback={handleCAP}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
        <div className={"flex"}>
          <div className="option">
            <p>Città</p>
            <InputTextWithDefaultValue
              value={user.billingCity}
              placeholder={"Città"}
              callback={handleCity}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
          <div className="option">
            <p>Provincia</p>
            <InputTextWithDefaultValue
              value={user.billingProvince}
              placeholder={"Provincia"}
              callback={handleProvince}
              missed={false}
              missedText={"Campo obbligatorio"}
              //   onKeyDown={() => {}}
            ></InputTextWithDefaultValue>
          </div>
        </div>
      </div>

      <a
        className="cta"
        onClick={() => (isSubmitting ? () => {} : save())}
        style={{ opacity: isSubmitting ? "0.6" : "1" }}
      >
        <p>{isSubmitting ? "Caricamento" : "Salva"}</p>
      </a>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    brands: state.brand.brands,
    styles: state.domain.style,
    isLogged: state.utils.isLogged,
    me: state.utils.me,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    modifyMe: (user: User) => {
      dispatch(actions.modifyMe(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndirizzoFatturazione);
