import React from 'react'
import './scheda.scss'

const Header = ({prod} : any) => {
    return (
        <div className="schedaHeader">
            <img className="image" src={prod.image} />
        </div>
    )
}

export default Header