import axios from "axios";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import handImage from "../../../assets/images/hand.png";
import GenericForm from "../../../components/GenericForm/GenericForm";
import { MailRest } from "../../../models/rest";
import * as action from "../../../store/actions";
import { formatForm, getInitState, inputHandler } from "../../../utils/form";
import "./Mail.scss";

class Mail extends Component<any> {
  state = {
    form: {},
  };

  componentDidMount() {
    this.setState(getInitState(MailRest));
  }

  submitHandler = (event: any) => {
    event.preventDefault();
    const objToSave = formatForm(this.state.form);
    let url = `${process.env.REACT_APP_BACK_END_URL}/email/new`;
    this.props.startHttpCall();
    axios
      .post(url, objToSave)
      .then((_: any) => {
        this.props.endHttpCall();
        this.setState(getInitState(MailRest));
      })
      .catch((_) => {
        this.props.endHttpCall();
      });
  };

  render() {
    return (
      <div className="Mail" id="#contactUs">
        <div className="leftCol">
          <h1>{this.props.t("mail:title")}</h1>
          <h5>{this.props.t("mail:subtitle")}</h5>
          <GenericForm
            onSubmit={this.submitHandler}
            form={this.state.form}
            handler={(type: string) => inputHandler(this, type)}
          >
            <div className="text-center mb-3">
              <button className="btn btn-primary" type="submit">
                {this.props.t("mail:button")}
              </button>
            </div>
          </GenericForm>
        </div>
        <div
          className="rightCol"
          style={{ backgroundImage: `url('${handImage}')` }}
        ></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    startHttpCall: () => dispatch(action.startHttpCall()),
    endHttpCall: () => dispatch(action.endHttpCall()),
  };
};
export default compose(
  withTranslation("mail"),
  connect(null, mapDispatchToProps)
)(Mail);
