import { mobileScreenSizeLimit } from "../../utils/consts";
import * as actionTypes from "../actions/actionTypes";
import { User } from "./../../models/user";

const initialState: {
  screenWidth: any;
  isMobile: boolean;
  token: any;
  isLogged: boolean;
  isEditor: boolean;
  restToWait: number;
  users: User[];
  me: User;
} = {
  screenWidth: null,
  isMobile: false,
  token: null,
  isLogged: false,
  isEditor: false,
  restToWait: 0,
  users: [],
  me: {},
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth: action.screenWidth,
        isMobile: action.screenWidth < mobileScreenSizeLimit,
      };
    case actionTypes.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.token,
        isEditor: action.isEditor,
        isLogged: true,
      };
    case actionTypes.UNSET_AUTH_TOKEN:
      return {
        ...state,
        token: null,
        isEditor: false,
        isLogged: false,
      };
    case actionTypes.START_HTTP_CALL:
      return {
        ...state,
        restToWait: state.restToWait + 1,
      };
    case actionTypes.END_HTTP_CALL:
      return {
        ...state,
        restToWait: state.restToWait - 1,
      };
    case actionTypes.SAVE_USERS:
      return {
        ...state,
        users: action.users,
      };
    case actionTypes.SAVE_ME:
      return { ...state, me: action.user };
    default:
      return { ...state };
  }
};

export default reducer;
